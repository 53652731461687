import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface Plan {
  id: string;
  planName: string;
  description: string;
}

const SuperAdminAddPlanGroup = () => {
  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHJlZWx0eS5jb20iLCJleHAiOjE3Mjc5NzIzMzEsInVzZXJUeXBlIjoiIn0.cFNnl3zUN32x3X_Ysj7bttI-0DpTVgLdZmY8V1NI7Xk";
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/plan-profiles",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const plansOptions =
    plans?.map((plan) => ({
      value: plan,
      label: plan.planName,
    })) || [];

  const formik = useFormik({
    initialValues: {
      groupName: "",
      groupPlans: [] as Plan[],
    },
    validationSchema: Yup.object({
      groupName: Yup.string()
        .min(2, "Group name must be at least 2 characters long")
        .max(50, "Group name cannot be longer than 50 characters")
        .required("Group name is required"),
        groupPlans: Yup.array()
        .of(Yup.object())
        .min(1, 'At least one plan must be selected')
        .required('Group plan is required'),
      
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/plan-groups",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`, // Add the Bearer token here
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: values.groupName,
              planProfile: values.groupPlans,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to submit form");
        }

        const data = await response.json();
        // toast.success("Plan profile added successfully!");
        console.log("Form successfully submitted:", data);
        navigate("/superadmin/profiles", {
          state: { successMessage: "Plan group added successfully!" },
        });
      } catch (error) {
        toast.error("An error occurred while adding the plan profile.");
        console.error("Error submitting form:", error);
      }
    },
  });

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Add Plan Group </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          Group Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="groupName"
                          name="groupName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.groupName}
                        />
                        {formik.touched.groupName && formik.errors.groupName ? (
                          <div className="text-danger">
                            {formik.errors.groupName}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="plan-select">Plan</label>

                          <Select
                            id="groupPlans"
                            name="groupPlans"
                            options={plansOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={plansOptions.filter((option) =>
                              formik.values.groupPlans.includes(option.value)
                            )}
                            onChange={(selectedOptions) =>
                              formik.setFieldValue(
                                "groupPlans",
                                selectedOptions
                                  ? selectedOptions.map(
                                      (option) => option.value
                                    )
                                  : []
                              )
                            }
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.groupPlans &&
                          formik.errors.groupPlans ? (
                            <div className="text-danger">
                              {/* {formik.errors.groupPlans} */}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddPlanGroup;
