import React, { useState, useRef } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

const SignageAddTaxRate = ()=>{
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");

const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      name: '',      
      taxRate: '',      
      statuss: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Name must be at least 2 characters long')
        .max(50, 'Name cannot be longer than 50 characters')
        .required('Name is required'),
     taxRate: Yup.string()
        .required('Tax Rate is required'), 
     statuss: Yup.string()        
        .required('Status is required'), 
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      const taxStatus = (values.statuss=='Active')?true:false;

      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/taxes",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              taxRate: Number(values.taxRate),
              status: taxStatus
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("GeoLocation added successfully!");
        // console.log("Form submission successful:", result);
        navigate("/signage/taxRates", {
          state: { successMessage: "Tax Rate added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Tax Rate.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });    

//   if (!isLoaded) return <div>Loading...</div>;


    return(
        <div className="teammember">
            <div className="card">
                <div className="card-body">
                    <h2>Add Tax Rate </h2>
                    
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">                    
                    <div className="p-2 mt-4">
                      <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <div className="text-danger">{formik.errors.name}</div>
                            ) : null}
                          </div> 
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Tax Rate <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="taxRate"
                              name="taxRate"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.taxRate}
                            />
                            {formik.touched.taxRate && formik.errors.taxRate ? (
                              <div className="text-danger">{formik.errors.taxRate}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Status{" "} <span className="text-danger">*</span> 
                            </label>
                            <select className="form-control" id="statuss" name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}                            
                            >
                              <option value="">- Select -</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                            {formik.touched.statuss && formik.errors.statuss ? (
                              <div className="text-danger">{formik.errors.statuss}</div>
                            ) : null}
                          </div> 
                          
                                        
                          
                        </div>
                        
                        
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>                        
                      </form>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
                </div>
                
            </div>
        </div>
    );
}

export default SignageAddTaxRate;