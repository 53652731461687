import React from "react";

const MasterAdminDashboard = ()=>{
    return(
        <>
        <h2>MasterAdmin Dashboard</h2>
        </>
    );
}

export default MasterAdminDashboard;