import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./RealtorSuppliers.css";

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Supplier {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
}

const RealtorSuppliers = () => {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("sessionToken");

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/users?userType=signage_firm", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch suppliers");
        }

        const data: Supplier[] = await response.json();
        console.log(data);
        setSuppliers(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchSuppliers();
  }, [token]);

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Suppliers</h2>

          {loading && <p>Loading suppliers...</p>}
          {error && <p className="text-danger">Error: {error}</p>}

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              {/* Search and Filter Section */}
              <div className="card">
                <div className="card-header border-0 rounded">
                  <div className="row g-2">
                    <div className="col-xl-3">
                      <div className="search-box">
                        <input
                          type="text"
                          className="form-control"
                          id="searchResultList"
                          placeholder="Search for sellers & owner name..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <div className="col-xxl-3 ms-auto">
                      <select className="form-control" id="category-select">
                        <option value="All">Select Categories</option>
                        <option value="Computers & Electronics">
                          Computers & Electronics
                        </option>
                        <option value="Food Service">Food Service</option>
                        <option value="Health & Medicine">
                          Health & Medicine
                        </option>
                        <option value="Manufacturer">Manufacturer</option>
                        <option value="Retailer">Retailer</option>
                      </select>
                    </div>
                    <div className="col-lg-auto">
                      <div className="hstack gap-2">
                        <button type="button" className="btn btn-danger">
                          <i className="ri-equalizer-fill me-1 align-bottom"></i>{" "}
                          Filters
                        </button>
                        {/* <button
                          className="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target="#addSeller"
                        >
                          <i className="ri-add-fill me-1 align-bottom"></i> Add
                          Supplier
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Supplier Cards */}
              <div className="row mt-4" id="seller-list">
                {suppliers && suppliers
                  .filter((supplier) => supplier.userType === "signage_firm") // Filter out 'super_admin' suppliers
                  .map((supplier) => (
                    <div key={supplier.id} className="col-xl-3 col-lg-6">
                      <div className="card ribbon-box right overflow-hidden">
                        <div className="card-body text-center p-4">
                          <div className="ribbon ribbon-info ribbon-shape trending-ribbon">
                            <i className="ri-flashlight-fill text-white align-bottom"></i>
                            <span className="trending-ribbon-text">
                              Trending
                            </span>
                          </div>
                          <img
                            src="/assets/images/companies/img-1.png"
                            alt="Supplier Logo"
                            height="45"
                          />
                          <h5 className="mb-1 mt-4">
                            <Link
                              to={`/realtor/supplier/${supplier.id}`}
                              className="link-primary"
                            >
                              {supplier.firmDetails.companyName}
                            </Link>
                          </h5>
                          <p className="text-muted mb-4">
                            {supplier.firmDetails.address.city}
                          </p>

                          <div className="row justify-content-center">
                            <div className="col-lg-12">
                              <h5>Specialities:</h5>
                              <span className="text-muted">
                                {supplier.firmDetails.specialities}
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <h5>Regions Served</h5>
                              <span className="text-muted">
                                {supplier.firmDetails.regionsServed}
                              </span>
                            </div>
                          </div>

                          <div className="mt-4">
                            <Link
                              to={`/realtor/supplier/${supplier.id}`}
                              className="btn btn-info w-100"
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {!loading && suppliers.length === 0 && (
                <p>No suppliers available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>    
  );
};

export default RealtorSuppliers;
