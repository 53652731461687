import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // Import useParams from React Router
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { format } from "date-fns";
// import "./RealtorViewOffice.css";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";


interface FirmInfo {
    companyID: string;
    companyName: string;
    address: string;
  }
  
  // interface License {
  //   id: string;
  //   companyID: string;
  //   companyName: string;
  //   licenseType: string;
  //   status: string;
  //   billingCycle: string;
  //   licenseValidity: string;
  //   firstName: string;
  //   lastName: string;
  //   firmDetails: FirmInfo;
  //   modulePermissions: string[];
  //   created_at: string;
  // }
  interface License {
    id: string;
    companyName: string;
    jobTitle: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    status: string;
    licenseValidity: string | null;
    billingCycle: string | null;
    paymentMethod: string;
    licenseType: string;
    modules: string[];
    created_at: string;
  }
  
  interface Module {
    id: string;
    name: string;
    module_code: string;
    license_type_code: string;
    description: string;
    default_status: boolean;
    default_volume: number;
  }

const SuperAdminViewLicense = () => {
  const { licenseId } = useParams<{ licenseId: string }>(); // Get licenseId from URL params
  const token = localStorage.getItem("sessionToken");
  const [license, setLicense] = useState<License | null>(null); // To store the license data
  const [error, setError] = useState<string | null>(null);

  console.log(licenseId);
  
  // Fetch the license data based on licenseId
  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${licenseId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch license data");
        }
        const data = await response.json();
        // setLicense(data);
        if (data && data.firmDetails && data.firmDetails.address) {
          setLicense({
            id: data.id || "",
            companyName: data.firmDetails.companyName || "",
            jobTitle: data.jobTitle || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            email: data.email || "",
            phone: data.phone1 || "",
            addressLine1: data.firmDetails.address.addressLine1 || "",
            addressLine2: data.firmDetails.address.addressLine2 || "",
            city: data.firmDetails.address.city || "",
            state: data.firmDetails.address.state || "",
            zip: data.firmDetails.address.zip || "",
            country: data.firmDetails.address.country || "",
            status: data.status || "Active",
            licenseValidity: data.licenseValidity || "", // Using data from API
            billingCycle: data.billingCycle || "",
            paymentMethod: data.paymentMethod || "",
            licenseType: data.licenseType || "",
            modules: data.modulePermissions || "",
            created_at: data.created_at
          });

          // setUserModules(data.modulePermissions);
          // console.log(userModules);
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        setError("Error fetching license data");
        console.error("Error fetching license data:", error);
      }
    };

    if (licenseId) {
      fetchLicenseData();
    }
  }, [licenseId, token]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!license) return <div>Loading License Data...</div>;

  // Format the date to be more human-readable
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>View License Details </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th colSpan={4}>License Information </th>
                      </tr>
                      <tr>
                        <th>Company Name: </th>
                        <td>{license.companyName}</td>
                        <th>License Type </th>
                        <td>{license.licenseType}</td>
                      </tr>
                      <tr>
                      <th>License Status</th>
                      <td>{license.status}</td>
                      <th>Started Date</th>
                      <td>{format(new Date(license.created_at), "MM/dd/yyyy")}</td>
                      </tr>
                      <tr>
                      <th>Billing cycle</th>
                      <td>{license.billingCycle}</td>
                      <th>Created date</th>
                      <td>{format(new Date(license.created_at), "MM/dd/yyyy")}</td>
                      </tr>
                      <tr>
                      <th>License Validity Period</th>
                      <td>{license.licenseValidity}</td>
                      <th>Primary Contact Name</th>
                      <td>{license.firstName} {license.lastName}</td>
                      </tr>
                      <tr>
                      <th>License Payment Status</th>
                      <td>Pending </td>
                      <th>Active Orders</th>
                      <td>0</td>
                      </tr>
                      <tr>
                        <th colSpan={4}>User Details </th>
                      </tr>
                      <tr>
                        <th>First Name: </th>
                        <td>{license.firstName}</td>
                        <th>Last Name </th>
                        <td>{license.lastName}</td>
                      </tr>
                      <tr>
                      <th>Email </th>
                      <td>{license.email}</td>
                      <th>Phone </th>
                      <td>{license.phone}</td>
                      </tr>
                      <tr>
                      <th>Job Title</th>
                      <td>{license.jobTitle}</td>
                      <th></th>
                      <td></td>
                      </tr>
                    </tbody>
                  </table>  
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminViewLicense;
