import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import "datatables.net"; // DataTables itself
import "datatables.net-dt/css/dataTables.dataTables.css";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "./SuperAdminLicenseRegistrations.css";
import withAuth from "../../components/withAuth"; // Import the HOC
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: string;
}

interface LicenseRegistration {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  email: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
}

const SuperAdminLicenseRegistrations: React.FC = () => {
  const [licenseRegistrations, setLicenseRegistrations] = useState<LicenseRegistration[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress

  const token = localStorage.getItem("sessionToken");

  const location = useLocation();
  useEffect(() => {
    if (location.state?.successMessage) {
      toast.success(location.state.successMessage);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchLicenseRegistrations = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/users", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setLicenseRegistrations(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseRegistrations();
  }, [token]);

  const handleDelete = async (licenseId: string) => {
    setDeleting(licenseId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/users/${licenseId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the license.");
      }

      toast.success("License deleted successfully!");
      // Update the state by removing the deleted license
      setLicenseRegistrations((prev) =>
        prev.filter((license) => license.id !== licenseId)
      );
      setDeleting(null); // Clear the deleting state
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  // Initialize DataTable
  useEffect(() => {
    if (!loading) {
      const table = $("#transactions").DataTable({
        destroy: true,
        paging: true,
        searching: true,
        ordering: true,
        lengthChange: false,
        pageLength: 10,
        columnDefs: [
          { orderable: false, targets: "_all" }, // Disable sorting for all columns
          { orderable: true, targets: [1, 2] }, // Enable sorting for columns 1 and 2
        ],
      });      

      return () => {
        table.destroy(); // Cleanup DataTable instance
      };
    }
  }, [loading, licenseRegistrations]);

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>License Registrations</h2>
          <div className="text-end">
            <Link
              to="/superadmin/add-license"
              className="btn btn-primary float-right"
            >
              Add License
            </Link>
          </div>
          <div className="row">
            {!loading && !error && (
              <div className="table-container">
                <table
                  id="transactions"
                  className="table table-bordered dt-responsive nowrap table-striped align-middle"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Company Name</th>
                      <th>License Type</th>
                      <th>Email</th>
                      <th>License Status</th>
                      <th>Started Date</th>
                      <th>Billing Cycle</th>
                      <th>Created Date</th>
                      <th>License Validity Period</th>
                      <th>Primary Contact Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {licenseRegistrations.map((license, index) => (
                      <tr key={license.id}>
                        <td>{index + 1}</td>
                        <td>{license.firmDetails?.companyName || "N/A"}</td>
                        <td>{license.licenseType}</td>
                        <td>{license.email}</td>
                        <td>{license.status}</td>
                        <td>{format(new Date(license.created_at), "MM/dd/yyyy")}</td>
                        <td>{license.billingCycle}</td>
                        <td>{format(new Date(license.created_at), "MM/dd/yyyy")}</td>
                        <td>{license.licenseValidity}</td>
                        <td>
                          {license.firstName} {license.lastName}
                        </td>
                        <td>
                          <Link
                            to={`/superadmin/view-license/${license.id}`}
                            className="btn btn-secondary waves-effect waves-light me-2"
                          >
                            <i className="ri-eye-fill align-bottom me-2"></i>
                          </Link>

                          <Link
                            to={`/superadmin/edit-license/${license.id}`}
                            className="btn btn-warning waves-effect waves-light me-2"
                          >
                            <i className="ri-pencil-fill align-bottom me-2"></i>
                          </Link>

                          <button
                            onClick={() =>
                              window.confirm("Are you sure you want to delete this license?") &&
                              handleDelete(license.id)
                            }
                            className="btn btn-danger waves-effect waves-light"
                          >
                            <i className="ri-delete-bin-fill align-bottom me-2"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminLicenseRegistrations;
