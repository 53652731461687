import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

// type Agent = {
//   id: number;
//   first_name: string;
//   last_name: string;  
//   phone: string;
//   email: string;
//   license_number: string;
//   license_expiry_date: string;
//   date_joined: string;
//   last_login: string; 
//   status: string;
// };
interface AgentDetails {
  brokerageFirmAffiliation: string;
  managingBroker: string;
  agentID: string;
  licenseNumber: string;
  licenseExpiryDate: string;
};

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Agent {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  email: string;
  phone1: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
  agentDetails: AgentDetails;
}

const SignageAgents = () => {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.successMessage && !toastShown) {
      toast.success(location.state.successMessage);
      setToastShown(true);
      location.state.successMessage = null;
    }
  }, [location.state, toastShown]);
  
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/users-search?userType=agent", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch agents");
        }

        const data: Agent[] = await response.json();
        console.log(data);
        setAgents(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [token]);

  useEffect(() => {
    if (agents!=null && agents.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agents]);

  const handleDelete = async (agentId: string) => {
    // const confirmation = window.confirm(
    //   "Are you sure you want to delete this license?"
    // );
    // if (!confirmation) return;

    setDeleting(agentId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/users/${agentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the agent.");
      }

      toast.success("Office deleted successfully!");
      // Update the state by removing the deleted license
      setAgents((prev) =>
        prev.filter((agent) => agent.id !== agentId)
      );
      setDeleting(null); // Clear the deleting state
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  const handleActivate = async (licenseId: string) => {
    setDeleting(licenseId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/users/${licenseId}/activate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:",response);
      if (!response.ok) {
        throw new Error("Failed to activate the license.");
      }

      toast.success("Agent activated successfully!");
      window.location.reload();
      // navigate("/signage/agents", {
      //   state: { successMessage: "Agent activated successfully!" },
      // });
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while activating."
      );
      
    }
  };

  const handleDeactivate = async (licenseId: string) => {
    setDeleting(licenseId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/users/${licenseId}/deactivate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:",response);
      if (!response.ok) {
        throw new Error("Failed to deactivate the license.");
      }

      toast.success("License deactivated successfully!");
      window.location.reload();
      // navigate("/signage/agents", {
      //   state: { successMessage: "Agent deactivated successfully!" },
      // });
      
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deactivating."
      );
      
    }
  };

  return (
    <div className="agents-list">
      <div className="card">
        <div className="card-body">
          <h2>Agents </h2>
          <div className="text-end">
            <a
              href="/signage/add-agent"
              className="btn btn-primary float-right"
            >
              Add Agent
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table
                    id="example"
                    className="table table-bordered dt-responsive nowrap table-striped align-middle"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Agent ID</th>
                        <th>Agent Name</th>
                        <th>License Number</th>
                        <th>License Expiry Date</th>
                        <th>Status</th>
                        <th>Date Joined</th>
                        {/* <th>Last Login</th> */}
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Action</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {agents && agents
                  // .filter((agent) => agent.userType === "agent") // Filter out 'super_admin' suppliers
                  .map((agent) => (
                      <tr key={agent.id}>
                        <td>{agent.id}</td>
                        <td>{agent.firstName} {agent.lastName}</td>
                        <td>{agent.agentDetails.licenseNumber}</td>
                        <td>{agent.agentDetails.licenseExpiryDate}</td>
                        <td>{agent.status}</td>
                        <td>{agent.created_at}</td>
                        {/* <td>{agent.last_login}</td>                         */}
                        <td>{agent.email}</td>
                        <td>{agent.phone1}</td>
                        <td>
                          {(agent.status==='active' || agent.status==='Active')?
                            <Link
                            to="#"
                            onClick={() =>
                              window.confirm("Are you sure you want to deactivate this agent?") &&
                              handleDeactivate(agent.id)
                            }
                            className="btn btn-danger waves-effect waves-light"
                          >
                            Deactivate
                          </Link>
                          :
                        <Link
                            to="#"
                            onClick={() =>
                              window.confirm("Are you sure you want to activate this agent?") &&
                              handleActivate(agent.id)
                            }
                            className="btn btn-success waves-effect waves-light"
                          >
                            Activate
                          </Link>
                          }
                        </td>
                        <td>
                            <Link
                              to={`/signage/view-agent/${agent.id}`}
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to={`/signage/edit-agent/${agent.id}`} className="btn btn-warning waves-effect waves-light">
                              <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link
                                  to="#"
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this agent?"
                                    ) && handleDelete(agent.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </Link>
                          </td>
                      </tr>                      
                       ))} 
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageAgents;
