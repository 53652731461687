import React, { useState } from 'react';

const TestApi = () => {
  const [responseData, setResponseData] = useState('');
  const [error, setError] = useState('');

  const makeApiCall = () => {
    fetch('http://192.168.29.41:5002/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: 'admin@reelty.com',
        password: 'zof_brl787af2eD!o+@e',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setResponseData(JSON.stringify(data, null, 2));
      })
      .catch((err) => {
        setError('Error: ' + err);
      });
  };

  return (
    <div className="container">
      <h1>API Access Application</h1>
      <button onClick={makeApiCall}>Make API Call</button>
      <div id="response">
        <pre>{responseData || error || 'Response will appear here.'}</pre>
      </div>
    </div>
  );
};

export default TestApi;
