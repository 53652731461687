// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-validation-box.mt-2 {
    width: 400px;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: #f4f4f4;
    padding: 10px;
    /* display: none; */
}`, "",{"version":3,"sources":["webpack://./src/pages/signage/SignageAddTeammember.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,QAAQ;IACR,SAAS;IACT,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".password-validation-box.mt-2 {\n    width: 400px;\n    position: fixed;\n    right: 0;\n    bottom: 0;\n    z-index: 99;\n    background-color: #f4f4f4;\n    padding: 10px;\n    /* display: none; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
