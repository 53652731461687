import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Teammember = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  assignedOffices: Office[]; // Array of office IDs
  permissions: string[]; // Array of permission strings
  photo?: string;
  statuss: string;
  licenseType: string;
};

type Office = {
  id: string;
  officeName: string;
  officeType: string;
};

type SelectOption = {
  value: string;
  label: string;
};

const RealtorEditTeammember = () => {
  const { teammemberId } = useParams<{ teammemberId: string }>();
  const [teammember, setTeammember] = useState<Teammember | null>(null);
  const [offices, setOffices] = useState<Office[]>([]);
  const [selectedOffices, setSelectedOffices] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);

  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  const permissionsOptions: SelectOption[] = [
    { value: "all", label: "All" },
    { value: "view", label: "View" },
    { value: "edit", label: "Edit" },
    { value: "delete", label: "Delete" },
  ];

  // Fetch Teammember Data
  useEffect(() => {
    const fetchTeammemberData = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${teammemberId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching team member: ${response.statusText}`);
        }

        const data = await response.json();
        if (data) {
          setTeammember({
            id: data.id || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            jobTitle: data.jobTitle || "",
            phone: data.phone1 || "",
            email: data.email || "",
            assignedOffices: data.offices || [],
            permissions: data.accessLevel || [],
            photo: data.photo || "",
            statuss: data.status || "Active",
            licenseType: data.licenseType || "",
          });
        } else {
          console.error("Incomplete data", data);
          setError("Incomplete data received from the server.");
        }
      } catch (error: any) {
        console.error("Error fetching team member data:", error);
        setError(error.message || "An unknown error occurred while fetching team member data.");
      } finally {
        setLoading(false);
      }
    };

    if (teammemberId && !teammember) {
      fetchTeammemberData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teammemberId, token]);

  // Fetch Offices Data
  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/offices", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`Error fetching offices: ${response.statusText}`);
        }
        const data = await response.json();
        setOffices(data);
      } catch (err: any) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchOffices();
    }
  }, [token]);

  // Map Offices to Select Options using useMemo for performance
  const officesOptions: SelectOption[] = useMemo(
    () =>
      offices.map((office) => ({
        value: office.id,
        label: office.officeName,
      })),
    [offices]
  );

  // Update selectedOffices when Teammember and Offices are loaded
  useEffect(() => {
    if (teammember && offices.length > 0) {
      const selected = teammember.assignedOffices
        .map((officce) => {
          const office = offices.find((o) => o.id === officce.id);
          return office ? { value: office.id, label: office.officeName } : null;
        })
        .filter((option): option is SelectOption => option !== null);
      setSelectedOffices(selected);
  
      // Set the Formik assignedOffices field with the IDs
      const assignedOfficeIds = selected.map((office) => office.value);
      formik.setFieldValue("assignedOffices", assignedOfficeIds);
    }
  }, [teammember, offices]);  

  // Initialize Formik early
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: teammember?.firstName || "",
      lastName: teammember?.lastName || "",
      jobTitle: teammember?.jobTitle || "",
      email: teammember?.email || "",
      phone: teammember?.phone || "",
      photo: null as File | null, // Initialize as File or null
      assignedOffices: teammember?.assignedOffices || [],
      permissions: teammember?.permissions || [],
      statuss: teammember?.statuss || "active",
      licenseType: teammember?.licenseType || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      jobTitle: Yup.string().required("Role is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      assignedOffices: Yup.array().min(1, "Assign Offices is required"),
      permissions: Yup.array().min(1, "Permissions are required"),
      statuss: Yup.string().required("Status is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      photo: Yup.mixed().nullable(),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      console.log('Assigned office ids:',values.assignedOffices);
      const assignedOfficeIds = new Array();
      for(const item of values.assignedOffices)
      {
        assignedOfficeIds.push(item);
      }
      // console.log(assignedOfficeIds);
      // const assignedOfficeIds =
      // values.assignedOffices?.map((id) => ({
      //   id
      // })) || [];
      console.log('assignedOfficeIds:',assignedOfficeIds);
      // const assignedOfficeIds = values.assignedOffices as string[];
      const assignedOffices = new Array();
      for (const oid of assignedOfficeIds) {
        console.log(oid); 
        const assignedOffice = offices.find((office) => office.id === oid);
        assignedOffices.push(assignedOffice);
        console.log('assignedOffices:', assignedOffices);
      }    
      // const assignedOffices = assignedOfficeIds.filter((office) => offices.includes(office.id));
      // console.log(assignedOffices);

      try {
        const body: any = {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle,
          phone1: values.phone,
          email: values.email,
          offices: assignedOffices,
          accessLevel: values.permissions,
          status: values.statuss,
          userType: "team_member",
          licenseType: "TEAM_MEMBER",
        };

        // Handle file upload with FormData if photo is present
        // let formData: FormData | null = null;
        // if (values.photo) {
        //   formData = new FormData();
        //   formData.append("firstName", values.firstName);
        //   formData.append("lastName", values.lastName);
        //   formData.append("jobTitle", values.jobTitle);
        //   formData.append("phone1", values.phone);
        //   formData.append("email", values.email);
        //   values.assignedOffices.forEach((officeId) =>
        //     formData.append("offices[]", officeId)
        //   );
        //   values.permissions.forEach((permission) =>
        //     formData.append("permissions[]", permission)
        //   );
        //   formData.append("photo", values.photo);
        //   formData.append("status", values.status);
        //   formData.append("userType", "team_member");
        //   formData.append("licenseType", "TEAM_MEMBER");
        // }

        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${teammemberId}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              // Do not set Content-Type when using FormData
              // ...(values.photo ? {} : { "Content-Type": "application/json" }),
            },
            body: JSON.stringify(body),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to save data");
        }

        // toast.success("Teammember updated successfully!");
        navigate("/realtor/teammembers", {
          state: { successMessage: "Team member updated successfully!" },
        });
      } catch (err: any) {
        toast.error(err.message || "An error occurred while updating the teammember.");
        console.error(
          err instanceof Error ? err.message : "Unknown error"
        );
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  

  // Handle Assign Offices Change
  const handleOfficeChange = (options: MultiValue<SelectOption>) => {
    setSelectedOffices(options as SelectOption[]);
    const selectedValues = options.map((option) => option.value);
    formik.setFieldValue("assignedOffices", selectedValues);
    console.log(`Assigned Offices selected:`, selectedValues);
  };

  // Handle Permissions Change
  const handlePermissionsChange = (options: MultiValue<SelectOption>) => {
    const selectedPermissions = options.map((option) => option.value);
    formik.setFieldValue("permissions", selectedPermissions);
    console.log(`Permissions selected:`, selectedPermissions);
  };

  // Handle Photo Upload
  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      formik.setFieldValue("photo", file);
      // Optionally, handle file preview or upload here
    }
  };

  // Debugging: Log fetched data
  useEffect(() => {
    console.log("Teammember Data:", teammember);
  }, [teammember]);

  useEffect(() => {
    console.log("Offices Data:", offices);
  }, [offices]);

  useEffect(() => {
    console.log("Selected Offices:", selectedOffices);
  }, [selectedOffices]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error}</div>;
  }

  return (
    <div className="teammember">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Edit Team Member</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        {/* First Name */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.firstName && formik.errors.firstName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="invalid-feedback">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>

                        {/* Last Name */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.lastName && formik.errors.lastName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="invalid-feedback">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>

                        {/* Job Title */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Job Title/Role <span className="text-danger">*</span>
                          </label>
                          <select
                            className={`form-control ${
                              formik.touched.jobTitle && formik.errors.jobTitle
                                ? "is-invalid"
                                : ""
                            }`}
                            id="jobTitle"
                            name="jobTitle"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                            required
                          >
                            <option value="">- Select -</option>
                            <option value="Agent">Agent</option>
                            <option value="Manager">Manager</option>
                            <option value="Admin">Admin</option>
                            <option value="VP">VP</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Legal">Legal</option>
                            <option value="Custom">Custom</option>
                          </select>
                          {formik.touched.jobTitle && formik.errors.jobTitle ? (
                            <div className="invalid-feedback">
                              {formik.errors.jobTitle}
                            </div>
                          ) : null}
                        </div>

                        {/* Phone Number */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.phone && formik.errors.phone
                                ? "is-invalid"
                                : ""
                            }`}
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="invalid-feedback">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        {/* Photo/Avatar Upload */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Photo/Avatar Upload
                          </label>
                          <input
                            type="file"
                            className={`form-control ${
                              formik.touched.photo && formik.errors.photo
                                ? "is-invalid"
                                : ""
                            }`}
                            id="photo"
                            name="photo"
                            onChange={handlePhotoChange}
                            onBlur={formik.handleBlur}
                            accept="image/*"
                          />
                          {formik.touched.photo && formik.errors.photo ? (
                            <div className="invalid-feedback">
                              {formik.errors.photo}
                            </div>
                          ) : null}
                        </div>

                        {/* Assign Offices */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Assign Offices <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="assignedOffices"
                            name="assignedOffices"
                            options={officesOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedOffices}
                            onBlur={formik.handleBlur}
                            onChange={handleOfficeChange}
                          />
                          {formik.touched.assignedOffices &&
                          formik.errors.assignedOffices ? (
                            <div className="text-danger">
                              {/* {formik.errors.assignedOffices} */}
                            </div>
                          ) : null}
                        </div>

                        {/* Permissions/Access Level */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Permissions/Access Level{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="permissions"
                            name="permissions"
                            options={permissionsOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={permissionsOptions.filter((option) =>
                              formik.values.permissions.includes(option.value)
                            )}
                            onChange={handlePermissionsChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.permissions &&
                          formik.errors.permissions ? (
                            <div className="text-danger">
                              {formik.errors.permissions}
                            </div>
                          ) : null}
                        </div>

                        {/* Status */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className={`form-control ${
                              formik.touched.statuss && formik.errors.statuss
                                ? "is-invalid"
                                : ""
                            }`}
                            id="statuss"
                            name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                            required
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                          {formik.touched.statuss && formik.errors.statuss ? (
                            <div className="invalid-feedback">
                              {formik.errors.statuss}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> {/* End of card */}
      </div> {/* End of teammember */}
    </div>
  );
};

export default RealtorEditTeammember;
