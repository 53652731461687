import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const libraries: Array<"places"> = ["places"];
const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  default_status: boolean;
  default_volume: number;
}

const MasterAdminAddModule = () => {
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [loading, setLoading] = useState(true);
  const [licenseType, setLicenseType] = useState<string>("");
  const [modules, setModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/license-types",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, [token]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [address, setAddress] = useState("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      module_code: "",
      license_type_code: "",
      description: "",
      default_status: "",
      default_volume: "",
    },
    validationSchema: Yup.object({
        name: Yup.string()
        .min(2, "Module name must be at least 2 characters long")
        .max(50, "Module name cannot be longer than 50 characters")
        .required("Module Name is required"),
        module_code: Yup.string()
        .min(2, "Module code must be at least 2 characters long")
        .max(30, "Module code cannot be longer than 50 characters"),
        license_type_code: Yup.string()
        .required("License type is required"),
        description: Yup.string()
        .min(2, "Description must be at least 2 characters long")
        .max(200, "Description cannot be longer than 50 characters"),      
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // For debugging
      setFormSubmitting(true);
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/modules",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                name: values.name,
                module_code: values.module_code,
                license_type_code: values.license_type_code,
                description: values.description,
                default_status: false,
                default_volume: 100,
              },
            ),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Module added successfully!");
        console.log("Form submission successful:", result);
        
        navigate('/master/modules');
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add module.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Add Module </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Module Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name &&
                          formik.errors.name ? (
                            <div className="text-danger">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Module Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="module_code"
                            name="module_code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.module_code}
                          />
                          {formik.touched.module_code && formik.errors.module_code ? (
                            <div className="text-danger">
                              {formik.errors.module_code}
                            </div>
                          ) : null}
                        </div>                     
                      
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Select License Type</label>
                            <select
                                name="license_type_code"
                                id="license_type_code"
                                className="form-control mb-3"
                                value={formik.values.license_type_code}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                // onChange={(e) =>
                                //   setLicenseType(e.target.value)
                                // }
                            >
                                <option value="">- Select -</option>
                                {licenseTypes.map((license) => (
                                <option
                                    key={license.id}
                                    value={license.code}
                                >
                                    {license.name}
                                </option>
                                ))}
                            </select>
                          </div>
                        </div>                            
                         
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                          {formik.touched.description && formik.errors.description ? (
                            <div className="text-danger">
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>                    
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          // disabled={formSubmitting}
                        >
                          {formSubmitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterAdminAddModule;
