import { useLocation } from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "./RealtorOffices.css";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type officeAddress = {
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  // phone: string;
};
type Office = {
  id: string;
  officeName: string;
  officeType: string;
  officeAddress: officeAddress;
};

const RealtorOffices = () => {
  const [offices, setOffices] = useState<Office[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); 
  const token = localStorage.getItem("sessionToken");
  
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.successMessage && !toastShown) {
      toast.success(location.state.successMessage);
      setToastShown(true);
      location.state.successMessage = null;
    }
  }, [location.state, toastShown]);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/offices", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setOffices(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOffices();
  }, [token]);

  const handleDelete = async (officeId: string) => {
    // const confirmation = window.confirm(
    //   "Are you sure you want to delete this license?"
    // );
    // if (!confirmation) return;

    setDeleting(officeId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/offices/${officeId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the office.");
      }

      toast.success("Office deleted successfully!");
      // Update the state by removing the deleted license
      setOffices((prev) =>
        prev.filter((office) => office.id !== officeId)
      );
      setDeleting(null); // Clear the deleting state
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  // if (!offices) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Offices </h2>
          <div className="text-end">
            <a
              href="/realtor/add-office"
              className="btn btn-primary float-right"
            >
              Add Office
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table
                    id="transactions"
                    className="table table-bordered dt-responsive nowrap table-striped align-middle"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "10px" }}>
                          <div className="form-check">
                            <input
                              className="form-check-input fs-15"
                              type="checkbox"
                              id="checkAll"
                              value="option"
                            />
                          </div>
                        </th>
                        <th>S.No.</th>
                        <th>Office Name</th>
                        {/* <th>Office ID</th> */}
                        <th>Office Type</th>
                        {/* <th>Office Phone</th> */}
                        <th>City</th>
                        <th>State</th>
                        {/* <th>Office Status</th> */}
                        <th>Country</th>
                        <th>Zip Code</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {offices!=null && offices.map((office) => (
                        <tr key={office.id}>
                          <th scope="row">
                            <div className="form-check">
                              <input
                                className="form-check-input fs-15"
                                type="checkbox"
                                name="checkAll"
                                value="option1"
                              />
                            </div>
                          </th>
                          <td>{office.id}</td>
                          <td>{office.officeName}</td>
                          {/* <td>{office.office_id}</td> */}
                          <td>{office.officeType}</td>
                          {/* <td>{office.officeAddress.phone}</td> */}
                          <td>{office.officeAddress.city}</td>
                          <td>{office.officeAddress.state}</td>
                          <td>{office.officeAddress.country}</td>
                          <td>{office.officeAddress.zip}</td>
                          <td>
                            <Link
                              to={`/realtor/view-office/${office.id}`}
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link to={`/realtor/edit-office/${office.id}`} className="btn btn-warning waves-effect waves-light">
                              <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <Link
                                  to="#"
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this office?"
                                    ) && handleDelete(office.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </Link>
                          </td>
                        </tr>
                      ))}                      
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorOffices;
