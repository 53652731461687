import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Plan {
  id: string;
  planName: string;
  description: string;
}
interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface PermissionsProps {
  plans: Plan[];
  licenseTypes: LicenseType[];
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  default_status: boolean;
  default_volume: number;
}

const SuperAdminAddPlanPermissions = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [selectedLicenseType, setSelectedLicenseType] = useState<string>("");
  const [modules, setModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [error, setError] = useState<string>("");
  const [submissionError, setSubmissionError] = useState<string>("");
  const [submissionSuccess, setSubmissionSuccess] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = localStorage.getItem("sessionToken");

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/plan-profiles",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/license-types",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, []);

  useEffect(() => {
    const fetchModules = async () => {
      if (!selectedLicenseType) return;

      setLoadingModules(true);
      setError("");
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/modules/license-type/${selectedLicenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch modules");

        const data = await response.json();
        setModules(data || []);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoadingModules(false);
      }
    };

    fetchModules();
  }, [selectedLicenseType, token]);

  // Handle input change for each module
  const handleModuleChange = (
    index: number,
    field: "default_status" | "default_volume",
    value: boolean | number
  ) => {
    setModules((prevModules) =>
      prevModules.map((module, i) =>
        i === index ? { ...module, [field]: value } : module
      )
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmissionError("");
    setSubmissionSuccess("");

    // Validate that both plan and license type are selected
    if (!selectedPlan || !selectedLicenseType) {
      setSubmissionError("Please select both a plan and a license type.");
      return;
    }

    const selectedPlanData = plans.find(
      (plan) => plan.planName === selectedPlan
    );
    if (!selectedPlanData) {
      setSubmissionError("Selected plan not found.");
      return;
    }

    const requestData = {
      planID: selectedPlanData.id,
      planName: selectedPlan,
      licenseType: selectedLicenseType,
      modules: modules.map((module) => ({
        name: module.name,
        module_code: module.module_code,
        license_type_code: module.license_type_code,
        status: module.default_status,
        volume: module.default_volume,
      })),
    };
    console.log(modules);

    try {
      const response = await fetch(
        "https://dev.reelty.app/api/v1/plan-permissions",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (!response.ok) throw new Error("Failed to submit permissions");

      // setSubmissionSuccess("Permissions successfully submitted!");
      //   toast.success("Permissions successfully submitted!");
      navigate("/superadmin/profiles", {
        state: { successMessage: "Permissions added successfully!" },
      });
    } catch (err) {
      setSubmissionError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    }
  };

  return (
    <div className="permissions">
      <div className="card">
        <div className="card-body">
            <h2>Add Plan Permissions </h2>
          <form onSubmit={handleSubmit} id="permissions">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="plan-select">Select Plan</label>
                  <select
                    name="planName"
                    className="form-control mb-3"
                    id="plan-select"
                    value={selectedPlan}
                    onChange={(e) => setSelectedPlan(e.target.value)}
                  >
                    <option value="">Select Plan</option>
                    {plans.map((plan) => (
                      <option key={plan.id} value={plan.planName}>
                        {plan.planName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="license-select">Select License Type</label>
                  <select
                    name="profileName"
                    className="form-control mb-3"
                    id="license-select"
                    value={selectedLicenseType}
                    onChange={(e) => setSelectedLicenseType(e.target.value)}
                  >
                    <option value="">Select License Type</option>
                    {licenseTypes.map((license) => (
                      <option key={license.id} value={license.code}>
                        {license.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {loadingModules ? (
              <p>Loading modules...</p>
            ) : error ? (
              <p>Error: {error}</p>
            ) : modules.length > 0 ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>Yes/No</th>
                      <th>Volume</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modules.map((module, index) => (
                      <tr key={module.id}>
                        <td>{module.name}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={module.default_status}
                            onChange={(e) =>
                              handleModuleChange(
                                index,
                                "default_status",
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={module.default_volume}
                            onChange={(e) =>
                              handleModuleChange(
                                index,
                                "default_volume",
                                +e.target.value
                              )
                            }
                            style={{ width: "100px" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No modules available for this license type.</p>
            )}

            {/* {submissionError && <p className="text-danger">Error: {submissionError}</p>}
      {submissionSuccess && <p className="text-success">{submissionSuccess}</p>} */}

            <div className="row mt-3 justify-content-center">
              <div className="col-md-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddPlanPermissions;
