import { Link, useRouteError } from "react-router-dom";

const Error = ()=>{
    const error = useRouteError();
    console.log(error);

    return(
        <main>
            <h2>404</h2>
            <h1>Page not found</h1>
            <p>Sorry we coudn't find the page you're looking for.</p>
            <Link to="/" className="btn btn-info">Go Back Home</Link>
        </main>
    );
};

export default Error;