import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; 
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type Address = {
  addressLine1: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

type Warehouse = {
  id: string;
  label: string;
  address: Address;
  status: string;
  created_at: string;
};

const SignageViewWarehouse: React.FC = () => {
  const { warehouseId } = useParams<{ warehouseId: string }>();
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const token = localStorage.getItem("sessionToken");

  useEffect(() => {
    const fetchWarehouse = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/warehouses/${warehouseId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch warehouse data");
        }
        const data: Warehouse = await response.json();
        setWarehouse(data);
      } catch (error) {
        setError("Error fetching warehouse data. Please try again later.");
        console.error("Error fetching warehouse data:", error);
      }
    };

    if (warehouseId) {
      fetchWarehouse();
    }
  }, [warehouseId, token]);

  if (error) return <div>{error}</div>;
  if (!warehouse) return <div>Loading Warehouse Data...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>View Warehouse Details</h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th colSpan={4}>Warehouse Information</th>
                        </tr>
                        <tr>
                          <th>Warehouse Name:</th>
                          <td>{warehouse?.label ?? "N/A"}</td>
                          <th>Warehouse ID:</th>
                          <td>{warehouse?.id ?? "N/A"}</td>
                        </tr>
                        <tr>
                          <th colSpan={4}>Warehouse Address</th>
                        </tr>
                        <tr>
                          <th>Street Address:</th>
                          <td>{warehouse?.address?.addressLine1 ?? "N/A"}</td>
                          <th>City:</th>
                          <td>{warehouse?.address?.city ?? "N/A"}</td>
                        </tr>
                        <tr>
                          <th>State:</th>
                          <td>{warehouse?.address?.state ?? "N/A"}</td>
                          <th>Zipcode:</th>
                          <td>{warehouse?.address.zip}</td>
                        </tr>
                        <tr>
                        <th>Status:</th>
                        <td>{(warehouse?.status)?"Active":"Inactive"}</td>
                        <th>Created At:</th>
                          <td>{warehouse?.created_at
                              ? new Date(warehouse.created_at).toLocaleDateString("en-US")
                              : "N/A"}</td>
                          
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageViewWarehouse;
