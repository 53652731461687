import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";

interface License {
    id: string;
    companyName: string;
    jobTitle: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    statuss: string;
    licenseValidity: string | null;
    billingCycle: string | null;
    billingCyclePrice: number | null;
    paymentMethod: string;
    licenseType: string;
    offices: string[] | null;
    warehouses: string[] | null;
  }

  type Warehouse = {
    id: string;
    label: string;
  };

const TeammemberSidebar = () => {
    const token = localStorage.getItem("sessionToken");
    const userId = localStorage.getItem("userId");
    const [license, setLicense] = useState<License | null>(null);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState(true);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

    
  const warehousesOptions = useMemo(
    () =>
      warehouses.map((warehouse) => ({
        value: warehouse.id,
        label: warehouse.label,
      })),
    [warehouses]
  );

    useEffect(() => {
        const fetchLicense = async () => {
          try {
            const response = await fetch(
              `https://dev.reelty.app/api/v1/users/${userId}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
    
            if (!response.ok) {
              throw new Error("Failed to fetch license data");
            }
    
            const data = await response.json();
            console.log("Fetched License Data:", data);
    
            if (data && data.firmDetails && data.firmDetails.address) {
              const fetchedLicense: License = {
                id: data.id || "",
                companyName: data.firmDetails.companyName || "",
                jobTitle: data.jobTitle || "",
                firstName: data.firstName || "",
                lastName: data.lastName || "",
                email: data.email || "",
                phone: data.phone1 || "",
                addressLine1: data.firmDetails.address.addressLine1 || "",
                addressLine2: data.firmDetails.address.addressLine2 || "",
                city: data.firmDetails.address.city || "",
                state: data.firmDetails.address.state || "",
                zip: data.firmDetails.address.zip || "",
                country: data.firmDetails.address.country || "",
                statuss: data.status || "Active",
                licenseValidity: data.licenseValidity || null,
                billingCycle: data.billingCycle || null,
                billingCyclePrice: data.billingCyclePrice || null,
                paymentMethod: data.paymentMethod || "",
                licenseType: data.licenseType || "", 
                offices: data.offices || null,
                warehouses: data.warehouses || null,               
              };
    
              setLicense(fetchedLicense);              
            } else {
              console.error("Incomplete data", data);
            }
          } catch (error) {
            console.error("Error fetching license data:", error);
            setError("Failed to fetch license data.");
          } finally {
            setLoading(false);
          }
        };
    
        if (!license) {
          fetchLicense();
        }
      }, [userId, license, token]);

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        {/* Logo */}
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-dark.png" alt="" height="17" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-light.png" alt="" height="17" />
          </span>
        </Link>

        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      {/* User Dropdown */}
      <div className="dropdown sidebar-user m-1 rounded">
        <button
          type="button"
          className="btn material-shadow-none"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="d-flex align-items-center gap-2">
            <img
              className="rounded header-profile-user"
              src="/assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />
            <span className="text-start">
              <span className="d-block fw-medium sidebar-user-name-text">
                Anna Adame
              </span>
              <span className="d-block fs-14 sidebar-user-name-sub-text">
                <i className="ri-circle-fill fs-10 text-success align-baseline"></i>{" "}
                <span className="align-middle">Online</span>
              </span>
            </span>
          </span>
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <h6 className="dropdown-header">Welcome Anna!</h6>
          <Link to="/profile" className="dropdown-item">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Profile</span>
          </Link>
          <Link to="/messages" className="dropdown-item">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Messages</span>
          </Link>
          <Link to="/tasks" className="dropdown-item">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Taskboard</span>
          </Link>
          <Link to="/help" className="dropdown-item">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Help</span>
          </Link>
          <div className="dropdown-divider"></div>
          <Link to="/profile" className="dropdown-item">
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              Balance: <b>$5971.67</b>
            </span>
          </Link>
          <Link to="/settings" className="dropdown-item">
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </Link>
          <Link to="/lockscreen" className="dropdown-item">
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Lock screen</span>
          </Link>
          <Link to="/logout" className="dropdown-item">
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Logout</span>
          </Link>
        </div>
      </div>

      {/* Sidebar Navigation */}
      <div id="scrollbar">
        <div className="container-fluid">
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <Link to="/teammember/dashboard" className="nav-link menu-link">
                <i className="ri-dashboard-2-line"></i>{" "}
                <span data-key="t-dashboards">Dashboard</span>
              </Link>
            </li>
            {license?.warehouses !== null && (
                <li className="nav-item">
                    <Link to="/teammember/warehouses" className="nav-link menu-link">
                    <i className="ri-user-line"></i>{" "}
                    <span data-key="t-dashboards">Warehouses</span>
                    </Link>
                </li>
            )}
            {license?.offices !== null && (
                <li className="nav-item">
                    <Link to="/teammember/offices" className="nav-link menu-link">
                    <i className="ri-user-line"></i>{" "}
                    <span data-key="t-dashboards">Offices</span>
                    </Link>
                </li>
            )}

            <li className="nav-item">
              <Link to="/teammember/brands" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Brands </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/categories" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Categories </span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/teammember/inventory" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Inventory </span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/teammember/profile" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Profile</span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/teammember/customers" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Customers</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/teammembers" className="nav-link menu-link">
                <i className="ri-group-line"></i>{" "}
                <span data-key="t-dashboards">Team Members</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/teammembers" className="nav-link menu-link">
                <i className="ri-user-3-line"></i>{" "}
                <span data-key="t-dashboards">Teammembers</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/inventory" className="nav-link menu-link">
                <i className="ri-database-2-line"></i>{" "}
                <span data-key="t-dashboards">Inventory</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/work-orders" className="nav-link menu-link">
                <i className="ri-calendar-check-line"></i>{" "}
                <span data-key="t-dashboards">Work Orders</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/categories" className="nav-link menu-link">
                <i className="ri-folder-line"></i>{" "}
                <span data-key="t-dashboards">Categories</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/products" className="nav-link menu-link">
                <i className="ri-shopping-bag-line"></i>{" "}
                <span data-key="t-dashboards">Products</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/teammember/orders" className="nav-link menu-link">
                <i className="ri-shopping-cart-line"></i>{" "}
                <span data-key="t-dashboards">Orders</span>
              </Link>
            </li> */}
            <li className="nav-item">
            <LogoutButton />
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-background"></div>
    </div>
  );
};

export default TeammemberSidebar;
