import React from "react";

const TeammemberDashboard = ()=>{
    return(
        <div className="dashboard">
            <h2>Dashboard</h2>
        </div>
    );
}

export default TeammemberDashboard;