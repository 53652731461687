import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "./SignageAddProduct.css";

type FormValues = {
  name: string;
  product_type: string;
  sku: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  condition_note: string;
  purchase_price: string;
  retail_price: string;
  length: string;
  height: string;
  width: string;
  weight: string;
  threshold_qty: string;
  attribute: string;
  parameter: string;
  notes: string;
  image: File | null;
  statuss: string;
  is_rotational: string;
  warehouseId: string;
  on_hand: string;
  available: string;
  reserved: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Warehouse = {
  id: string;
  label: string;
  status: string;
};

type Attribute = {
  id: string;
  name: string;
  values: string[];
  description: string;
  status: string;
};

type AttributeValuePair = {
  attribute: string;
  value: string;
};

const SignageAddProduct = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [attrOptions, setAttrOptions] = useState([]);
  const [selectedWarehouseName, setSelectedWarehouseName] = useState<
    string | null
  >(null);

  // State to hold the attribute-value combinations
  const [options, setOptions] = useState<
    { attribute: string; value: string }[]
  >([]);

  // Add attribute-value combination
  const handleAddOption = () => {
    const { attribute, parameter } = formik.values;

    if (!attribute || !parameter) {
      alert("Please select both an attribute and a value.");
      return;
    }

    const isDuplicate = options.some(
      (option) => option.attribute === attribute && option.value === parameter
    );
    if (isDuplicate) {
      alert("This combination already exists.");
      return;
    }

    setOptions((prev) => [...prev, { attribute, value: parameter }]);
    toast.success("Attribute-Value pair added successfully!");

    formik.setFieldValue("attribute", "");
    formik.setFieldValue("parameter", "");
  };

  // Remove an option
  const handleRemoveOption = (index: number) => {
    setOptions((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/brands", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/categories",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/warehouses",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setWarehouses(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouses();
  }, [token]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/product-attributes",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setAttributes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAttributes();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "brands");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://dev.reelty.app/api/v1/images/upload", true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  const createSlug = (input: string): string => {
    return input
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading and trailing whitespace
      .replace(/[^a-z0-9\s-]/g, "") // Remove invalid characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single one
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
      product_type: "simple",
      sku: "",
      description: "",
      brand: "",
      category: "",
      item_condition: "",
      condition_note: "",
      purchase_price: "",
      retail_price: "",
      length: "",
      height: "",
      width: "",
      weight: "",
      threshold_qty: "",
      attribute: "",
      parameter: "",
      notes: "",
      image: null,
      statuss: "",
      is_rotational: "",
      warehouseId: "",
      on_hand: "0",
      available: "0",
      reserved: "0",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      image: Yup.mixed()
        .required("Image is required")
        .test("fileType", "Unsupported file format", (value) =>
          value instanceof File
            ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
            : false
        ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      if (!(values.image instanceof File)) {
        toast.error("Please upload a valid image file.");
        setFormSubmitting(false);
        return;
      }
      // const selectedOptions:AttributeValuePair[] = options;
      const transformedObject = options.reduce<Record<string, string>>((obj, item) => {
        obj[item.attribute.toLowerCase()] = item.value.trim();
        return obj;
      }, {});      

      try {
        const imageUrl = await uploadFile(values.image);
        console.log("Image uploaded successfully:", imageUrl);

        // 2. Submit product details
        const response = await fetch(
          `https://dev.reelty.app/api/v1/signage/${userId}/inventory`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              product_type: values.product_type,
              sku: values.sku,
              description: values.description,
              brand: values.brand,
              category: values.category,
              item_condition: values.item_condition,
              purchase_price: Number(values.purchase_price),
              retail_price: Number(values.retail_price),
              package_dimensions: {
                length: Number(values.length),
                height: Number(values.height),
                width: Number(values.width),
                weight: Number(values.weight),
              },
              min_qty: 1,
              variants: [
                {
                  variant_name: values.name,
                  sku: values.sku,
                  attributes: transformedObject,
                  purchase_price: 6000,
                  retail_price: 8000,
                  package_dimensions: {
                    length: Number(values.length),
                    height: Number(values.height),
                    width: Number(values.width),
                    weight: Number(values.weight),
                    weight_type: "kg",
                  },
                  inventory: [
                    {
                      location_id: values.warehouseId,
                      location_name: selectedWarehouseName,
                      on_hand: Number(values.on_hand),
                      available: Number(values.available),
                      reserved: Number(values.reserved),
                    },
                  ],
                  meta_data: {
                    slug: createSlug(values.name),
                  },
                },
              ],
              threshold_qty: Number(values.threshold_qty),
              image: { url: imageUrl },
              status: values.statuss,
              is_rotational: values.is_rotational == "yes" ? true : false,
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        navigate("/signage/products", {
          state: { successMessage: "Product added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Add Simple Product </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-pills nav-success mb-3" role="tablist">
                <li className="nav-item waves-effect waves-light">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#product-details"
                    role="tab"
                  >
                    Product Details
                  </a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#images"
                    role="tab"
                  >
                    Images
                  </a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#orders"
                    role="tab"
                  >
                    Orders
                  </a>
                </li>
                <li className="nav-item waves-effect waves-light">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#inventory-log"
                    role="tab"
                  >
                    Inventory Log
                  </a>
                </li>
              </ul>

              <div className="tab-content text-muted">
                <div
                  className="tab-pane active"
                  id="product-details"
                  role="tabpanel"
                >
                  <div className="d-flex mt-2">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-8 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                SKU <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="sku"
                                name="sku"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.sku}
                              />
                              {formik.touched.sku && formik.errors.sku ? (
                                <div className="text-danger">
                                  {formik.errors.sku}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-8">
                              <div className="card">
                                <div className="card-body">
                                  {/* <h5>Branding</h5> */}
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">Brand</label>
                                    <select
                                      className="form-control"
                                      id="brand"
                                      name="brand"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.brand}
                                    >
                                      <option value="">- Select -</option>
                                      {brands !== null &&
                                        brands.map((brand) => (
                                          <option value={brand.name}>
                                            {brand.name}
                                          </option>
                                        ))}
                                    </select>
                                    {formik.touched.brand &&
                                    formik.errors.brand ? (
                                      <div className="text-danger">
                                        {formik.errors.brand}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Category
                                    </label>
                                    <select
                                      className="form-control"
                                      id="category"
                                      name="category"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.category}
                                    >
                                      <option value="">- Select -</option>
                                      {categories !== null &&
                                        categories.map((category) => (
                                          <option value={category.name}>
                                            {category.name}
                                          </option>
                                        ))}
                                    </select>
                                    {formik.touched.category &&
                                    formik.errors.category ? (
                                      <div className="text-danger">
                                        {formik.errors.category}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="card">
                                <div className="card-body">
                                  {/* <h5>Pricing</h5> */}
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Purchase Price{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="purchase_price"
                                      name="purchase_price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.purchase_price}
                                    />
                                    {formik.touched.purchase_price &&
                                    formik.errors.purchase_price ? (
                                      <div className="text-danger">
                                        {formik.errors.purchase_price}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Retail Price{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="retail_price"
                                      name="retail_price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.retail_price}
                                    />
                                    {formik.touched.retail_price &&
                                    formik.errors.retail_price ? (
                                      <div className="text-danger">
                                        {formik.errors.retail_price}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description/Notes{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label htmlFor="warehouseId">
                                          Warehouse
                                        </label>
                                        <select
                                          name="warehouseId"
                                          id="warehouseId"
                                          className="form-control"
                                          value={formik.values.warehouseId}
                                          onChange={(e) => {
                                            const selectedId = e.target.value;
                                            formik.setFieldValue("warehouseId", selectedId);
                                          
                                            // Find the selected warehouse name
                                            const selectedWarehouse = warehouses.find((w) => w.id === selectedId);
                                            setSelectedWarehouseName(selectedWarehouse ? selectedWarehouse.label : null);
                                          }}                                          
                                          onBlur={formik.handleBlur}
                                        >
                                          <option value="">- Select -</option>
                                          {warehouses.map((warehouse) => (
                                            <option
                                              key={warehouse.id}
                                              value={warehouse.id}
                                            >
                                              {warehouse.label}
                                            </option>
                                          ))}
                                        </select>
                                        {formik.touched.warehouseId &&
                                        formik.errors.warehouseId ? (
                                          <div className="text-danger">
                                            {formik.errors.warehouseId}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="col-md-2">
                                        <label htmlFor="on_hand">On Hand</label>
                                        <input
                                          type="text"
                                          name="on_hand"
                                          id="on_hand"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.on_hand}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <label htmlFor="available">
                                          Available
                                        </label>
                                        <input
                                          type="text"
                                          name="available"
                                          id="available"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.available}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <label htmlFor="reserved">
                                          Reserved
                                        </label>
                                        <input
                                          type="text"
                                          name="reserved"
                                          id="reserved"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.reserved}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <strong>Attributes</strong>
                                    <div className="row">
                                      <div className="col-md-5 mb-3">
                                        {/* Attribute Dropdown */}
                                        <label className="form-label">
                                          Option
                                        </label>
                                        <select
                                          className="form-control"
                                          id="attribute"
                                          name="attribute"
                                          onChange={(e) => {
                                            formik.handleChange(e);
                                            // Reset the parameter value when the attribute changes
                                            formik.setFieldValue(
                                              "parameter",
                                              ""
                                            );
                                          }}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.attribute}
                                        >
                                          <option value="">-Select-</option>
                                          {attributes != null &&
                                            attributes.map(
                                              (attribute, index) => (
                                                <option
                                                  key={index}
                                                  value={attribute.name}
                                                >
                                                  {attribute.name}
                                                </option>
                                              )
                                            )}
                                        </select>
                                        {formik.touched.attribute &&
                                        formik.errors.attribute ? (
                                          <div className="text-danger">
                                            {formik.errors.attribute}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="col-md-5 mb-3">
                                        {/* Value Dropdown */}
                                        <label className="form-label">
                                          Value
                                        </label>
                                        <select
                                          className="form-control"
                                          id="parameter"
                                          name="parameter"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.parameter}
                                          disabled={!formik.values.attribute} // Disable if no attribute selected
                                        >
                                          <option value="">-Select-</option>
                                          {/* Filter values based on the selected attribute */}
                                          {attributes
                                            ?.find(
                                              (attr) =>
                                                attr.name ===
                                                formik.values.attribute
                                            )
                                            ?.values.map((value, index) => (
                                              <option key={index} value={value}>
                                                {value}
                                              </option>
                                            ))}
                                        </select>
                                        {formik.touched.parameter &&
                                        formik.errors.parameter ? (
                                          <div className="text-danger">
                                            {formik.errors.parameter}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="col-md-2 pt-4">
                                        <button
                                          type="button"
                                          className="btn btn-info"
                                          onClick={handleAddOption}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="options">
                                        {options.length === 0 && (
                                          <p>No options added yet.</p>
                                        )}
                                        {options.map((option, index) => (
                                          <div
                                            key={index}
                                            className="option-item d-flex mb-2"
                                          >
                                            <span className="me-2">
                                              {option.attribute} -{" "}
                                              {option.value}
                                            </span>
                                            <button
                                              type="button"
                                              className="btn btn-danger btn-sm"
                                              onClick={() =>
                                                handleRemoveOption(index)
                                              }
                                            >
                                              X
                                            </button>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Threshold Quantity{" "}
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="threshold_qty"
                                          name="threshold_qty"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.threshold_qty}
                                        />
                                        {formik.touched.threshold_qty &&
                                        formik.errors.threshold_qty ? (
                                          <div className="text-danger">
                                            {formik.errors.threshold_qty}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Status{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          id="statuss"
                                          name="statuss"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.statuss}
                                        >
                                          <option value="">- Select -</option>
                                          <option value="Active">Active</option>
                                          <option value="Inactive">
                                            Inactive
                                          </option>
                                        </select>
                                        {formik.touched.statuss &&
                                        formik.errors.statuss ? (
                                          <div className="text-danger">
                                            {formik.errors.statuss}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Is Rotational{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          id="is_rotational"
                                          name="is_rotational"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.is_rotational}
                                        >
                                          <option value="">- Select -</option>
                                          <option value="yes">Yes</option>
                                          <option value="no">No</option>
                                        </select>
                                        {formik.touched.is_rotational &&
                                        formik.errors.is_rotational ? (
                                          <div className="text-danger">
                                            {formik.errors.is_rotational}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Dimensions/Measurements (Inches)
                                  </label>
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="length"
                                        name="length"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.length}
                                        placeholder="Length"
                                      />
                                      {formik.touched.length &&
                                      formik.errors.length ? (
                                        <div className="text-danger">
                                          {formik.errors.length}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="height"
                                        name="height"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.height}
                                        placeholder="Height"
                                      />
                                      {formik.touched.height &&
                                      formik.errors.height ? (
                                        <div className="text-danger">
                                          {formik.errors.height}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="width"
                                        name="width"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.width}
                                        placeholder="Width"
                                      />
                                      {formik.touched.width &&
                                      formik.errors.width ? (
                                        <div className="text-danger">
                                          {formik.errors.width}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <label className="form-label">
                                        Weight(in gms)
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="weight"
                                        name="weight"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.weight}
                                      />
                                      {formik.touched.weight &&
                                      formik.errors.weight ? (
                                        <div className="text-danger">
                                          {formik.errors.weight}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                {/* <h5>Pricing</h5> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Item Condition
                                  </label>
                                  <select
                                    className="form-control"
                                    id="item_condition"
                                    name="item_condition"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.item_condition}
                                  >
                                    <option value="">- Select -</option>
                                    <option value="new">New</option>
                                    <option value="used">Used</option>
                                    <option value="damaged">Damaged</option>
                                  </select>
                                  {formik.touched.item_condition &&
                                  formik.errors.item_condition ? (
                                    <div className="text-danger">
                                      {formik.errors.item_condition}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Condition Notes{" "}
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="condition_note"
                                    name="condition_note"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.condition_note}
                                    rows={3}
                                  ></textarea>
                                  {formik.touched.condition_note &&
                                  formik.errors.condition_note ? (
                                    <div className="text-danger">
                                      {formik.errors.condition_note}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12 mb-3">
                                  <div className="row">
                                    <div className="col-md-12 mb-3">
                                      <label className="form-label">
                                        Image{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        name="image"
                                        onChange={(event) => {
                                          const file =
                                            event.currentTarget.files?.[0];
                                          if (file) {
                                            formik.setFieldValue("image", file);
                                            setPreview(
                                              URL.createObjectURL(file)
                                            ); // Optional: show preview
                                          }
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                      {preview && (
                                        <img
                                          src={preview}
                                          alt="Preview"
                                          style={{
                                            maxWidth: "100px",
                                            marginTop: "10px",
                                          }}
                                        />
                                      )}
                                      {formik.touched.image &&
                                      formik.errors.image ? (
                                        <div className="text-danger">
                                          {formik.errors.image}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                      <input type="hidden" id="product_type" name="product_type" onChange={formik.handleChange}
                                          onBlur={formik.handleBlur} value={formik.values.product_type} />
                        <button className="btn btn-success w-100" type="submit">
                          Save Inventory Item
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="tab-pane" id="images" role="tabpanel">
                  <div className="d-flex mt-2">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Product Gallery</h5>
                      </div>
                      <div className="card-body">
                        {/* <div className="mb-4">
                          <h5 className="fs-14 mb-1">Product Image</h5>
                          <p className="text-muted">Add Product main Image.</p>
                          <div className="text-center">
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute top-100 start-100 translate-middle">
                                <label
                                  htmlFor="product-image-input"
                                  className="mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="Select Image"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control d-none"
                                  value=""
                                  id="product-image-input"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                />
                              </div>
                              <div className="avatar-lg">
                                <div className="avatar-title bg-light rounded">
                                  <img
                                    src=""
                                    id="product-img"
                                    className="avatar-md h-auto"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <h5 className="fs-14 mb-1">Product Gallery</h5>
                          <p className="text-muted">
                            Add Product Gallery Images.
                          </p>

                          {/* <div className="dropzone">
                            <div className="fallback">
                              <input name="file" type="file" />
                            </div>
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
                              </div>

                              <h5>Drop files here or click to upload.</h5>
                            </div>
                          </div> */}

                          <ul
                            className="list-unstyled mb-0"
                            id="dropzone-preview"
                          >
                            <li className="mt-2" id="dropzone-preview-list">
                              <div className="border rounded">
                                <div className="d-flex p-2">
                                  <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm bg-light rounded">
                                      <img
                                        data-dz-thumbnail
                                        className="img-fluid rounded d-block"
                                        src="#"
                                        alt="Product-Image"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="pt-1">
                                      <h5 className="fs-14 mb-1" data-dz-name>
                                        &nbsp;
                                      </h5>
                                      <p
                                        className="fs-13 text-muted mb-0"
                                        data-dz-size
                                      ></p>
                                      <strong
                                        className="error text-danger"
                                        data-dz-errormessage
                                      ></strong>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 ms-3">
                                    <button
                                      data-dz-remove
                                      className="btn btn-sm btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="orders" role="tabpanel">
                  <div className="d-flex mt-2">
                    <p>Orders</p>
                  </div>
                </div>
                <div className="tab-pane" id="inventory-log" role="tabpanel">
                  <div className="d-flex mt-2">
                    <p>Iventory logs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="mt-0 card-bg-fill">
            <div className="pt-1">
              <div className="pt-1 mt-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageAddProduct;
