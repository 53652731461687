// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    width: 100%;
    overflow-x: auto; /* Enables horizontal scrolling */
    margin: 0 auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  thead {
    background-color: #f4f4f4;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  @media (max-width: 768px) {
    th, td {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/realtor/RealtorViewGroup.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB,EAAE,iCAAiC;IACnD,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE;MACE,eAAe,EAAE,yCAAyC;IAC5D;EACF","sourcesContent":[".table-container {\n    width: 100%;\n    overflow-x: auto; /* Enables horizontal scrolling */\n    margin: 0 auto;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  thead {\n    background-color: #f4f4f4;\n  }\n  \n  th, td {\n    padding: 12px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n  \n  @media (max-width: 768px) {\n    th, td {\n      font-size: 14px; /* Adjust font size for smaller screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
