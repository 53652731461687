import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tabs from "../../components/Tabs";
import PlanProfiles from "../../components/PlanProfiles";
import Permissions from "../../components/Permissions";
import ProfileGroups from "../../components/ProfileGroups";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Plan {
  id: string;
  planName: string;
  description: string;
}
interface LicenseType {
  id: string;
  name: string;
  code: string;
}
interface ModulePermission {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
}
interface PlanPermission {
  id: string;
  planID: string;
  planName: string;
  licenseType: string;
  modules: ModulePermission[];
}

interface Plan {
  id: string;
  planName: string;
  description: string;
}

interface PlanGroup {
  id: string;
  name: string;
  planProfile: Plan[];
}

const SuperAdminProfiles = () => {  
  const [plans, setPlans] = useState<Plan[]>([]);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [planPermissions, setPlanPermissions] = useState<PlanPermission[]>([]);
  const [planGroups, setPlanGroups] = useState<PlanGroup[]>([]);

  const [plansLoading, setPlansLoading] = useState(true);
  const [licenseTypesLoading, setLicenseTypesLoading] = useState(true);
  const [planPermissionsLoading, setPlanPermissionsLoading] = useState(true);
  const [planGroupsLoading, setPlanGroupsLoading] = useState(true);

  const [plansError, setPlansError] = useState('');
  const [licenseTypesError, setLicenseTypesError] = useState('');
  const [planPermissionsError, setPlanPermissionsError] = useState('');
  const [planGroupsError, setPlanGroupsError] = useState('');

  const token = localStorage.getItem('sessionToken');
  const location = useLocation();

  // Display success message if redirected with success state
  useEffect(() => {
    if (location.state?.successMessage) {
      toast.success(location.state.successMessage);
    }
  }, [location.state]);

  // Fetch Plans
  useEffect(() => {
    const fetchPlans = async () => {
      setPlansLoading(true);
      try {
        const response = await fetch('https://dev.reelty.app/api/v1/plan-profiles', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setPlansError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setPlansLoading(false);
      }
    };
    fetchPlans();
  }, [token]);

  // Fetch License Types
  useEffect(() => {
    const fetchLicenseTypes = async () => {
      setLicenseTypesLoading(true);
      try {
        const response = await fetch('https://dev.reelty.app/api/v1/license-types', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setLicenseTypesError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setLicenseTypesLoading(false);
      }
    };
    fetchLicenseTypes();
  }, [token]);

  // Fetch Plan Permissions
  useEffect(() => {
    const fetchPlanPermissions = async () => {
      setPlanPermissionsLoading(true);
      try {
        const response = await fetch('https://dev.reelty.app/api/v1/plan-permissions', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setPlanPermissions(data);
      } catch (err) {
        setPlanPermissionsError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setPlanPermissionsLoading(false);
      }
    };
    fetchPlanPermissions();
  }, [token]);

  // Fetch Plan Groups
  useEffect(() => {
    const fetchPlanGroups = async () => {
      setPlanGroupsLoading(true);
      try {
        const response = await fetch('https://dev.reelty.app/api/v1/plan-groups', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        setPlanGroups(data);
      } catch (err) {
        setPlanGroupsError(err instanceof Error ? err.message : 'An unknown error occurred');
      } finally {
        setPlanGroupsLoading(false);
      }
    };
    fetchPlanGroups();
  }, [token]);

  // Delete Plan
  const deletePlan = async (id: string) => {
    try {
      const response = await fetch(`https://dev.reelty.app/api/v1/plan-profile/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to delete plan');
      setPlans(plans.filter(plan => plan.id !== id));
      console.log(`Plan with ID ${id} deleted successfully`);
    } catch (err) {
      setPlansError(err instanceof Error ? err.message : 'An unknown error occurred');
    }
  };

  // Delete Plan Permissions
  const deletePlanPermissions = async (id: string) => {
    try {
      const response = await fetch(`https://dev.reelty.app/api/v1/plan-permissions/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to delete plan permissions');
      setPlanPermissions(planPermissions.filter(planPermission => planPermission.id !== id));
      console.log(`Plan Permissions with ID ${id} deleted successfully`);
    } catch (err) {
      setPlanPermissionsError(err instanceof Error ? err.message : 'An unknown error occurred');
    }
  };

  // Delete Plan Group
  const deletePlanGroup = async (id: string) => {
    try {
      const response = await fetch(`https://dev.reelty.app/api/v1/plan-groups/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to delete plan group');
      setPlanGroups(planGroups.filter(planGroup => planGroup.id !== id));
      console.log(`Plan group with ID ${id} deleted successfully`);
    } catch (err) {
      setPlanPermissionsError(err instanceof Error ? err.message : 'An unknown error occurred');
    }
  };

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Plans</h2>
          <Tabs>
            <PlanProfiles plans={plans} loading={plansLoading} error={plansError} deletePlan={deletePlan} />
            <Permissions planPermissions={planPermissions} licenseTypes={licenseTypes} deletePlanPermissions={deletePlanPermissions} />
            <ProfileGroups planGroups={planGroups} loading={planGroupsLoading} error={planGroupsError} deletePlanGroup={deletePlanGroup} />
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminProfiles;

