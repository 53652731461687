import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type FormValues = {
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  length: number;
  height: number;
  width: number;
  weight: number;
  threshold_qty: number;
  warehouseId: string;
  installer: string;
  bin_location: string;
  notes: string;
  // image: File | null;
  statuss: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      location_id: string;
      location_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type Product = {
  product_id: string;
  name: string;
  sku: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  threshold_qty: number;
  package_dimensions: Dimensions;
  // notes: string;
  // image: Image | null;
  variants: Variant[] | [];
  status: string;
  linked_installer: {
    id: string;
    name: string;
    warehouse: Warehouse | null;
  } | null;
};

type Image = {
  url: string;
};

type Warehouse = {
  id: string;
  label: string;
  bin_location: string;
  status: string;
};

type AgentProduct = {
  product: Product;
  created_by: {
    id: string;
    name: string;
  };
};

const SignageEditRotationalProduct = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const { productId } = useParams<{ productId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [product, setProduct] = useState<Product>();
  const [agentproduct, setAgentProduct] = useState<AgentProduct>();
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(
    null
  );

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://dev.reelty.app/api/v1/agent/${agentId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch category data");
        }
        const data = await response.json();
        console.log(data);
        setAgentProduct(data);
        setProduct(data.product);
      } catch (err) {
        console.error("Error fetching category data:", err);
        toast.error("Error fetching category data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (productId) fetchProduct();
  }, [productId, token]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/brands", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/categories",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/warehouses",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setWarehouses(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouses();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  // Handler for warehouse selection
const handleWarehouseChange = (
  event: React.ChangeEvent<HTMLSelectElement>
) => {
  const selectedId = event.target.value;
  const selected = warehouses.find((wh) => wh.id === selectedId) || null;
  setSelectedWarehouse(selected);
  formik.setFieldValue("warehouseId", selectedId); // Update Formik field value
};

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "products");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://dev.reelty.app/api/v1/images/upload", true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: product?.name || "",
      description: product?.description || "",
      brand: product?.brand || "",
      category: product?.category || "",
      item_condition: product?.item_condition || "",
      purchase_price: product?.purchase_price || 0,
      retail_price: product?.retail_price || 0,
      length: product?.package_dimensions?.length || 0,
      height: product?.package_dimensions?.height || 0,
      width: product?.package_dimensions?.width || 0,
      weight: product?.package_dimensions?.weight || 0,
      warehouseId: product?.linked_installer?.warehouse?.id || "",
      threshold_qty: product?.threshold_qty || 0,
      installer: "",
      bin_location: product?.linked_installer?.warehouse?.bin_location || "",
      notes: "",
      // image: null,
      statuss: product?.status || "active",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      // image: Yup.mixed()
      //   .required("Image is required")
      //   .test("fileType", "Unsupported file format", (value) =>
      //     value instanceof File
      //       ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
      //       : true
      //   ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      // if (!(values.image instanceof File)) {
      //   toast.error("Please upload a valid image file.");
      //   setFormSubmitting(false);
      //   return;
      // }

      try {
        // const imageUrl = await uploadFile(values.image);
        // console.log("Image uploaded successfully:", imageUrl);

        // 2. Submit product details
        const response = await fetch(
          `https://dev.reelty.app/api/v1/agent/${agentId}/inventory/${productId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: product?.name,
              sku: product?.sku,
              description: product?.description,
              brand: product?.brand,
              category: product?.category,
              item_condition: values.item_condition,
              purchase_price: Number(product?.purchase_price),
              retail_price: Number(product?.retail_price),
              package_dimensions: {
                length: Number(values.length),
                height: Number(values.height),
                width: Number(values.width),
                weight: Number(values.weight),
              },
              threshold_qty: Number(product?.threshold_qty),
              // image: { url: imageUrl },
              status: values.statuss,
              is_rotational: true,
              linked_mode: "standalone",
              linked_installer: {
                id: userId,
                name: userName,
                warehouse: {
                  id: selectedWarehouse?.id,
                  label: selectedWarehouse?.label,
                  bin_location: values.bin_location,
                },
              }, 
              // variants: [
              //   {
              //     variant_name: product?.name,
              //     sku: product?.sku,
              //     attributes: "",
              //     purchase_price: Number(product?.purchase_price),
              //     retail_price: Number(product?.retail_price),
              //     package_dimensions: {
              //       length: Number(values.length),
              //       height: Number(values.height),
              //       width: Number(values.width),
              //       weight: Number(values.weight),
              //       weight_type: "g",
              //     },
              //     threshold_qty: Number(product?.threshold_qty),
              //     inventory: [
              //       {
              //         location_id: selectedWarehouse?.id,
              //         location_name: selectedWarehouse?.label,
              //         on_hand: 10,
              //         available: 10,
              //         reserved: 10,
              //       },                  
              //     ],
              //     is_rotational: true,
              //   },
              // ],             
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        navigate("/signage/rotationalProducts", {
          state: { successMessage: "Product updated successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Rotational Product Management </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-1 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <table>
                            <tr>
                              <th>Status: </th>
                              <td>Assigned Rotation</td>
                            </tr>
                            <tr>
                              <th>Product Added to Pool: </th>
                              <td>2024-11-12</td>
                            </tr>
                            <tr>
                              <th>Brokerage Office:</th>
                              <td>Main Office</td>
                            </tr>
                            <tr>
                              <th>Product Name:</th>
                              <td>{product?.name}</td>
                            </tr>
                            <tr>
                              <th>Product Category:</th>
                              <td>{product?.category}</td>
                            </tr>
                            <tr>
                              <th>Quantity:</th>
                              <td>
                                On Hand:{" "}
                                {
                                  product?.variants?.[0]?.inventory?.[0]
                                    ?.on_hand
                                }{" "}
                                | Reserved:{" "}
                                {
                                  product?.variants?.[0]?.inventory?.[0]
                                    ?.reserved
                                }{" "}
                                | Available:{" "}
                                {
                                  product?.variants?.[0]?.inventory?.[0]
                                    ?.available
                                }
                              </td>
                            </tr>
                            <tr>
                              <th>Condition:</th>
                              <td>
                                <select
                                  className="form-control"
                                  id="item_condition"
                                  name="item_condition"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.item_condition}
                                >
                                  <option value="">- Select -</option>
                                  <option value="new">New</option>
                                  <option value="used">Used</option>
                                  <option value="damaged">Damaged</option>
                                </select>
                                {formik.touched.item_condition &&
                                formik.errors.item_condition ? (
                                  <div className="text-danger">
                                    {formik.errors.item_condition}
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                            <tr>
                              <th>Dimensions/Measurements:</th>
                              <td>
                                <div className="row">
                                  <div className="col-md-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="length"
                                      name="length"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.length}
                                      placeholder="Length"
                                    />
                                    {formik.touched.length &&
                                    formik.errors.length ? (
                                      <div className="text-danger">
                                        {formik.errors.length}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="height"
                                      name="height"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.height}
                                      placeholder="Height"
                                    />
                                    {formik.touched.height &&
                                    formik.errors.height ? (
                                      <div className="text-danger">
                                        {formik.errors.height}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="width"
                                      name="width"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.width}
                                      placeholder="Width"
                                    />
                                    {formik.touched.width &&
                                    formik.errors.width ? (
                                      <div className="text-danger">
                                        {formik.errors.width}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Weight:</th>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="weight"
                                  name="weight"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.weight}
                                />
                                {formik.touched.weight &&
                                formik.errors.weight ? (
                                  <div className="text-danger">
                                    {formik.errors.weight}
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                            {/* <tr>
                                    <th>Images:</th>
                                    <td>
                                    <input
                                type="file"
                                className="form-control"
                                id="image"
                                name="image"
                                onChange={(event) => {
                                  const file = event.currentTarget.files?.[0];
                                  if (file) {
                                    formik.setFieldValue("image", file);
                                    setPreview(URL.createObjectURL(file)); // Optional: show preview
                                  }
                                }}
                                onBlur={formik.handleBlur}
                              />
                              {preview && (
                                <img
                                  src={preview}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "100px",
                                    marginTop: "10px",
                                  }}
                                />
                              )}
                              {formik.touched.image && formik.errors.image ? (
                                <div className="text-danger">
                                  {formik.errors.image}
                                </div>
                              ) : null}
                                    </td>
                                </tr> */}
                            <tr>
                              <th>Linked Agent:</th>
                              <td>{agentproduct?.created_by.name}</td>
                            </tr>
                            <tr>
                              <th>Warehouse: </th>
                              <td>
                              <select
      name="warehouseId"
      id="warehouseId"
      className="form-control"
      onChange={handleWarehouseChange} // Use the handler here
      onBlur={formik.handleBlur}
      value={formik.values.warehouseId}
    >
      <option value="">- Select -</option>
      {warehouses != null &&
        warehouses.map((warehouse, index) => (
          <option key={index} value={warehouse.id}>
            {warehouse.label}
          </option>
        ))}
    </select>
                              </td>
                            </tr>
                            <tr>
                              <th>Bin Location: </th>
                              <td>
                                <input
                                  type="text"
                                  name="bin_location"
                                  id="bin_location"
                                  className="form-control"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.bin_location}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>Installer Details:</th>
                              <td>{userName} </td>
                            </tr>
                            <tr>
                              <th>Notes for the Installer: </th>
                              <td>
                                <textarea
                                  name="notes"
                                  id="notes"
                                  className="form-control"
                                ></textarea>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td align="center">
                                <button
                                  type="submit"
                                  className="btn btn-success"
                                >
                                  Save Product{" "}
                                </button>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageEditRotationalProduct;
