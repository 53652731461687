import React from "react";

const AgentDashboard = ()=>{
    return(
        <div className="dashboard">
            <h2>Dashboard</h2>
        </div>
    );
}

export default AgentDashboard;