import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../api/authApi'; // Helper function to clear session

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Clear session
    navigate('/login', { replace: true }); // Redirect to login page
    window.location.reload();
  };

  return <Link to="" onClick={handleLogout} className="nav-link menu-link"><i className="ri-logout-box-line"></i>{" "}
                <span data-key="t-dashboards">Logout</span></Link>;
};

export default LogoutButton;
