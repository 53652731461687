import React, { useState, useRef } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

const SignageAddWarehouse = ()=>{
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");

const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      warehouseName: '',      
      streetAddress1: '',      
      city: '',
      state: '',      
      zipcode: '',
      statuss: '',
    },
    validationSchema: Yup.object({
      warehouseName: Yup.string()
        .min(2, 'Warehouse name must be at least 2 characters long')
        .max(50, 'Warehouse name cannot be longer than 50 characters')
        .required('Warehouse Name is required'),
     streetAddress1: Yup.string()
        .min(2, 'Street address must be at least 2 characters long')
        .max(50, 'Street address must be max 50 characters long') 
        .required('Street address is required'), 
     city: Yup.string()
        .min(2, 'City must be at least 2 characters long')
        .max(50, 'City cannot be longer than 50 characters')
        .required('City is required'),
     state: Yup.string()
        .min(2, 'State must be at least 2 characters long')
        .max(50, 'State cannot be longer than 50 characters')
        .required('State is required'),     
     zipcode: Yup.string()        
        .required('Zip code is required'),   
     country: Yup.string()
        .min(2, 'State must be at least 2 characters long')
        .max(50, 'State cannot be longer than 50 characters'),  
     statuss: Yup.string()        
        .required('Status is required'), 
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      const warehouseStatus = (values.statuss=='Active')?true:false;

      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/warehouses",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              label: values.warehouseName,
              address: {
                  street: values.streetAddress1,
                  city: values.city,
                  state: values.state,
                  zip: values.zipcode              
              },
              status: warehouseStatus
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("Warehouse added successfully!");
        // console.log("Form submission successful:", result);
        navigate("/signage/warehouses", {
          state: { successMessage: "Warehouse added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Warehouse.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });  

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
  
      const addressComponents = place.address_components;
      console.log(addressComponents);
  
      if (addressComponents) {
        // Helper function to get specific address component
        const getComponent = (type: string) => {
          const component = addressComponents.find(c => c.types.includes(type));
          return component ? component.long_name : '';
        };
  
        const streetNumber = getComponent('street_number');
        const route = getComponent('route');
        const city = getComponent('locality') || getComponent('administrative_area_level_2');
        const state = getComponent('administrative_area_level_1');
        const postalCode = getComponent('postal_code');
        const country = getComponent('country');
  
        const street = `${streetNumber} ${route}`.trim();
  
        // Update form values
        formik.setFieldValue('streetAddress1', street);
        formik.setFieldValue('city', city);
        formik.setFieldValue('state', state);
        formik.setFieldValue('zipcode', postalCode);
        formik.setFieldValue('country', country);
      }
    }
  };
  

  if (!isLoaded) return <div>Loading...</div>;


    return(
        <div className="teammember">
            <div className="card">
                <div className="card-body">
                    <h2>Add Warehouse </h2>
                    
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">                    
                    <div className="p-2 mt-4">
                      <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Warehouse Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="warehouseName"
                              name="warehouseName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.warehouseName}
                            />
                            {formik.touched.warehouseName && formik.errors.warehouseName ? (
                              <div className="text-danger">{formik.errors.warehouseName}</div>
                            ) : null}
                          </div> 
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Street Address <span className="text-danger">*</span>
                            </label>
                            <Autocomplete
                              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                              onPlaceChanged={handlePlaceChanged}
                            >
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress1"
                              name="streetAddress1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress1}
                            />
                            </Autocomplete>
                            {formik.touched.streetAddress1 && formik.errors.streetAddress1 ? (
                              <div className="text-danger">{formik.errors.streetAddress1}</div>
                            ) : null}
                          </div>
                          {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Street Address 2{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress2"
                              name="streetAddress2"
                              placeholder="Enter Address line 2"
                            />
                            <div className="invalid-feedback">
                              Please enter Address line 2
                            </div>
                          </div> */}
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              City <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <div className="text-danger">{formik.errors.city}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              State <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <div className="text-danger">{formik.errors.state}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Zip code <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipcode"
                              name="zipcode"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.zipcode}
                            />
                            {formik.touched.zipcode && formik.errors.zipcode ? (
                              <div className="text-danger">{formik.errors.zipcode}</div>
                            ) : null}
                          </div>
                          
                          
                          {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Country 
                            </label>                            
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              name="country"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.country}                              
                            />                           
                            {formik.touched.country && formik.errors.country ? (
                              <div className="text-danger">{formik.errors.country}</div>
                            ) : null}
                          </div> */}
                          {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Assign Team Member{" "}  <span className="text-danger">*</span>
                            </label>
                            <select className="form-control" id="assignedTeammember" name="assignedTeammember">
                              <option value="">- Select -</option>
                              <option value="Team Member 1">Team Member 1</option>
                              <option value="Team Member 2">Team Member 2</option>
                              <option value="Team Member 3">Team Member 3</option>
                            </select>
                            <div className="invalid-feedback">
                              Please select Team Member
                            </div>
                          </div> */}
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Status{" "} <span className="text-danger">*</span> 
                            </label>
                            <select className="form-control" id="statuss" name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}                            
                            >
                              <option value="">- Select -</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                            {formik.touched.statuss && formik.errors.statuss ? (
                              <div className="text-danger">{formik.errors.statuss}</div>
                            ) : null}
                          </div>                         
                          
                        </div>
                        
                        
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>                        
                      </form>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
                </div>
                
            </div>
        </div>
    );
}

export default SignageAddWarehouse;