import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  default_status: boolean;
  default_volume: number;
}

const MasterAdminModules = () => {

  const [modules, setModules] = useState<Module[]>([]);
  const token = localStorage.getItem("sessionToken");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/modules",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setModules(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchModules();
  }, [token]);

  const profiles = [
    "Super Admin",
    "Signage Firm",
    "Realtor Firm",
    "Agent",
    "Installer",
  ];  

  // Function to handle module deletion
  const handleDelete = async (moduleId: string) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this module?"
    );
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/modules/${moduleId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete module");
      }

      // Remove deleted module from the state
      setModules((prevModules) =>
        prevModules.filter((module) => module.id !== moduleId)
      );

      toast.success("Module deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete the module.");
    }
  };

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Modules </h2>
          <div className="text-end">
            <a
              href="/master/add-module"
              className="btn btn-primary float-right"
            >
              Add Module
            </a>
          </div>
          <div className="row">
            <div className="table-container">
            <table
              id="transactions"
              className="table table-bordered dt-responsive nowrap table-striped align-middle"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Module Name </th>                  
                  <th>License Type </th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {modules.map((module, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{module.name}</td>                    
                    <td>{module.license_type_code}</td>
                    <td>{module.description}</td>
                    <td>
                            {/* <Link
                              to={`/master/view-module/${module.id}`}
                              className="btn btn-secondary waves-effect waves-light"
                            >
                              <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                            </Link> */}

                            <Link to={`/master/edit-module/${module.id}`} className="btn btn-warning waves-effect waves-light">
                              <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                            </Link>

                            <button
                          onClick={() => handleDelete(module.id)}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2"></i>{" "}
                        </button>
                          </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterAdminModules;
