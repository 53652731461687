import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Warehouse = {
  id: string;
  label: string;
};

type Teammember = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  warehouses: Warehouse[];
  permissions: string;
  password: string;
  image?: string;
  status: string;
  licenseType: string;
};

const SignageTeammembers = () => {
  const [teammembers, setTeammembers] = useState<Teammember[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");

  const location = useLocation();
  useEffect(() => {
    if (location.state?.successMessage) {
      toast.success(location.state.successMessage);
    }
  }, [location.state]);
  
  useEffect(() => {
    const fetchTeammembers = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/team-members",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setTeammembers(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTeammembers();
  }, [token]);

  const handleDelete = async (teammemberId: string) => {
    // const confirmation = window.confirm(
    //   "Are you sure you want to delete this license?"
    // );
    // if (!confirmation) return;

    setDeleting(teammemberId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/users/${teammemberId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the teammember.");
      }

      toast.success("Teammember deleted successfully!");
      // Update the state by removing the deleted license
      setTeammembers((prev) =>
        prev.filter((teammember) => teammember.id !== teammemberId)
      );
      setDeleting(null); // Clear the deleting state
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  if (teammembers !== null && teammembers.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <div className="teammember">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Team Members </h2>
          <div className="text-end">
            <a
              href="/signage/add-teammember"
              className="btn btn-primary float-right"
            >
              Add Team Member
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="example"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Team Member ID</th>
                          <th>Team Member Name</th>
                          <th>Role/Position</th>
                          <th>Associated Warehouses</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {teammembers!==null && teammembers
                          .map((teammember) => (
                            <tr key={teammember.id}>
                              <td>
                              <Link
                                  to={`/signage/view-teammember/${teammember.id}`}
                                >{teammember.id}
                                </Link></td>
                              <td>
                              <Link
                                  to={`/signage/view-teammember/${teammember.id}`}
                                >
                                  {teammember.firstName} {teammember.lastName}
                                </Link>
                              </td>
                              <td>{teammember.jobTitle}</td>
                              <td>{teammember.warehouses!==null && teammember.warehouses.map((warehouse)=>(
                                <Link
                                to={`/signage/view-warehouse/${warehouse.id}`}
                                className="btn waves-effect waves-light"
                              >
                                {warehouse.label}
                              </Link>
                              ))}</td>
                              <td>{teammember.status}</td>
                              <td>
                                <Link
                                  to={`/signage/view-teammember/${teammember.id}`}
                                  className="btn btn-secondary waves-effect waves-light"
                                >
                                  <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <Link
                                  to={`/signage/edit-teammember/${teammember.id}`}
                                  className="btn btn-warning waves-effect waves-light"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <Link
                                  to="#"
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this teammember?"
                                    ) && handleDelete(teammember.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageTeammembers;
