import React, { useState, useRef, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

type CountyZipList = {
  county: string;
  zipList: string[];
};

type GeoLocation = {
  id: string;
  country: string;
  countryCode: string;
  state: string;
  stateCode: string;
  countyZipList: CountyZipList[];
};

const SignageAddTaxRule = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [geolocations, setGeoLocations] = useState<GeoLocation[]>([]);
  const [filteredCounties, setFilteredCounties] = useState<CountyZipList[]>([]);

  const token = localStorage.getItem("sessionToken");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGeoLocations = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/geo-locations",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setGeoLocations(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGeoLocations();
  }, [token]);

  const formik = useFormik({
    initialValues: {
      country: "",
      state: "",
      county: "",
      zipCodes: "",
      taxRate: "",
      comments: "",
      statuss: "",
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      county: Yup.string().required("County is required"),
      zipCodes: Yup.string()        
        .required("Zip Codes are required"),
      taxRate: Yup.number()
        .min(0, "Tax Rate must be positive")
        .required("Tax Rate is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      const ruleStatus = values.statuss === "Active";
      const zipCodes = values.zipCodes.split(",");

      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/tax-rules",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              ...values,
              zipCodes,
              taxRate: Number(values.taxRate),
              status: ruleStatus,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        navigate("/signage/taxRules", {
          state: { successMessage: "Tax rule added successfully!" },
        });
        toast.success("Tax rule added successfully!");
      } catch (err) {
        toast.error(err instanceof Error ? err.message : "Error saving data");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    formik.setFieldValue("country", selectedCountry);
    formik.setFieldValue("state", "");
    formik.setFieldValue("county", ""); // Reset county when country changes

    // Find states for the selected country
    const states = geolocations
      .filter((geo) => geo.country === selectedCountry)
      .map((geo) => geo.state);

    if (states.length > 0) {
      // If there is only one state, auto-populate the state field
      formik.setFieldValue("state", states[0]);
    } else {
      // Clear state if no unique state exists
      formik.setFieldValue("state", "");
    }    

    const counties = geolocations
    .filter((geo) => geo.country === selectedCountry)
    .flatMap((geo) => geo.countyZipList);

    setFilteredCounties(counties);
  };

  const handleCountyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCounty = e.target.value;
    formik.setFieldValue("county", selectedCounty);
    formik.setFieldValue("zipCodes", "");
    const zips = filteredCounties
      .filter((cnt) => cnt.county === selectedCounty)
      .flatMap((cnt) => cnt.zipList);
      console.log('Zips:', zips);
      if (zips.length > 0) {
        // If there is only one state, auto-populate the state field
        formik.setFieldValue("zipCodes", zips.join(","));
      } else {
        // Clear state if no unique state exists
        formik.setFieldValue("zipCodes", "");
      } 
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-danger">{error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add Tax Rule </h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Country <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  onChange={handleCountryChange}
                  value={formik.values.country}
                >
                  <option value="">-Select-</option>
                  {geolocations.map((location) => (
                    <option key={location.country} value={location.country}>
                      {location.country}
                    </option>
                  ))}
                </select>
                {formik.touched.country && formik.errors.country ? (
                  <div className="text-danger">{formik.errors.country}</div>
                ) : null}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  State <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  readOnly
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className="text-danger">{formik.errors.state}</div>
                ) : null}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  County <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="county"
                  name="county"
                  onChange={handleCountyChange}
                  value={formik.values.county}
                >
                  <option value="">-Select-</option>
                  {filteredCounties.map((countyObj) => (
                    <option key={countyObj.county} value={countyObj.county}>
                      {countyObj.county}
                    </option>
                  ))}
                </select>
                {formik.touched.county && formik.errors.county ? (
                  <div className="text-danger">{formik.errors.county}</div>
                ) : null}
              </div>
              
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Zip Codes <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="zipCodes"
                  name="zipCodes"
                  onChange={formik.handleChange}
                  value={formik.values.zipCodes}
                />
                {formik.touched.zipCodes && formik.errors.zipCodes ? (
                  <div className="text-danger">{formik.errors.zipCodes}</div>
                ) : null}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Tax Rate <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="taxRate"
                  name="taxRate"
                  onChange={formik.handleChange}
                  value={formik.values.taxRate}
                />
                {formik.touched.taxRate && formik.errors.taxRate ? (
                  <div className="text-danger">{formik.errors.taxRate}</div>
                ) : null}
              </div>
              <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Status{" "} <span className="text-danger">*</span> 
                            </label>
                            <select className="form-control" id="statuss" name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}                            
                            >
                              <option value="">- Select -</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                            {formik.touched.statuss && formik.errors.statuss ? (
                              <div className="text-danger">{formik.errors.statuss}</div>
                            ) : null}
                          </div> 
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                            Comments <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="comments"
                              name="comments"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.comments}
                            >
                            </textarea>
                            {formik.touched.comments && formik.errors.comments ? (
                              <div className="text-danger">{formik.errors.comments}</div>
                            ) : null}
                          </div>
            </div>
            <button
              className="btn btn-success w-100 mt-4"
              type="submit"
              disabled={formSubmitting}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignageAddTaxRule;
