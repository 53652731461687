import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom'; // Import Outlet for nested routes

import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.min.css';
import '../assets/css/app.min.css';
import '../assets/css/custom.min.css';
import './SignageLayout.css';

import RealtorHeader from '../components/RealtorHeader';
import RealtorSidebar from '../components/RealtorSidebar';
import DashboardFooter from '../components/DashboardFooter';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type LayoutProps = {
  children?: ReactNode; // Children is optional since we are using Outlet
};

const RealtorLayout: React.FC<LayoutProps> = () => {
  return (
    <div id="layout-wrapper">
      <ToastContainer />
      {/* Header component */}
      <RealtorHeader />
      
      {/* Sidebar component */}
      <RealtorSidebar />

      {/* Main Content Area */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* Outlet will render the matched child routes */}
            <Outlet />
          </div>
        </div>

        {/* Footer component */}
        <DashboardFooter />
      </div>
    </div>
  );
};

export default RealtorLayout;
