import React from "react";

const SuperAdminDashboard = ()=>{
    return(
        <>
        <h2>SuperAdmin Dashboard</h2>
        </>
    );
}

export default SuperAdminDashboard;