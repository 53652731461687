import { useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

const SignageBrands = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/brands",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  // if (brands?.length === 0) {
  //   return <div>Loading...</div>;
  // }

  useEffect(() => {
    if(brands!=null && brands.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [brands]);

  const handleDelete = async (brandId: string) => {

    setDeleting(brandId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/brands/${brandId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the brand.");
      }

      toast.success("Brand deleted successfully!");
      // Update the state by removing the deleted license
      setBrands((prev) =>
        prev.filter((group) => group.id !== brandId)
      );
      setDeleting(null); // Clear the deleting state
    } catch (err) {
      toast.error("Failed to delete the brand.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Brands </h2>
          <div className="text-end">
            <a
              href="/signage/addBrand"
              className="btn btn-primary float-right"
            >
              Add Brand
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {brands != null &&
                          brands.map((brand, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>
                              <td><img src={`https://dev.reelty.app/${brand.image}`} height={50} alt="Brand Image" />
                              </td>
                              <td>{brand.name}</td>
                              <td>
                                {brand.status ? "Active" : "Inactive"}
                              </td>
                              <td>
                                <Link
                                  to={`/signage/editBrand/${brand.id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this brand?"
                                    ) && handleDelete(brand.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageBrands;
