import React, { useState, useRef, useEffect } from "react";
import NavbarBrandBox from "./NavbarBrandBox";
import MyCartDropdown from "./MyCartDropdown";
import NotificationDropdown from "./NotificationDropdown";
import { Link } from "react-router-dom";
import ProfileDropdownn from "./ProfileDropdownn";

const SignageHeader = () => {
  const [isActive, setIsActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const divRef = useRef<HTMLDivElement | null>(null);

  const toogleMenuBtn = () => {
    setIsActive(!isActive);

    var windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;

    if (windowSize > 767) humberIcon.classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setIsActive(false); // Hide the div
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box horizontal-logo">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="../assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img
                    src="../assets/images/logo-dark.png"
                    alt=""
                    height="17"
                  />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src="../assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img
                    src="../assets/images/logo-light.png"
                    alt=""
                    height="17"
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger material-shadow-none"
              id="topnav-hamburger-icon"
            >
              <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>

            <form className="app-search d-none d-md-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  autoComplete="off"
                  id="search-options"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <span className="mdi mdi-magnify search-widget-icon"></span>
                <span
                  className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                  id="search-close-options"
                ></span>
              </div>
            </form>
          </div>

          <div className="d-flex align-items-center">
            <div className="dropdown d-md-none topbar-head-dropdown header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-search fs-22"></i>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="dropdown topbar-head-dropdown ms-1 header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle"
                id="page-header-cart-dropdown"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-shopping-bag fs-22"></i>
                <span className="position-absolute topbar-badge cartitem-badge fs-10 translate-middle badge rounded-pill bg-info">
                  5
                </span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
                aria-labelledby="page-header-cart-dropdown"
              >
                <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0 fs-16 fw-semibold"> My Cart</h6>
                    </div>
                    <div className="col-auto">
                      <span className="badge bg-warning-subtle text-warning fs-13">
                        <span className="cartitem-badge">7</span>
                        items
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="dropdown topbar-head-dropdown ms-1 header-item"
              id="notificationDropdown"
            >
              <button
                type="button"
                className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-bell fs-22"></i>
                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                  3<span className="visually-hidden">unread messages</span>
                </span>
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div className="dropdown-head bg-primary bg-pattern rounded-top">
                  <div className="p-3">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="m-0 fs-16 fw-semibold text-white">
                          {" "}
                          Notifications{" "}
                        </h6>
                      </div>
                      <div className="col-auto dropdown-tabs">
                        <span className="badge bg-light text-body fs-13">
                          {" "}
                          4 New
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="px-2 pt-2">
                    <ul
                      className="nav nav-tabs dropdown-tabs nav-tabs-custom"
                      data-dropdown-tabs="true"
                      id="notificationItemsTab"
                      role="tablist"
                    >
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#all-noti-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          All (4)
                        </a>
                      </li>
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#messages-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Messages
                        </a>
                      </li>
                      <li className="nav-item waves-effect waves-light">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#alerts-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Alerts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn material-shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src="../assets/images/users/avatar-1.jpg"
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                      Anna Adame
                    </span>
                    <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">
                      Founder
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome Anna!</h6>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Profile</span>
                </a>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Messages</span>
                </a>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Taskboard</span>
                </a>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Help</span>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">
                    Balance : <b>$5971.67</b>
                  </span>
                </a>
                <a className="dropdown-item" href="#">
                  <span className="badge bg-success-subtle text-success mt-1 float-end">
                    New
                  </span>
                  <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Settings</span>
                </a>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Lock screen</span>
                </a>
                <a className="dropdown-item" href="#">
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SignageHeader;
