// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.choices__inner{
    height: 50px;
}
.card.ribbon-box {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.card.ribbon-box:hover {
    transform: translateY(-5px) scale(1.02); /* Slightly lifts and scales the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */
    cursor: pointer;
}
  `, "",{"version":3,"sources":["webpack://./src/pages/realtor/RealtorSuppliers.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,qDAAqD;IACrD,wCAAwC;AAC5C;;AAEA;IACI,uCAAuC,EAAE,uCAAuC;IAChF,wCAAwC,EAAE,0BAA0B;IACpE,eAAe;AACnB","sourcesContent":[".choices__inner{\n    height: 50px;\n}\n.card.ribbon-box {\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);\n}\n\n.card.ribbon-box:hover {\n    transform: translateY(-5px) scale(1.02); /* Slightly lifts and scales the card */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow for depth */\n    cursor: pointer;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
