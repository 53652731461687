import React from "react";
import { Link } from "react-router-dom";

const NavbarBrandBox = () => {
  return (
    <>
    <div className="navbar-brand-box horizontal-logo">
      <Link to="/" className="logo logo-dark">
        <span className="logo-sm">
          <h2>REALESY</h2>
        </span>
        <span className="logo-lg">
        <h2>REALESY</h2>
        </span>
      </Link>

      <Link to="/" className="logo logo-light">
        <span className="logo-sm">
        <h2>REALESY</h2>
        </span>
        <span className="logo-lg">
        <h2>REALESY</h2>
        </span>
      </Link>
    </div>
    </>
  );
};

export default NavbarBrandBox;
