import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";

interface ModulePermission {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  status: boolean;
  volume: number;
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
}

interface License {
  id: string;
  companyName: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  statuss: string;
  licenseValidity: string | null;
  billingCycle: string | null;
  billingCyclePrice: number | null;
  paymentMethod: string;
  licenseType: string;
  modules: Module[]; // Changed to Module[]
}

const sidebarItems = [
  { name: "Dashboard", path: "/realtor/dashboard", icon: "ri-dashboard-2-line", module_code: "DASHBOARD" },
  { name: "Offices", path: "/realtor/offices", icon: "ri-building-2-line", module_code: "OFFICES" },
  { name: "Team Members", path: "/realtor/teammembers", icon: "ri-group-line", module_code: "TEAM_MEMBERS" },
  { name: "Agents", path: "/realtor/agents", icon: "ri-user-line", module_code: "AGENTS" },  
  { name: "Groups", path: "/realtor/groups", icon: "ri-group-line", module_code: "GROUPS" },
  { name: "Suppliers", path: "/realtor/suppliers", icon: "ri-store-2-line", module_code: "SUPPLIERS" },
  { name: "Profile", path: "/realtor/profile", icon: "ri-user-line", module_code: "PROFILE" },
];

const RealtorSidebar = () => {
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [license, setLicense] = useState<License | null>(null);
  const [modulePermissions, setModulePermissions] = useState<Module[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLicense = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch license data");
        }

        const data = await response.json();
        console.log("Fetched License Data:", data);

        if (data && data.firmDetails && data.firmDetails.address) {
          const fetchedLicense: License = {
            id: data.id || "",
            companyName: data.firmDetails.companyName || "",
            jobTitle: data.jobTitle || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            email: data.email || "",
            phone: data.phone1 || "",
            addressLine1: data.firmDetails.address.addressLine1 || "",
            addressLine2: data.firmDetails.address.addressLine2 || "",
            city: data.firmDetails.address.city || "",
            state: data.firmDetails.address.state || "",
            zip: data.firmDetails.address.zip || "",
            country: data.firmDetails.address.country || "",
            statuss: data.status || "Active",
            licenseValidity: data.licenseValidity || null,
            billingCycle: data.billingCycle || null,
            billingCyclePrice: data.billingCyclePrice || null,
            paymentMethod: data.paymentMethod || "",
            licenseType: data.licenseType || "",
            modules: Array.isArray(data.modulePermissions)
              ? data.modulePermissions
              : [],
          };

          setLicense(fetchedLicense);
          setModulePermissions(
            Array.isArray(data.modulePermissions) ? data.modulePermissions : []
          );          
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching license data:", error);
        setError("Failed to fetch license data.");
      } finally {
        setLoading(false);
      }
    };

    if (!license) {
      fetchLicense();
    }
  }, [userId, license, token]);

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        {/* Logo */}
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <h2>REALESY</h2>
          </span>
          <span className="logo-lg">
            <h2>REALESY</h2>
          </span>
        </Link>
        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <h2>REALESY</h2>
          </span>
          <span className="logo-lg">
            <h2>REALESY</h2>
          </span>
        </Link>

        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      {/* User Dropdown */}
      <div className="dropdown sidebar-user m-1 rounded">
        <button
          type="button"
          className="btn material-shadow-none"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="d-flex align-items-center gap-2">
            <img
              className="rounded header-profile-user"
              src="assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />
            <span className="text-start">
              <span className="d-block fw-medium sidebar-user-name-text">
                Anna Adame
              </span>
              <span className="d-block fs-14 sidebar-user-name-sub-text">
                <i className="ri-circle-fill fs-10 text-success align-baseline"></i>{" "}
                <span className="align-middle">Online</span>
              </span>
            </span>
          </span>
        </button>

        <div className="dropdown-menu dropdown-menu-end">
          <h6 className="dropdown-header">Welcome Anna!</h6>
          <Link to="/profile" className="dropdown-item">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </Link>
          <Link to="/messages" className="dropdown-item">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Messages</span>
          </Link>
          <Link to="/tasks" className="dropdown-item">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Taskboard</span>
          </Link>
          <Link to="/faqs" className="dropdown-item">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Help</span>
          </Link>
          <div className="dropdown-divider"></div>
          <Link to="/profile" className="dropdown-item">
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">
              Balance: <b>$5971.67</b>
            </span>
          </Link>
          <Link to="/settings" className="dropdown-item">
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Settings</span>
          </Link>
          <Link to="/lockscreen" className="dropdown-item">
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Lock screen</span>
          </Link>
          <LogoutButton />
        </div>
      </div>

      {/* Sidebar Navigation */}
      <div id="scrollbar">
        <div className="container-fluid">
          <ul className="navbar-nav" id="navbar-nav">
          <li className="nav-item">
              <Link to="/realtor/dashboard" className="nav-link menu-link">
                <i className="ri-dashboard-2-line"></i>{" "}
                <span>Dashboard</span>
              </Link>
            </li>
            {sidebarItems.map((item) => {
              const permission = modulePermissions.find(
                (perm) => perm.module_code === item.module_code
              );

              // Only render item if permission is found and status is true
              return permission && permission.status ? (
                <li className="nav-item" key={item.module_code}>
                  <Link to={item.path} className="nav-link menu-link">
                    <i className={item.icon}></i>{" "}
                    <span>{item.name}</span>
                  </Link>
                </li>
              ) : null;
            })}
            <li className="nav-item">
              <Link to="/realtor/profile" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Profile</span>
              </Link>
            </li>
            <li className="nav-item">
              <LogoutButton />
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-background"></div>
    </div>
  );
};

export default RealtorSidebar;
