import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./AgentSuppliers.css";
import { width } from "assets/libs/gridjs/src/util/width";

interface FirmInfo {
  companyID: string;
  companyName: string;
  companyWebsite: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Supplier {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  jobTitle: string;
  email: string;
  phone1: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
}

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  product_id: string;
  name: string;
  product_type: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  // notes: string;
  image: Image | null;
  status: string;
};

type Image = {
  url: string;
};

const AgentSupplierPage = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const token = localStorage.getItem("sessionToken");
  const [error, setError] = useState<string | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  // Fetch supplier data
  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${supplierId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch teammember data");
        }
        const data = await response.json();
        if (data && data.firmDetails && data.firmDetails.address) {
          setSupplier(data);
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching teammember data:", error);
      }
    };

    if (supplierId) {
      fetchSupplierData();
    }
  }, [supplierId, token]);

  // Fetch products data
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/signage/${supplierId}/inventory/products`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        console.log(data);
        setProducts(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [supplierId, token]);

  // Loading state must come after all hooks
  if (!supplier) return <div>Loading Teammember Data...</div>;

  return (
    <div className="supplier-page">
      {/* Supplier Details Section */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
            <h4 className="mb-sm-0">Supplier Details</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                </li>
                <li className="breadcrumb-item active">Supplier Details</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Supplier Information */}
      <div className="row">
        <div className="col-xxl-3">
          <div className="card">
            <div className="card-body p-4">
              <div>
                <div className="flex-shrink-0 avatar-md mx-auto">
                  <div className="avatar-title bg-light rounded">
                    <img
                      src="/assets/images/companies/img-2.png"
                      alt="Supplier Logo"
                      height="50"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h5 className="mb-1">{supplier.firmDetails.companyName}</h5>
                  <p className="text-muted">
                    {supplier.firmDetails.address.city}
                  </p>
                </div>
                <div className="p-1">
                  <table className="table mb-0 table-borderless">
                    <tbody>
                      <tr>
                        <th>
                          <span className="fw-medium">Name</span>
                        </th>
                        <td>
                          {supplier.firstName} {supplier.lastName}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Job title / Role</span>
                        </th>
                        <td>{supplier.jobTitle}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Email</span>
                        </th>
                        <td>{supplier.email}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Website</span>
                        </th>
                        <td>
                          <Link to="#" className="link-primary">
                            {supplier.firmDetails.companyWebsite}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Contact No.</span>
                        </th>
                        <td>{supplier.phone1}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Location</span>
                        </th>
                        <td>
                          {supplier.firmDetails.address.addressLine1}{" "}
                          {supplier.firmDetails.address.addressLine2}{" "}
                          {supplier.firmDetails.address.city}{" "}
                          {supplier.firmDetails.address.state}{" "}
                          {supplier.firmDetails.address.country}{" "}
                          {supplier.firmDetails.address.zip}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Products List */}
        <div className="col-xxl-9">
          <div className="row g-4 mb-3">
            <div className="col-sm-auto">
              <div>
                <h2>Products</h2>
              </div>
            </div>
            <div className="col-sm">
              <div className="d-flex justify-content-sm-end">
                <div className="search-box ms-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Products..."
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div
                id="table-product-list-all"
                className="table-card gridjs-border-none"
              >
               <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>                          
                        <th style={{ width: '50px' }}>S.No.</th>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Category</th>
                          <th>Qty</th>
                          <th>Status</th>
                          <th>Condition</th>
                          <th>Dimensions</th>
                          <th>Weight</th>                          
                        </tr>
                      </thead>
                      <tbody>
                        {products != null &&
                          products.map((product, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>
                              <td>
                                {product.image?.url ? (
                                  <img
                                    src={`https://dev.reelty.app/${product.image.url}`}
                                    height={40}
                                    alt="Product"
                                  />
                                ) : (
                                  <span>No image</span>
                                )}
                              </td>

                              <td>{product.name}</td>
                              <td>{product.category}</td>
                              <td>1</td>
                              <td>{product.status}</td>                              
                              <td>{product.item_condition}</td>
                              <td>
                                {product.package_dimensions.length} x{" "}
                                {product.package_dimensions.height} x{" "}
                                {product.package_dimensions.width}
                              </td>
                              <td>
                                {product.package_dimensions.weight}
                              </td>                              
                            </tr>
                          ))}
                      </tbody>
                    </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentSupplierPage;

