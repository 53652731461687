interface UserInfo {
  email: string;
  password: string;
}

interface LoginResponse {
  id: string;
  token: string;
  userType: string;
  isProfileUpdated:boolean;
  error: string;
}

interface RegisterResponse {
  success: boolean;
  message: string;
}

export const loginUser = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await fetch('https://dev.reelty.app/api/v1/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify({ email, password }),
    });

    // Check if the response status is not OK
    if (!response.ok) {
      // Attempt to read the error message from the response body
      const errorData = await response.json();
      console.log('errorData:', errorData);
      // throw new Error(errorData.error || 'Login failed');
      return errorData;
    }

    // Parse successful response
    const data: LoginResponse = await response.json();
    console.log(data);
    return data;

  } catch (error) {
    // Log the error for debugging
    console.error('Error logging in:', error);
    throw error; // Re-throw to let the caller handle the error as well
  }
};

export const registerUser = async (userInfo: UserInfo): Promise<RegisterResponse> => {
  const response = await fetch('/api/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInfo),
  });

  if (!response.ok) throw new Error('Registration failed');
  const data: RegisterResponse = await response.json();
  return data;
};

export const logout = () => {
  localStorage.removeItem('sessionToken');
};
