import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type CountyZipList = {
  county: string;
  zipList: string[];
};

type GeoLocation = {
  id: string;
  country: string;
  countryCode: string;
  state: string;
  stateCode: string;
};

const SignageEditGeoLocation = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const [geoLocation, setGeoLocation] = useState<GeoLocation | null>(null);
  const [countyList, setCountyList] = useState<CountyZipList[] | null>(null);

  const navigate = useNavigate();

  // Fetch location data
  useEffect(() => {
    const fetchGeoLocation = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/geo-locations/${locationId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch location data");
        }
        const data = await response.json();
        setGeoLocation({
          id: data.id || "",
          country: data.country || "",
          countryCode: data.countryCode || "",
          state: data.state || "",
          stateCode: data.stateCode || "",
        });
        setCountyList(data.countyZipList);
      } catch (error) {
        setError("Error fetching location data. Please try again later.");
        console.error("Error fetching location data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (locationId) {
      fetchGeoLocation();
    }
  }, [locationId]);

  // Handle form submission
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: geoLocation?.country || "",
      countryCode: geoLocation?.countryCode || "US",
      state: geoLocation?.state || "",
      stateCode: geoLocation?.stateCode || "STATE",
    },
    validationSchema: Yup.object({
      country: Yup.string()
        .min(2, "Country name must be at least 2 characters long")
        .max(50, "Country name cannot be longer than 50 characters")
        .required("Country Name is required"),
      countryCode: Yup.string().required("Country code is required"),
      state: Yup.string()
        .min(2, "State must be at least 2 characters long")
        .max(50, "State cannot be longer than 50 characters")
        .required("State is required"),
      stateCode: Yup.string().required("State code is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/geo-locations/${locationId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              country: values.country,
              countryCode: values.countryCode,
              state: values.state,
              stateCode: values.stateCode,
              countyZipList: countyList
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        toast.success("GeoLocation updated successfully!");
        navigate("/signage/geolocations", {
          state: { successMessage: "GeoLocation updated successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update GeoLocation.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const handleCountyChange = (index: number, value: string) => {
    setCountyList((prev) => {
      if (!prev) return null; // Handle the case where prev is null
      return prev.map((item, i) =>
        i === index ? { ...item, county: value } : item
      );
    });
  };
  
  const handleZipChange = (index: number, value: string) => {
    const zipList = value.split(",").map((zip) => zip.trim());
    setCountyList((prev) => {
      if (!prev) return null; // Handle the case where prev is null
      return prev.map((item, i) =>
        i === index ? { ...item, zipList } : item
      );
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Geo Location</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <TextInput
                label="Country"
                id="country"
                name="country"
                formik={formik}
                required
              />
              <TextInput
                label="State"
                id="state"
                name="state"
                formik={formik}
                required
              />
              {countyList ? (
  countyList.map((countyObj, index) => (
    <div className="col-md-12" key={index}>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-label">
            County <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id={`county_${index}`}
            name={`county_${index}`}
            onChange={(e) => handleCountyChange(index, e.target.value)}
            value={countyObj.county || ""}
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-label">
            Zip code <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id={`zipcode_${index}`}
            name={`zipcode_${index}`}
            onChange={(e) => handleZipChange(index, e.target.value)}
            value={Array.isArray(countyObj.zipList) ? countyObj.zipList.join(", ") : ""}
          />
        </div>
      </div>
    </div>
  ))
) : (
  <div className="col-md-12">
    <button
      type="button"
      className="btn btn-primary mb-3"
      onClick={() =>
        setCountyList([{ county: "", zipList: [""] }]) // Initialize the list with one empty entry
      }
    >
      Add County and Zip Codes
    </button>
  </div>
)}

{countyList && (
  <div className="col-md-12">
    <button
      type="button"
      className="btn btn-secondary mt-3"
      onClick={() =>
        setCountyList((prev) => [
          ...(prev || []),
          { county: "", zipList: [""] }, // Add a new blank entry
        ])
      }
    >
      Add Another County and Zip Codes
    </button>
  </div>
)}


            </div>
            <div className="mt-4">
              <button
                className="btn btn-success w-100"
                type="submit"
                disabled={formSubmitting}
              >
                {formSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const TextInput = ({
  label,
  id,
  name,
  formik,
  required,
}: {
  label: string;
  id: string;
  name: string;
  formik: any;
  required?: boolean;
}) => (
  <div className="col-md-6 mb-3">
    <label className="form-label">
      {label} {required && <span className="text-danger">*</span>}
    </label>
    <input
      type="text"
      className="form-control"
      id={id}
      name={name}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values[name]}
    />
    {formik.touched[name] && formik.errors[name] && (
      <div className="text-danger">{formik.errors[name]}</div>
    )}
  </div>
);

export default SignageEditGeoLocation;
