import React from 'react';
import { Outlet, Link } from 'react-router-dom';

// import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.min.css';
import '../assets/css/app.min.css';
import '../assets/css/custom.min.css';
import './SuperAdminLayout.css';

import SuperAdminHeader from '../components/SuperAdminHeader';
import SuperAdminSidebar from '../components/SuperAdminSidebar';
import DashboardFooter from '../components/DashboardFooter';

interface LayoutProps {
  children?: React.ReactNode;  // Make children optional since we're using Outlet
}

const SuperAdminLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div id="layout-wrapper">
      {/* Header Component */}
      <SuperAdminHeader />
      
      {/* Sidebar Component */}
      <SuperAdminSidebar />

      {/* Main Content Area */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* Page Title Section */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                  {/* <h4 className="mb-sm-0">Super Admin Dashboard</h4> */}

                  {/* Breadcrumb */}
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <Link to="/superadmin/dashboard">Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item active">Overview</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>

            {/* Render children if passed, otherwise render Outlet */}
            {children ? children : <Outlet />}
          </div>
        </div>

        {/* Footer Component */}
        <DashboardFooter />
      </div>
    </div>
  );
};

export default SuperAdminLayout;
