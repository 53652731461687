import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface LicenseType {
    id: string;
    name: string;
    code: string;
}
interface ModulePermission {
    id: string;
    name: string;
    module_code: string;
    license_type_code: string;
    description: string;
    status: boolean;
    volume: number;
  }
  interface PlanPermission {
    id: string;
    planID: string;
    planName: string;
    licenseType: string;
    modules: ModulePermission[];
  }
  interface Module {
    id: string;
    name: string;
    module_code: string;
    license_type_code: string;
    status: boolean;
    volume: number;
  }
  interface Plan {
    id: string;
    planName: string;
    description: string;
  }

const SuperAdminEditPlanPermissions = () => {
  // const token =
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHJlZWx0eS5jb20iLCJleHAiOjE3Mjc5NzIzMzEsInVzZXJUeXBlIjoiIn0.cFNnl3zUN32x3X_Ysj7bttI-0DpTVgLdZmY8V1NI7Xk";
  const token = localStorage.getItem('sessionToken');
  const { planPermissionsId } = useParams<{ planPermissionsId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submissionError, setSubmissionError] = useState<string>("");
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [planPermissions, setPlanPermissions] = useState<PlanPermission | null>(null);
  const [modulePermissions, setModulePermissions] = useState<ModulePermission[]>([]);
  const [selectedLicenseType, setSelectedLicenseType] = useState<string>("");
  const [loadingModules, setLoadingModules] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [submissionSuccess, setSubmissionSuccess] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const navigate = useNavigate();

  // Fetch the plan data based on planId
  useEffect(() => {
    const fetchPlanPermissionsData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://dev.reelty.app/api/v1/plan-permissions/${planPermissionsId}`, {
          method: "GET", // Use GET for fetching
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch plan data");
        }

        const data = await response.json();
        console.log(data);
        setPlanPermissions(data);
        setSelectedPlan(data.planName);
        setSelectedLicenseType(data.licenseType);
        setModulePermissions(data.modules);
        // Update formik values with the fetched data
        // formik.setValues({
        //   planName: data.plans[0].planName || "",
        //   description: data.plans[0].description || "",
        // });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching plan data:", error);
        setError("Failed to load the plan data");
        setLoading(false);
      }
    };

    if (planPermissionsId) {
        fetchPlanPermissionsData();
    }
  }, [planPermissionsId]);

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/license-types",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, []);

  useEffect(() => {
    const fetchModules = async () => {
      if (!selectedLicenseType) return;

      setLoadingModules(true);
      setError("");
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/modules/license-type/${selectedLicenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch modules");

        const data = await response.json();
        setModules(data || []);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoadingModules(false);
      }
    };

    fetchModules();
  }, [selectedLicenseType, token]);

// Handle input change for each module
const handleModuleChange = (
    index: number,
    field: "status" | "volume",
    value: boolean | number
  ) => {
    setModulePermissions((prevModules) =>
      prevModules.map((module, i) =>
        i === index ? { ...module, [field]: value } : module
      )
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmissionError("");
    setSubmissionSuccess("");

    // Validate that both plan and license type are selected
    if (!selectedPlan || !selectedLicenseType) {
      setSubmissionError("Please select both a plan and a license type.");
      return;
    }

    if (!selectedPlan) {
      setSubmissionError("Selected plan not found.");
      return;
    }

    const requestData = {
    //   planID: selectedPlanData.id,
      planName: selectedPlan,
      licenseType: selectedLicenseType,
      modules: modulePermissions.map((module) => ({
        name: module.name,
        module_code: module.module_code,
        license_type_code: module.license_type_code,
        status: module.status,
        volume: module.volume,
      })),
    };
    console.log(modules);

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/plan-permissions/${planPermissionsId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (!response.ok) throw new Error("Failed to submit permissions");

      // setSubmissionSuccess("Permissions successfully submitted!");
      //   toast.success("Permissions successfully submitted!");
      navigate("/superadmin/profiles", {
        state: { successMessage: "Permissions updated successfully!" },
      });
    } catch (err) {
      setSubmissionError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    }
  };

//   const formik = useFormik({
//     initialValues: {
//       planID: planPermissions?.planID,
//       planName: planPermissions?.planName,
//       licenseType: planPermissions?.licenseType,
//       modules: planPermissions?.modules
//     },
//     validationSchema: Yup.object({
//       planName: Yup.string()
//         .min(2, "Plan name must be at least 2 characters long")
//         .max(50, "Plan name cannot be longer than 50 characters")
//         .required("Plan name is required"),
//     }),
//     onSubmit: async (values) => {
//       try {
//         setLoading(true);
//         const response = await fetch(`https://dev.reelty.app/api/v1/plan-permissions/${planPermissionsId}`, {
//           method: "PUT", // Use PUT or PATCH for updates
//           headers: {
//             "Authorization": `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             id: planPermissionsId,
//             planName: values.planName,
//             description: values.description,
//           }),
//         });

//         // console.log(response.body);

//         if (!response.ok) {
//           throw new Error("Failed to submit form");
//         }

//         const data = await response.json();
//         console.log("Form successfully submitted:", data);
//         navigate('/superadmin/profiles', { state: { successMessage: "Plan profile updated successfully!" } });
//         setLoading(false);
//       } catch (error) {
//         console.error("Error submitting form:", error);
//         setError("Failed to submit the form");
//         setLoading(false);
//       }
//     },
//   });

  

  return (
    <div className="roles">
      <div className="card">
        <div className="card-body">
          <h2>Edit Plan Permissions</h2>

          {loading && <p>Loading...</p>}
          {error && <p className="text-danger">{error}</p>}

          {!loading && !error && (
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">
                  <form onSubmit={handleSubmit} id="permissions">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="plan-select">Plan </label>
                  <input
                    name="planName"
                    className="form-control mb-3"
                    id="plan-select"
                    value={selectedPlan}
                  />
                    
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="license-select">Select License Type</label>
                  <select
                    name="profileName"
                    className="form-control mb-3"
                    id="license-select"
                    value={selectedLicenseType}
                    onChange={(e) => setSelectedLicenseType(e.target.value)}
                  >
                    <option value="">Select License Type</option>
                    {licenseTypes.map((license) => (
                      <option key={license.id} value={license.code}>
                        {license.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {loadingModules ? (
              <p>Loading modules...</p>
            ) : error ? (
              <p>Error: {error}</p>
            ) : modules.length > 0 ? (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Module</th>
                      <th>Yes/No</th>
                      <th>Volume</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modulePermissions && modulePermissions.map((permission, index) => (
                      <tr key={module.id}>
                        <td>{permission.name}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={permission.status}
                            onChange={(e) =>
                              handleModuleChange(
                                index,
                                "status",
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={permission.volume}
                            onChange={(e) =>
                              handleModuleChange(
                                index,
                                "volume",
                                +e.target.value
                              )
                            }
                            style={{ width: "100px" }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No modules available for this license type.</p>
            )}

            {/* {submissionError && <p className="text-danger">Error: {submissionError}</p>}
      {submissionSuccess && <p className="text-success">{submissionSuccess}</p>} */}

            <div className="row mt-3 justify-content-center">
              <div className="col-md-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminEditPlanPermissions;
