import React from "react";

const RealtorDashboard = ()=>{
    return(
        <>
        <h2>Realtor Dashboard</h2>
        </>
    );
}

export default RealtorDashboard;