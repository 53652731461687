// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-item {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #ffffff !important;
}
.topbar-user {
    background-color: #ffffff !important;
}
.badge {
    line-height: .95;
    width: 20px;
}
.topbar-badge {
    left: 30px;
    top: 4px !important;
}
.vertical-sidebar-enable {
    margin-left: 0!important;
    z-index: 1004;
}
@media (min-width: 768px) {
    .topbar-user {
        background-color: #ffffff !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MasterAdminHeader.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;AACA;IACI,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,UAAU;IACV,mBAAmB;AACvB;AACA;IACI,wBAAwB;IACxB,aAAa;AACjB;AACA;IACI;QACI,oCAAoC;IACxC;AACJ","sourcesContent":[".header-item {\n    height: 70px;\n    display: flex;\n    align-items: center;\n    background-color: #ffffff !important;\n}\n.topbar-user {\n    background-color: #ffffff !important;\n}\n.badge {\n    line-height: .95;\n    width: 20px;\n}\n.topbar-badge {\n    left: 30px;\n    top: 4px !important;\n}\n.vertical-sidebar-enable {\n    margin-left: 0!important;\n    z-index: 1004;\n}\n@media (min-width: 768px) {\n    .topbar-user {\n        background-color: #ffffff !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
