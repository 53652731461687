import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type FormValues = {
  name: string;
  description: string;
  sku: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  length: number;
  height: number;
  width: number;
  weight: number;
  threshold_qty: number;
  installerId: string;
  notes: string;
  image: File | null;
  imageUrl: string | null;
  statuss: string;
  is_rotational: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  product_id: string;
  name: string;
  description: string;
  sku: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  threshold_qty: number;
  package_dimensions: Dimensions;
  notes: string;
  image: Image | null;
  status: string;
  is_rotational: boolean;
  linked_installer: {
    id: string;
    name: string;
  };
};

type Image = {
  url: string;
};

interface Installer {
  supplier_id: string;
  name: string;
  email: string;
}

interface FirmDetails {
  companyID: string;
  companyName: string;
}

const AgentEditProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [product, setProduct] = useState<Product>();
  const [installers, setInstallers] = useState<Installer[]>([]);

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://dev.reelty.app/api/v1/agent/${userId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch category data");
        }
        const data = await response.json();
        console.log(data);
        setProduct(data.product);
      } catch (err) {
        console.error("Error fetching category data:", err);
        toast.error("Error fetching category data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (productId) fetchProduct();
  }, [productId, token]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/brands", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/categories",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/agent/suppliers", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch installers data");
        }
  
        const data = await response.json();
        console.log("Fetched Installers:", data);
  
        if (Array.isArray(data)) {
          setInstallers(data);
        } else {
          console.error("Unexpected installers data format:", data);
        }
      } catch (error) {
        console.error("Error fetching installers data:", error);
      }
    };
  
    fetchInstallers();
  }, [token]);
  

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "products");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://dev.reelty.app/api/v1/images/upload", true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  function getInstallerById(installerId: string) {
    console.log("Looking up installer for ID:", installerId);
    return installers.find((installer) => installer.supplier_id === installerId) || null;
  }

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: product?.name || "",
      description: product?.description || "",
      sku: product?.sku || "",
      brand: product?.brand || "",
      category: product?.category || "",
      item_condition: product?.item_condition || "",
      purchase_price: product?.purchase_price || 0,
      retail_price: product?.retail_price || 0,
      length: product?.package_dimensions?.length || 0,
      height: product?.package_dimensions?.height || 0,
      width: product?.package_dimensions?.width || 0,
      weight: product?.package_dimensions?.weight || 0,
      threshold_qty: product?.threshold_qty || 0,
      installerId: product?.linked_installer?.id || "",
      notes: product?.notes || "",
      image: null, // Initialize as null for new uploads
      imageUrl: product?.image?.url || null, // Use this for prefilled image URL
      statuss: product?.status || "active",
      is_rotational: product?.is_rotational ? "yes" : "no",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      image: Yup.mixed()
        .nullable()
        .test("fileType", "Unsupported file format", (value) =>
          value instanceof File
            ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
            : true
        ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      console.log("Selected Installer ID:", values.installerId);
      const selectedInstallerId = values.installerId;
      const selectedInstaller = getInstallerById(selectedInstallerId);
      console.log("Selected Installer Object:", selectedInstaller);

      const isRotational = values.is_rotational === "yes" ? true : false;
      const linkedMode = "linked";

      try {
        let imageUrl = values.imageUrl;

        // Upload new image if provided
        if (values.image instanceof File) {
          imageUrl = await uploadFile(values.image);
        }

        // 2. Submit product details
        const response = await fetch(
          `https://dev.reelty.app/api/v1/agent/${userId}/inventory/${productId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              description: values.description,
              product_type: "simple",
              sku: values.sku,
              brand: values.brand,
              category: values.category,
              item_condition: values.item_condition,
              purchase_price: Number(values.purchase_price),
              retail_price: Number(values.retail_price),
              package_dimensions: {
                length: Number(values.length),
                height: Number(values.height),
                width: Number(values.width),
                weight: Number(values.weight),
                weight_type: "g",
              },
              min_qty: 1,
              variants: [],
              status: values.statuss,
              is_rotational: isRotational,
              linked_mode: linkedMode,
              threshold_qty: Number(values.threshold_qty),
              image: { url: imageUrl },
              linked_installer: {
                id: selectedInstaller?.supplier_id,
                name: selectedInstaller?.name,
              },
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        navigate("/agent/inventory", {
          state: { successMessage: "Product added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Edit Inventory Item </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-1 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-8 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Brand</label>
                              <select
                                className="form-control"
                                id="brand"
                                name="brand"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.brand}
                              >
                                <option value="">- Select -</option>
                                {brands !== null &&
                                  brands.map((brand) => (
                                    <option value={brand.name}>
                                      {brand.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.brand && formik.errors.brand ? (
                                <div className="text-danger">
                                  {formik.errors.brand}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Category</label>
                              <select
                                className="form-control"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category}
                              >
                                <option value="">- Select -</option>
                                {categories !== null &&
                                  categories.map((category) => (
                                    <option value={category.name}>
                                      {category.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.category &&
                              formik.errors.category ? (
                                <div className="text-danger">
                                  {formik.errors.category}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Item Condition
                              </label>
                              <select
                                className="form-control"
                                id="item_condition"
                                name="item_condition"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.item_condition}
                              >
                                <option value="">- Select -</option>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                                <option value="damaged">Damaged</option>
                              </select>
                              {formik.touched.item_condition &&
                              formik.errors.item_condition ? (
                                <div className="text-danger">
                                  {formik.errors.item_condition}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Purchase Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="purchase_price"
                                name="purchase_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.purchase_price}
                              />
                              {formik.touched.purchase_price &&
                              formik.errors.purchase_price ? (
                                <div className="text-danger">
                                  {formik.errors.purchase_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Retail Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="retail_price"
                                name="retail_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.retail_price}
                              />
                              {formik.touched.retail_price &&
                              formik.errors.retail_price ? (
                                <div className="text-danger">
                                  {formik.errors.retail_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Threshold Quantity{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="threshold_qty"
                                name="threshold_qty"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.threshold_qty}
                              />
                              {formik.touched.threshold_qty &&
                              formik.errors.threshold_qty ? (
                                <div className="text-danger">
                                  {formik.errors.threshold_qty}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="text-danger">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description/Notes{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Dimensions/Measurements (Inches)
                              </label>
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="length"
                                    name="length"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.length}
                                    placeholder="Length"
                                  />
                                  {formik.touched.length &&
                                  formik.errors.length ? (
                                    <div className="text-danger">
                                      {formik.errors.length}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.height}
                                    placeholder="Height"
                                  />
                                  {formik.touched.height &&
                                  formik.errors.height ? (
                                    <div className="text-danger">
                                      {formik.errors.height}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="width"
                                    name="width"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.width}
                                    placeholder="Width"
                                  />
                                  {formik.touched.width &&
                                  formik.errors.width ? (
                                    <div className="text-danger">
                                      {formik.errors.width}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Weight(in gms)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="weight"
                                name="weight"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weight}
                              />
                              {formik.touched.weight && formik.errors.weight ? (
                                <div className="text-danger">
                                  {formik.errors.weight}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Image</label>
                              <input
                                type="file"
                                className="form-control"
                                id="image"
                                name="image"
                                onChange={(event) => {
                                  const file = event.currentTarget.files?.[0];
                                  if (file) {
                                    formik.setFieldValue("image", file); // Update the File field
                                    setPreview(URL.createObjectURL(file)); // Optional: show preview
                                  }
                                }}
                                onBlur={formik.handleBlur}
                              />

                              {preview && (
                                <img
                                  src={preview}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "100px",
                                    marginTop: "10px",
                                  }}
                                />
                              )}
                              {formik.touched.image && formik.errors.image ? (
                                <div className="text-danger">
                                  {formik.errors.image}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-4 mb-3">
                              <label className="form-label">Installer</label>
                              <select
                                name="installerId"
                                id="installerId"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.installerId}
                              >
                                <option value="">-Select-</option>
                                {installers &&
                                  installers.map((installer) => (
                                    <option
                                      key={installer?.supplier_id}
                                      value={installer?.supplier_id}
                                    >
                                      {installer?.name}{" "}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.installerId &&
                              formik.errors.installerId ? (
                                <div className="text-danger">
                                  {formik.errors.installerId}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Notes for the Installer{" "}
                              </label>
                              <textarea
                                className="form-control"
                                id="notes"
                                name="notes"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.notes}
                              ></textarea>
                              {formik.touched.notes && formik.errors.notes ? (
                                <div className="text-danger">
                                  {formik.errors.notes}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Is Rotational{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="is_rotational"
                                name="is_rotational"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.is_rotational}
                              >
                                <option value="">- Select -</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                              {formik.touched.is_rotational &&
                              formik.errors.is_rotational ? (
                                <div className="text-danger">
                                  {formik.errors.is_rotational}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Save Inventory Item
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentEditProduct;
