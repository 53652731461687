import React from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const SuperAdminAddProfile = () => {
  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHJlZWx0eS5jb20iLCJleHAiOjE3Mjc5NzIzMzEsInVzZXJUeXBlIjoiIn0.cFNnl3zUN32x3X_Ysj7bttI-0DpTVgLdZmY8V1NI7Xk";
  const token = localStorage.getItem('sessionToken');
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      planName: '',
      description: '',
    },
    validationSchema: Yup.object({
      planName: Yup.string()
        .min(2, 'Plan name must be at least 2 characters long')
        .max(50, 'Plan name cannot be longer than 50 characters')
        .required('Plan name is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch('https://dev.reelty.app/api/v1/plan-profile', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Add the Bearer token here
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            planName: values.planName,
            description: values.description,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to submit form');
        }

        const data = await response.json();
        // toast.success("Plan profile added successfully!");
        console.log('Form successfully submitted:', data);
        navigate('/superadmin/profiles', { state: { successMessage: "Plan profile added successfully!" } });
        
      } catch (error) {
        toast.error("An error occurred while adding the plan profile.");
        console.error('Error submitting form:', error);
      }
    },
  });

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Add Plan Profile </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          Plan Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="planName"
                          name="planName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.planName}
                          required
                        />
                        {formik.touched.planName && formik.errors.planName ? (
                          <div className="text-danger">{formik.errors.planName}</div>
                        ) : null}
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="description"
                          name="description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mt-4">
                          <button className="btn btn-success w-100" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddProfile;
