import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

const TeammemberEditCategory = () => {
  const { catId } = useParams<{ catId: string }>();
  const [category, setCategory] = useState<Category | null>(null);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  // Fetch category data
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://dev.reelty.app/api/v1/categories/${catId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch category data");
        }
        const data = await response.json();
        setCategory({
          id: data.id || "",
          name: data.name || "",
          image: `https://dev.reelty.app/${data.image}`,
          status: data.status ? "Active" : "Inactive",
        });
      } catch (err) {
        console.error("Error fetching category data:", err);
        toast.error("Error fetching category data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (catId) fetchCategory();
  }, [catId, token]);

  // Upload file to the server
  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "categories");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://dev.reelty.app/api/v1/images/upload", true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          resolve(`${response.path}`);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      name: "",
      image: null as File | null,
      statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      let imageUrl = category?.image || "";

      try {
        // Upload image if a new file is selected
        if (values.image) {
          imageUrl = await uploadFile(values.image);
        }

        // Update category data
        const response = await fetch(
          `https://dev.reelty.app/api/v1/categories/${catId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              image: imageUrl,
              status: values.statuss === "Active",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update category");
        }

        toast.success("Category updated successfully!");
        navigate("/teammember/categories", {
          state: { successMessage: "Category updated successfully!" },
        });
      } catch (err) {
        console.error(err);
        toast.error("Failed to update category.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  // Set Formik values when category is fetched
  useEffect(() => {
    if (category) {
      formik.setValues({
        name: category.name,
        image: null,
        statuss: category.status,
      });
    }
  }, [category]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Category</h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    Status <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="statuss"
                    name="statuss"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.statuss}
                  >
                    <option value="">- Select -</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  {formik.touched.statuss && formik.errors.statuss ? (
                    <div className="text-danger">{formik.errors.statuss}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Category Image</label><br />
                  <input
                    type="file"
                    className="form-control"
                    id="image"
                    name="image"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        formik.setFieldValue("image", e.target.files[0]);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>

                {category?.image && (
                  <div>
                    <label>Current Image:</label>
                    <img src={category.image} alt="Category" height={100} />
                  </div>
                )}
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-success w-100"
              disabled={formSubmitting}
            >
              {formSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TeammemberEditCategory;
