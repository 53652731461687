import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import "./SignageAddVariableProduct.css";

type FormValues = {
  name: string;
  product_type: string;
  sku: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  condition_note: string;
  purchase_price: string | number;
  retail_price: string | number;
  length: string | number;
  height: string | number;
  width: string | number;
  weight: string | number;
  threshold_qty: string | number;
  attribute: string;
  parameter: string;
  image: File | string | null;
  statuss: string;
  is_rotational: string;
  warehouseId: string;
  on_hand: string;
  available: string;
  reserved: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Warehouse = {
  id: string;
  label: string;
  status: string;
};

type Attribute = {
  id: string;
  name: string;
  values: string[];
  description: string;
  status: string;
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      location_id: string;
      location_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type Image = {
  url: string;
};

type Product = {
  product_id: string;
  name: string;
  product_type: string;
  sku: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: Dimensions;
  threshold_qty: number;
  variants: Variant[];
  condition_note: string;
  image: Image | null;
  status: string;
  is_rotational: boolean;
};

const SignageEditVariableProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product>();
  const [variants, setVariants] = useState<Variant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [options, setOptions] = useState<Record<string, string>[]>([]);
  const [selectedWarehouseName, setSelectedWarehouseName] = useState<
    string | null
  >(null);

  // Fetch product data
useEffect(() => {
    const fetchProduct = async () => {
      if (!token || !userId) {
        console.error("Missing token or userId");
        return;
      }
  
      try {
        setLoading(true);
        const response = await fetch(
          `https://dev.reelty.app/api/v1/signage/${userId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }
  
        const data = await response.json();
        console.log("Fetched product data:", data);
  
        setProduct(data);
  
        if (data.variants && data.variants.length > 0) {
          setVariants(data.variants);
        }
      } catch (err) {
        console.error("Error fetching product data:", err);
        toast.error("Error fetching product data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
  
    if (productId) fetchProduct();
  }, [productId, token, userId]);
  
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/brands", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/categories",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/warehouses",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setWarehouses(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouses();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "brands");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://dev.reelty.app/api/v1/images/upload", true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/product-attributes",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setAttributes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAttributes();
  }, [token]);

  const handleAttributeClick = (attribute: string, value: string) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      const existingIndex = updatedOptions.findIndex(
        (option) => option[attribute.toLowerCase()] === value
      );
      if (existingIndex !== -1) {
        updatedOptions.splice(existingIndex, 1); // Remove if already exists
      } else {
        updatedOptions.push({ [attribute.toLowerCase()]: value });
      }
      return updatedOptions;
    });
  };

  const handleRemoveOption = (index: number) => {
    setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
  };

  const handleGenerateCombinations = () => {
    const attributeValues = attributes.reduce<Record<string, string[]>>(
      (acc, attr) => {
        const selectedValues = attr.values.filter((value) =>
          options.some((option) => option[attr.name.toLowerCase()] === value)
        );
        if (selectedValues.length > 0) {
          acc[attr.name.toLowerCase()] = selectedValues;
        }
        return acc;
      },
      {}
    );

    const keys = Object.keys(attributeValues);
    if (keys.length === 0) {
      toast.error("No attributes selected.");
      return;
    }

    // Helper function to generate combinations
    const generateCombinations = (
      remainingKeys: string[],
      currentCombination: Record<string, string>
    ) => {
      if (remainingKeys.length === 0) {
        return [currentCombination];
      }

      const [key, ...restKeys] = remainingKeys;
      const values = attributeValues[key];
      const combinations: Record<string, string>[] = [];

      for (const value of values) {
        combinations.push(
          ...generateCombinations(restKeys, {
            ...currentCombination,
            [key]: value,
          })
        );
      }

      return combinations;
    };

    const allCombinations = generateCombinations(keys, {});

    setOptions(allCombinations);
    toast.success("Combinations generated successfully!");
  };

  const createSlug = (input: string): string => {
    return input
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading and trailing whitespace
      .replace(/[^a-z0-9\s-]/g, "") // Remove invalid characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single one
  };

  const generateProductNames = (
    productName: string,
    options: { [key: string]: string }[]
  ) => {
    return options.map((option) => {
      const formattedAttributes = Object.values(option)
        .map((value) => value.trim()) // Trim whitespace
        .join(" - "); // Combine attributes with " - "
      return `${productName} - ${formattedAttributes}`;
    });
  };

  const generateProductVariants = (
    productNames: string[],
    values: FormValues,
    options: Record<string, string>[]
  ) => {
    return productNames.map((name, index) => {
      return {
        variant_name: name,
        sku: `${values.sku}-${index + 1}`, // Add SKU differentiation
        attributes: options[index] || {}, // Map attributes to combination
        purchase_price: Number(values.purchase_price),
        retail_price: Number(values.retail_price),
        package_dimensions: {
          length: Number(values.length),
          height: Number(values.height),
          width: Number(values.width),
          weight: Number(values.weight),
          weight_type: "kg",
        },
        inventory: [
          {
            location_id: values.warehouseId,
            location_name: selectedWarehouseName || "Default Warehouse",
            on_hand: Number(values.on_hand),
            available: Number(values.available),
            reserved: Number(values.reserved),
          },
        ],
        meta_data: {
          slug: createSlug(name),
        },
      };
    });
  };

  const reverseProductVariants = (variants: any[]) => {
    if (variants.length === 0) {
      return {
        productNames: [],
        values: {},
        exoptions: [],
      };
    }
  
    // Extract productNames
    const productNames = variants.map((variant) => variant.variant_name);
  
    // Extract common values from the first variant
    const firstVariant = variants[0];
    const values: FormValues = {
      name: "",
      product_type: "variable", // Assuming product type is "variable"
      sku: firstVariant.sku.split("-")[0], // Extract SKU base
      description: "", // Placeholder as it's not in variants
      brand: "", // Placeholder as it's not in variants
      category: "", // Placeholder as it's not in variants
      item_condition: "", // Placeholder as it's not in variants
      condition_note: "", // Placeholder as it's not in variants
      purchase_price: firstVariant.purchase_price,
      retail_price: firstVariant.retail_price,
      length: firstVariant.package_dimensions.length,
      height: firstVariant.package_dimensions.height,
      width: firstVariant.package_dimensions.width,
      weight: firstVariant.package_dimensions.weight,
      threshold_qty: "", // Placeholder as it's not in variants
      attribute: "", // Placeholder as it's not in variants
      parameter: "", // Placeholder as it's not in variants
      image: null, // Placeholder as it's not in variants
      statuss: "", // Placeholder as it's not in variants
      is_rotational: "", // Placeholder as it's not in variants
      warehouseId: firstVariant.inventory[0]?.location_id || "",
      on_hand: String(firstVariant.inventory[0]?.on_hand || 0),
      available: String(firstVariant.inventory[0]?.available || 0),
      reserved: String(firstVariant.inventory[0]?.reserved || 0),
    };
  
    // Extract options from each variant
    const exoptions = variants.map((variant) => variant.attributes || {});
  
    return { productNames, values, exoptions };
  };

  const { productNames, values, exoptions } = reverseProductVariants(variants);
  console.log("Product Names:", productNames);
  console.log("Values:", values);
  console.log("Exoptions:", exoptions);
  console.log("options:", options);
  console.log("Variants:", variants);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: product?.name || "",
      product_type: "variable",
      sku: product?.sku || "",
      description: product?.description || "",
      brand: product?.brand || "",
      category: product?.category || "",
      item_condition: product?.item_condition || "",
      condition_note: product?.condition_note || "",
      purchase_price: String(product?.purchase_price || 0),
      retail_price: String(product?.retail_price || 0),
      length: String(product?.package_dimensions.length || 0),
      height: String(product?.package_dimensions.height || 0),
      width: String(product?.package_dimensions.width || 0),
      weight: String(product?.package_dimensions.weight || 0),
      threshold_qty: String(product?.threshold_qty || 0),
      attribute: product?.name || "",
      parameter: "",
      image: product?.image?.url || null,
      statuss: product?.status || "active",
      is_rotational: product?.is_rotational ? "yes" : "no",
      warehouseId: product?.variants?.[0]?.inventory?.[0]?.location_id || "",
      on_hand: String(product?.variants?.[0]?.inventory?.[0]?.on_hand || 0),
      available: String(product?.variants?.[0]?.inventory?.[0]?.available || 0),
      reserved: String(product?.variants?.[0]?.inventory?.[0]?.reserved || 0),
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      image: Yup.mixed().test("fileType", "Unsupported file format", (value) =>
        value instanceof File
          ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
          : true
      ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      //   if (!(values.image instanceof File)) {
      //     toast.error("Please upload a valid image file.");
      //     setFormSubmitting(false);
      //     return;
      //   }

      // console.log(options);
      const productName = values.name;
      const productNames = generateProductNames(productName, options);

      console.log(productNames);

      const productVariants = generateProductVariants(
        productNames,
        values,
        options
      );
      console.log("Product Variants:", productVariants);

      // const selectedOptions:AttributeValuePair[] = options;
      // const transformedObject = options.reduce<Record<string, string>>((obj, item) => {
      //   obj[item.attribute.toLowerCase()] = item.value.trim();
      //   return obj;
      // }, {});

      try {
        let imageUrl = product?.image?.url; // Use existing image URL by default

        // Upload new image if provided
        if (values.image instanceof File) {
          imageUrl = await uploadFile(values.image);
        }

        // 2. Submit product details
        const response = await fetch(
          `https://dev.reelty.app/api/v1/signage/${userId}/inventory/${productId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              product_type: values.product_type,
              sku: values.sku,
              description: values.description,
              brand: values.brand,
              category: values.category,
              item_condition: values.item_condition,
              condition_note: values.condition_note,
              purchase_price: Number(values.purchase_price),
              retail_price: Number(values.retail_price),
              package_dimensions: {
                length: Number(values.length),
                height: Number(values.height),
                width: Number(values.width),
                weight: Number(values.weight),
              },
              min_qty: 1,
              variants: productVariants,
              threshold_qty: Number(values.threshold_qty),
              image: { url: imageUrl },
              status: values.statuss,
              is_rotational: values.is_rotational == "yes" ? true : false,
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        navigate("/signage/products", {
          state: { successMessage: "Product added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Edit Variable Product </h2>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills nav-success mb-3" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#product-details"
                      role="tab"
                    >
                      Product Details
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#combinations"
                      role="tab"
                    >
                      Combinations
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#images"
                      role="tab"
                    >
                      Images
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#orders"
                      role="tab"
                    >
                      Orders
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#inventory-log"
                      role="tab"
                    >
                      Inventory Log
                    </a>
                  </li>
                </ul>

                <div className="tab-content text-muted">
                  <div
                    className="tab-pane active"
                    id="product-details"
                    role="tabpanel"
                  >
                    <div className="d-flex mt-2">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-8 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                SKU <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="sku"
                                name="sku"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.sku}
                              />
                              {formik.touched.sku && formik.errors.sku ? (
                                <div className="text-danger">
                                  {formik.errors.sku}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-8">
                              <div className="card">
                                <div className="card-body">
                                  {/* <h5>Branding</h5> */}
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">Brand</label>
                                    <select
                                      className="form-control"
                                      id="brand"
                                      name="brand"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.brand}
                                    >
                                      <option value="">- Select -</option>
                                      {brands !== null &&
                                        brands.map((brand) => (
                                          <option value={brand.name}>
                                            {brand.name}
                                          </option>
                                        ))}
                                    </select>
                                    {formik.touched.brand &&
                                    formik.errors.brand ? (
                                      <div className="text-danger">
                                        {formik.errors.brand}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Category
                                    </label>
                                    <select
                                      className="form-control"
                                      id="category"
                                      name="category"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.category}
                                    >
                                      <option value="">- Select -</option>
                                      {categories !== null &&
                                        categories.map((category) => (
                                          <option value={category.name}>
                                            {category.name}
                                          </option>
                                        ))}
                                    </select>
                                    {formik.touched.category &&
                                    formik.errors.category ? (
                                      <div className="text-danger">
                                        {formik.errors.category}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="card">
                                <div className="card-body">
                                  {/* <h5>Pricing</h5> */}
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Purchase Price{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="purchase_price"
                                      name="purchase_price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.purchase_price}
                                    />
                                    {formik.touched.purchase_price &&
                                    formik.errors.purchase_price ? (
                                      <div className="text-danger">
                                        {formik.errors.purchase_price}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Retail Price{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="retail_price"
                                      name="retail_price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.retail_price}
                                    />
                                    {formik.touched.retail_price &&
                                    formik.errors.retail_price ? (
                                      <div className="text-danger">
                                        {formik.errors.retail_price}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description/Notes{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label htmlFor="">Warehouse </label>
                                        <select
                                          name="warehouseId"
                                          id="warehouseId"
                                          className="form-control"
                                        >
                                          <option value="">- Select -</option>
                                          {warehouses != null &&
                                            warehouses.map(
                                              (warehouse, index) => (
                                                <option value={warehouse.id}>
                                                  {warehouse.label}
                                                </option>
                                              )
                                            )}
                                        </select>
                                      </div>
                                      <div className="col-md-2">
                                        <label htmlFor="on_hand">On Hand</label>
                                        <input
                                          type="text"
                                          name="on_hand"
                                          id="on_hand"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.on_hand}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <label htmlFor="available">
                                          Available
                                        </label>
                                        <input
                                          type="text"
                                          name="available"
                                          id="available"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.available}
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <label htmlFor="reserved">
                                          Reserved
                                        </label>
                                        <input
                                          type="text"
                                          name="reserved"
                                          id="reserved"
                                          className="form-control"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.reserved}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Threshold Quantity{" "}
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="threshold_qty"
                                          name="threshold_qty"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.threshold_qty}
                                        />
                                        {formik.touched.threshold_qty &&
                                        formik.errors.threshold_qty ? (
                                          <div className="text-danger">
                                            {formik.errors.threshold_qty}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Status{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          id="statuss"
                                          name="statuss"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.statuss}
                                        >
                                          <option value="">- Select -</option>
                                          <option value="Active">Active</option>
                                          <option value="Inactive">
                                            Inactive
                                          </option>
                                        </select>
                                        {formik.touched.statuss &&
                                        formik.errors.statuss ? (
                                          <div className="text-danger">
                                            {formik.errors.statuss}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Is Rotational{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          id="is_rotational"
                                          name="is_rotational"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.is_rotational}
                                        >
                                          <option value="">- Select -</option>
                                          <option value="yes">Yes</option>
                                          <option value="no">No</option>
                                        </select>
                                        {formik.touched.is_rotational &&
                                        formik.errors.is_rotational ? (
                                          <div className="text-danger">
                                            {formik.errors.is_rotational}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Dimensions/Measurements (Inches)
                                  </label>
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="length"
                                        name="length"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.length}
                                        placeholder="Length"
                                      />
                                      {formik.touched.length &&
                                      formik.errors.length ? (
                                        <div className="text-danger">
                                          {formik.errors.length}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="height"
                                        name="height"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.height}
                                        placeholder="Height"
                                      />
                                      {formik.touched.height &&
                                      formik.errors.height ? (
                                        <div className="text-danger">
                                          {formik.errors.height}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="width"
                                        name="width"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.width}
                                        placeholder="Width"
                                      />
                                      {formik.touched.width &&
                                      formik.errors.width ? (
                                        <div className="text-danger">
                                          {formik.errors.width}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <label className="form-label">
                                        Weight(in gms)
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="weight"
                                        name="weight"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.weight}
                                      />
                                      {formik.touched.weight &&
                                      formik.errors.weight ? (
                                        <div className="text-danger">
                                          {formik.errors.weight}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                {/* <h5>Pricing</h5> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Item Condition
                                  </label>
                                  <select
                                    className="form-control"
                                    id="item_condition"
                                    name="item_condition"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.item_condition}
                                  >
                                    <option value="">- Select -</option>
                                    <option value="new">New</option>
                                    <option value="used">Used</option>
                                    <option value="damaged">Damaged</option>
                                  </select>
                                  {formik.touched.item_condition &&
                                  formik.errors.item_condition ? (
                                    <div className="text-danger">
                                      {formik.errors.item_condition}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Condition Notes{" "}
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="condition_note"
                                    name="condition_note"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.condition_note}
                                    rows={3}
                                  ></textarea>
                                  {formik.touched.condition_note &&
                                  formik.errors.condition_note ? (
                                    <div className="text-danger">
                                      {formik.errors.condition_note}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Image</label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    name="image"
                                    onChange={(event) => {
                                      const file =
                                        event?.currentTarget.files?.[0];
                                      if (file) {
                                        formik.setFieldValue("image", file); // Set File object
                                        setPreview(URL.createObjectURL(file)); // Set preview for new file
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />

                                  {formik.touched.image &&
                                    formik.errors.image && (
                                      <div className="text-danger">
                                        {formik.errors.image}
                                      </div>
                                    )}

                                  {/* Existing Image */}
                                  {product?.image?.url && !preview && (
                                    <div className="mt-3">
                                      <strong>Current Image:</strong>
                                      <br />
                                      <img
                                        src={
                                          "https://dev.reelty.app/" +
                                          product.image.url
                                        }
                                        alt="Existing Product"
                                        style={{
                                          maxWidth: "100px",
                                          marginTop: "10px",
                                        }}
                                      />
                                    </div>
                                  )}

                                  {/* Preview New Image */}
                                  {preview && (
                                    <div className="mt-3">
                                      <strong>Preview:</strong>
                                      <img
                                        src={preview}
                                        alt="Preview"
                                        style={{
                                          maxWidth: "100px",
                                          marginTop: "10px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <input
                        type="hidden"
                        id="product_type"
                        name="product_type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.product_type}
                      />
                      <button className="btn btn-success w-100" type="submit">
                        Save Product
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane" id="combinations" role="tabpanel">
                    <div className="mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-9">
                                  <h4>Combinations</h4>
                                  <div className="text-end">
                                    <button
                                      type="button"
                                      className="btn btn-info"
                                      onClick={handleGenerateCombinations}
                                    >
                                      Generate
                                    </button>
                                  </div>
                                  <div className="combinations">
                                    {exoptions?.length === 0 && (
                                      <p>No combinations added yet.</p>
                                    )}
                                    {exoptions?.map((combination, index) => (
                                      <div
                                        key={index}
                                        className="option-item d-flex mb-2"
                                      >
                                        <span className="me-2">
                                          {Object.entries(combination)
                                            .map(
                                              ([key, value]) =>
                                                `${key}: ${value}`
                                            )
                                            .join(", ")}
                                        </span>
                                        <button
                                          type="button"
                                          className="btn btn-danger btn-sm"
                                          onClick={() =>
                                            handleRemoveOption(index)
                                          }
                                        >
                                          X
                                        </button>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <h4>Attributes</h4>
                                  <div className="attributes">
                                    {attributes != null &&
                                      attributes.map((attr, index) => (
                                        <div key={index}>
                                          <div className="attr-head">
                                            {attr.name}
                                          </div>
                                          <ul className="list-group">
                                            {attr.values.map(
                                              (param, paramIndex) => (
                                                <li
                                                  key={paramIndex}
                                                  className="list-group-item"
                                                  onClick={() =>
                                                    handleAttributeClick(
                                                      attr.name,
                                                      param
                                                    )
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                      options.some(
                                                        (opt) =>
                                                          opt[
                                                            attr.name.toLowerCase()
                                                          ] === param
                                                      )
                                                        ? "lightgreen"
                                                        : "white",
                                                  }}
                                                >
                                                  {param}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <button className="btn btn-success w-100" type="submit">
                        Save Product
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane" id="images" role="tabpanel">
                    <div className="d-flex mt-2">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-0">Product Gallery</h5>
                        </div>
                        <div className="card-body">
                          <div className="mb-4">
                            {/* <h5 className="fs-14 mb-1">Product Image</h5>
                          <p className="text-muted">Add Product main Image.</p> */}
                            {/* <div className="text-center">
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute top-100 start-100 translate-middle">
                                <label
                                  htmlFor="product-image-input"
                                  className="mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="Select Image"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control d-none"
                                  value=""
                                  id="product-image-input"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                />
                              </div>
                              <div className="avatar-lg">
                                <div className="avatar-title bg-light rounded">
                                  <img
                                    src=""
                                    id="product-img"
                                    className="avatar-md h-auto"
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
                          </div>
                          <div>
                            {/* <h5 className="fs-14 mb-1">Product Gallery</h5> */}
                            <p className="text-muted">
                              Add Product Gallery Images.
                            </p>

                            <div className="dropzone">
                              <div className="fallback">
                                <input name="file" type="file" />
                              </div>
                              {/* <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
                              </div>

                              <h5>Drop files here or click to upload.</h5>
                            </div> */}
                            </div>

                            {/* <ul
                            className="list-unstyled mb-0"
                            id="dropzone-preview"
                          >
                            <li className="mt-2" id="dropzone-preview-list">
                              <div className="border rounded">
                                <div className="d-flex p-2">
                                  <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm bg-light rounded">
                                      <img
                                        data-dz-thumbnail
                                        className="img-fluid rounded d-block"
                                        src="#"
                                        alt="Product-Image"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="pt-1">
                                      <h5 className="fs-14 mb-1" data-dz-name>
                                        &nbsp;
                                      </h5>
                                      <p
                                        className="fs-13 text-muted mb-0"
                                        data-dz-size
                                      ></p>
                                      <strong
                                        className="error text-danger"
                                        data-dz-errormessage
                                      ></strong>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 ms-3">
                                    <button
                                      data-dz-remove
                                      className="btn btn-sm btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button className="btn btn-success w-100" type="submit">
                        Save Product
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane" id="orders" role="tabpanel">
                    <div className="d-flex mt-2">
                      <p>Orders</p>
                    </div>
                  </div>
                  <div className="tab-pane" id="inventory-log" role="tabpanel">
                    <div className="d-flex mt-2">
                      <p>Iventory logs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="mt-0 card-bg-fill">
            <div className="pt-1">
              <div className="pt-1 mt-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageEditVariableProduct;
