import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Profile {
  id: string;
  companyName: string;
  // companyWebsite: string;
  // role: string;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  phone2: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  status: string;
  numberOfAgents: number;
  specialties: string[];
  regionsServed: string[];
}

const libraries: Array<"places"> = ["places"];
const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

const specialtiesOptions = [
  { value: "Residential", label: "Residential" },
  { value: "Commercial", label: "Commercial" },
  { value: "Luxury", label: "Luxury" },
  { value: "Property Management", label: "Property Management" }
];

const RealtorProfile = () => {
  const token = localStorage.getItem("sessionToken");
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | null>(null); // type-safe
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [address, setAddress] = useState("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const cities = [
    "New York",
    "Los Angeles",
    "Chicago",
    "Houston",
    "Phoenix",
    "Philadelphia",
    "San Antonio",
    "San Diego",
    "Dallas",
    "San Jose",
    "Austin",
    "Jacksonville",
    "Fort Worth",
    "Columbus",
    "Charlotte",
    "San Francisco",
    "Indianapolis",
    "Seattle",
    "Denver",
    "Washington D.C.",
    "Boston",
    "El Paso",
    "Nashville",
    "Detroit",
    "Oklahoma City",
    "Las Vegas",
    "Portland",
    "Memphis",
    "Louisville",
    "Baltimore",
    "Milwaukee",
    "Albuquerque",
    "Tucson",
    "Fresno",
    "Mesa",
    "Sacramento",
    "Atlanta",
    "Kansas City",
    "Colorado Springs",
    "Miami",
    "Raleigh",
    "Omaha",
    "Long Beach",
    "Virginia Beach",
    "Oakland",
    "Minneapolis",
    "Tulsa",
    "Arlington",
    "New Orleans",
    "Wichita",
    "Cleveland",
    "Tampa",
    "Bakersfield",
    "Aurora",
    "Anaheim",
    "Honolulu",
    "Santa Ana",
    "Riverside",
    "Corpus Christi",
    "Lexington",
    "Henderson",
    "Stockton",
    "St. Paul",
    "Cincinnati",
    "St. Louis",
    "Pittsburgh",
    "Greensboro",
    "Lincoln",
    "Anchorage",
    "Plano",
    "Orlando",
    "Irvine",
    "Newark",
    "Durham",
    "Chula Vista",
    "Toledo",
    "Fort Wayne",
    "St. Petersburg",
    "Laredo",
    "Jersey City",
    "Chandler",
    "Madison",
    "Lubbock",
    "Scottsdale",
    "Reno",
    "Buffalo",
    "Gilbert",
    "Glendale",
    "North Las Vegas",
    "Winston-Salem",
    "Chesapeake",
    "Norfolk",
    "Fremont",
    "Garland",
    "Irving",
    "Hialeah",
    "Richmond",
    "Boise",
    "Spokane",
    "Baton Rouge",
  ];

  const cityOptions = cities.map((city) => ({
    value: city,
    label: city,
  }));

  const [selectedCities, setSelectedCities] = useState([]);

  // const handleCityChange = (event) => {
  //   const selectedOptions = Array.from(
  //     event.target.selectedOptions,
  //     (option) => option.value
  //   );
  //   setSelectedCities(selectedOptions);
  // };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        console.log(data);

        if (data && data.firmDetails && data.firmDetails.address) {
          setProfile({
            id: data.id || "",
            companyName: data.firmDetails.companyName || "",
            // companyWebsite: data.firmDetails.companyWebsite || "",
            // role: data.userType || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            email: data.email || "",
            phone1: data.phone1 || "",
            phone2: data.phone2 || "",
            addressLine1: data.firmDetails.address.addressLine1 || "",
            addressLine2: data.firmDetails.address.addressLine2 || "",
            city: data.firmDetails.address.city || "",
            state: data.firmDetails.address.state || "",
            zip: data.firmDetails.address.zip || "",
            country: data.firmDetails.address.country || "",
            status: data.status || "Active",
            numberOfAgents: data.firmDetails.numberOfAgents || 0,
            specialties: data.firmDetails.specialties || [],
            regionsServed: data.firmDetails.regionsServed || [],
          });
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching license data:", error);
      }
    };

    if (!profile) {
      fetchProfile();
    }
  }, [userId, token]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: profile?.firstName || "",
      lastName: profile?.lastName || "",
      phone1: profile?.phone1 || "",
      phone2: profile?.phone2 || "",
      email: profile?.email || "",
      companyName: profile?.companyName || "",
      addressLine1: profile?.addressLine1 || "",
      addressLine2: profile?.addressLine2 || "",
      city: profile?.city || "",
      state: profile?.state || "",
      country: profile?.country || "",
      zipcode: profile?.zip || "",
      numberOfAgents: profile?.numberOfAgents || "",
      specialties: profile?.specialties || [],
      regionsServed: profile?.regionsServed || [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      phone1: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      phone2: Yup.string().matches(
        /^\d{10}$/,
        "Phone number must be exactly 10 digits"
      ),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // password: Yup.string()
      //   .min(8, "Password must be at least 8 characters long")
      //   .max(16, "Password must be max 16 characters long")
      //   .required("Password is required"),
      // cpassword: Yup.string()
      //   .oneOf([Yup.ref("password"), ""], "Passwords must match")
      //   .required("Confirm Password is required"),
      companyName: Yup.string()
        .min(2, "Company Name must be at least 2 characters long")
        .max(50, "Company Name cannot be longer than 50 characters")
        .required("Company Name is required"),
      addressLine1: Yup.string()
        .min(2, "Street Name must be at least 2 characters long")
        .max(50, "Street Name cannot be longer than 50 characters")
        .required("Street Name is required"),
      city: Yup.string()
        .min(2, "City must be at least 2 characters long")
        .max(50, "City cannot be longer than 50 characters"),
      state: Yup.string()
        .min(2, "State must be at least 2 characters long")
        .max(50, "State cannot be longer than 50 characters"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // For debugging
      setFormSubmitting(true);
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${userId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              phone1: values.phone1,
              phone2: values.phone2,
              userType: 'brokerage_firm',
              licenseType: 'BROKERAGE_FIRM',
              firmDetails: {
                companyName: values.companyName,
                // companyWebsite: values.companyWebsite,
                address: {
                  addressLine1: values.addressLine1,
                  addressLine2: values.addressLine2,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  zip: values.zipcode,
                  phone: values.phone1,
                },
                numberOfAgents: Number(values.numberOfAgents),
                specialties: values.specialties,
                regionsServed: values.regionsServed,
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Profile updated successfully!");
        console.log("Form submission successful:", result);
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update profile.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      const addressComponents = place.address_components;

      if (addressComponents) {
        // Helper function to get specific address component
        const getComponent = (type: string) => {
          const component = addressComponents.find((c) =>
            c.types.includes(type)
          );
          return component ? component.long_name : "";
        };

        const streetNumber = getComponent("street_number");
        const route = getComponent("route");
        const city =
          getComponent("locality") ||
          getComponent("administrative_area_level_2");
        const state = getComponent("administrative_area_level_1");
        const postalCode = getComponent("postal_code");

        const street = `${streetNumber} ${route}`.trim();

        // Update form values
        formik.setFieldValue("addressLine1", street);
        formik.setFieldValue("city", city);
        formik.setFieldValue("state", state);
        formik.setFieldValue("zipcode", postalCode);
      }
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <>
      <div className="card mt-4 card-bg-fill">
        <div className="card-body p-4">
          <div className="mt-2">
            <h4>User Details (Primary Contact)</h4>
          </div>
          <div className="p-2 mt-4">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    required
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-danger">{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    required
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-danger">{formik.errors.lastName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone1"
                    name="phone1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone1}
                    required
                  />
                  {formik.touched.phone1 && formik.errors.phone1 ? (
                    <div className="text-danger">{formik.errors.phone1}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Secondary Phone Number </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone2"
                    name="phone2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone2}
                  />
                  {formik.touched.phone2 && formik.errors.phone2 ? (
                    <div className="text-danger">{formik.errors.phone2}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    required
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>

                {/* <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div className="position-relative auth-pass-inputgroup">
                    <input
                      type="password"
                      className="form-control pe-5 password-input"
                      id="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      aria-describedby="password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      required
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                      type="button"
                      id="password-addon"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-danger">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <div className="position-relative auth-pass-inputgroup">
                    <input
                      type="password"
                      className="form-control pe-5 password-input"
                      id="cpassword"
                      name="cpassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cpassword}
                      aria-describedby="cpassword"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      required
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                      type="button"
                      id="password-addon"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                    {formik.touched.cpassword && formik.errors.cpassword ? (
                      <div className="text-danger">
                        {formik.errors.cpassword}
                      </div>
                    ) : null}
                  </div>
                </div> */}
              </div>
              <h4>Brokerage Firm Details </h4>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">
                    Company Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    required
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="text-danger">
                      {formik.errors.companyName}
                    </div>
                  ) : null}
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Street Name <span className="text-danger">*</span>
                  </label>
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (autocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <input
                      type="text"
                      className="form-control"
                      id="addressLine1"
                      name="addressLine1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.addressLine1}
                      required
                    />
                  </Autocomplete>
                  {formik.touched.addressLine1 && formik.errors.addressLine1 ? (
                    <div className="text-danger">
                      {formik.errors.addressLine1}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Address line 2 </label>
                  <input
                    type="text"
                    className="form-control"
                    id="addressLine2"
                    name="addressLine2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.addressLine2}
                  />
                  <div className="invalid-feedback">
                    Please enter Address line 2
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-danger">{formik.errors.city}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div className="text-danger">{formik.errors.state}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <div className="text-danger">{formik.errors.country}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Zip code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    name="zipcode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipcode}
                  />
                  {formik.touched.zipcode && formik.errors.zipcode ? (
                    <div className="text-danger">{formik.errors.zipcode}</div>
                  ) : null}
                </div>
              </div>
              
              <h4>Firm Size & Specialties</h4>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Number of Agents </label>
                  <input
                    type="text"
                    className="form-control"
                    id="numberOfAgents"
                    name="numberOfAgents"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.numberOfAgents}
                  />
                  <div className="invalid-feedback">
                    Please enter Number of Agents
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Specialties </label>
                  <Select
          id="specialties"
          name="specialties"
          options={specialtiesOptions} // The options array
          isMulti // Enable multiple selection
          closeMenuOnSelect={false} // Keep the menu open while selecting multiple
          value={specialtiesOptions.filter(option =>
            formik.values.specialties.includes(option.value)
          )} // Map the selected values to the select component
          onChange={(selectedOptions) => {
            formik.setFieldValue(
              "specialties",
              selectedOptions ? selectedOptions.map(option => option.value) : []
            );
          }}
          onBlur={formik.handleBlur}
        />

        {formik.touched.specialties && formik.errors.specialties ? (
          <div className="text-danger">{formik.errors.specialties}</div>
        ) : null}
                  <div className="invalid-feedback">
                    Please select Specialties
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Regions Served </label>
                  {/* <input
                    type="text"
                    className="form-control"
                    id="regionsServed"
                    name="regionsServed"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.regionsServed}
                  /> */}
                  <Select
                    id="regionsServed"
                    name="regionsServed"
                    options={cityOptions}
                    isMulti // Enable multi-select
                    closeMenuOnSelect={false} // Keep the dropdown open while selecting
                    hideSelectedOptions={false} // Keep options visible
                    value={cityOptions.filter((option) =>
                      formik.values.regionsServed.includes(option.value)
                    )}
                    onChange={(selectedOptions) => {
                      formik.setFieldValue(
                        "regionsServed",
                        selectedOptions.map((option) => option.value)
                      );
                    }}
                  />

                  {formik.errors.regionsServed ? (
                    <div className="text-danger">
                      {formik.errors.regionsServed}
                    </div>
                  ) : null}

                  <div className="invalid-feedback">
                    Please select Regions Served
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <button
                  className="btn btn-success w-100"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RealtorProfile;
