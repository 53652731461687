import React, { useState, useRef, useEffect } from "react";
import NavbarBrandBox from "./NavbarBrandBox";
import MyCartDropdown from "./MyCartDropdown";
import NotificationDropdown from "./NotificationDropdown";
import { Link } from "react-router-dom";
import ProfileDropdownn from "./ProfileDropdownn";
import LogoutButton from "./LogoutButton";

const RealtorHeader = () => {
  const [isActive, setIsActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const divRef = useRef<HTMLDivElement | null>(null);

  const toogleMenuBtn = () => {
    setIsActive(!isActive);

    var windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;

    if (windowSize > 767) humberIcon.classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setIsActive(false); // Hide the div
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <NavbarBrandBox />

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger material-shadow-none"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <form className="app-search d-none d-md-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    autoComplete="off"
                    id="search-options"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <span className="mdi mdi-magnify search-widget-icon"></span>
                  <span
                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                    id="search-close-options"
                  ></span>
                </div>
              </form>
            </div>

            <div className="d-flex align-items-center">
              <div className="dropdown d-md-none topbar-head-dropdown header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle"
                  id="page-header-search-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-search fs-22"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <MyCartDropdown />
              <NotificationDropdown />
              <ProfileDropdownn />
            </div>
          </div>
        </div>
      </header>
      {isActive && (
        <div ref={divRef} className="active">
          <div
            className={
              isActive
                ? "app-menu navbar-menu vertical-sidebar-enable"
                : "app-menu navbar-menu"
            }
          >
            <div className="navbar-brand-box">
              <Link to="index.html" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="./assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo-dark.png" alt="" height="17" />
                </span>
              </Link>
              <Link to="index.html" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="assets/images/logo-light.png" alt="" height="17" />
                </span>
              </Link>
              <button
                type="button"
                className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                id="vertical-hover"
              >
                <i className="ri-record-circle-line"></i>
              </button>
            </div>

            <div className="dropdown sidebar-user m-1 rounded">
              <button
                type="button"
                className="btn material-shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center gap-2">
                  <img
                    className="rounded header-profile-user"
                    src="/assets/images/users/avatar-1.jpg"
                    alt="Header Avatar"
                  />
                  <span className="text-start">
                    <span className="d-block fw-medium sidebar-user-name-text">
                      Anna Adame
                    </span>
                    <span className="d-block fs-14 sidebar-user-name-sub-text">
                      <i className="ri ri-circle-fill fs-10 text-success align-baseline"></i>{" "}
                      <span className="align-middle">Online</span>
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome Anna!</h6>
                <Link className="dropdown-item" to="pages-profile.html">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Profile</span>
                </Link>
                <Link className="dropdown-item" to="apps-chat.html">
                  <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Messages</span>
                </Link>
                <Link className="dropdown-item" to="apps-tasks-kanban.html">
                  <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Taskboard</span>
                </Link>
                <Link className="dropdown-item" to="pages-faqs.html">
                  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Help</span>
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="pages-profile.html">
                  <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">
                    Balance : <b>$5971.67</b>
                  </span>
                </Link>
                <Link
                  className="dropdown-item"
                  to="pages-profile-settings.html"
                >
                  <span className="badge bg-success-subtle text-success mt-1 float-end">
                    New
                  </span>
                  <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Settings</span>
                </Link>
                <Link className="dropdown-item" to="auth-lockscreen-basic.html">
                  <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Lock screen</span>
                </Link>
                <LogoutButton />
              </div>
            </div>
            <div id="scrollbar">
              <div className="container-fluid">
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <li className="nav-item">
                    <Link
                      className="nav-link menu-link"
                      to="/realtor/dashboard"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarDashboards"
                    >
                      <i className="ri-dashboard-2-line"></i>{" "}
                      <span data-key="t-dashboards">Dashboard </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link menu-link"
                      to="/realtor/offices"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarDashboards"
                    >
                      <i className="ri-dashboard-2-line"></i>{" "}
                      <span data-key="t-dashboards">Offices </span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link menu-link"
                      to="/realtor/teammembers"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarDashboards"
                    >
                      <i className="ri-dashboard-2-line"></i>{" "}
                      <span data-key="t-dashboards">Team Members </span>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <LogoutButton />
                  </li>
                </ul>
              </div>
            </div>
            <div className="sidebar-background"></div>
          </div>
        </div>
      )}
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default RealtorHeader;
