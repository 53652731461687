import React, { useState, useRef, useEffect, useMemo } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select, { MultiValue } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Profile {
  id: string;
  companyName: string;
  companyWebsite: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  phone2: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  status: string;
  validityDuration: string | null;
  billingCycle: string | null;
  paymentMethod: string;
  licenseType: string;
  brokerageFirmAffiliation: string;
  managingBroker: string;
  teammemberID: string;
  licenseNumber: string;
  licenseExpiryDate: string;
  photo?: string;
  offices: Office[] | null;
  warehouses: Warehouse[] | null;
}

type Warehouse = {
  id: string;
  label: string;
};

type Office = {
  id: string;
  label: string;
};

type SelectOption = {
  value: string;
  label: string;
};

const libraries: Array<"places"> = ["places"];
const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

const TeammemberProfile = () => {
  const token = localStorage.getItem("sessionToken");
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | null>(null); // type-safe
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [address, setAddress] = useState("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState<SelectOption[]>([]);

  const warehousesOptions = useMemo(
    () =>
      warehouses.map((warehouse) => ({
        value: warehouse.id,
        label: warehouse.label,
      })),
    [warehouses]
  );

  // Handle Assign Warehouses Change
  const handleWarehouseChange = (options: MultiValue<SelectOption>) => {
    setSelectedWarehouses(options as SelectOption[]);
    const selectedValues = options.map((option) => option.value);
    formik.setFieldValue("assignedWarehouses", selectedValues);
    console.log(`Assigned Warehouses selected:`, selectedValues);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        console.log(data);

        if (data && data.firmDetails && data.firmDetails.address) {
          setProfile({
            id: data.id || "",
            companyName: data.firmDetails.companyName || "",
            companyWebsite: data.firmDetails.companyWebsite || "",
            jobTitle: data.userType || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            email: data.email || "",
            phone1: data.phone1 || "",
            phone2: data.phone2 || "",
            addressLine1: data.firmDetails.address.addressLine1 || "",
            addressLine2: data.firmDetails.address.addressLine2 || "",
            city: data.firmDetails.address.city || "",
            state: data.firmDetails.address.state || "",
            zip: data.firmDetails.address.zip || "",
            country: data.firmDetails.address.country || "",
            status: data.status || "Active",
            validityDuration: data.licenseValidity || "", // Using data from API
            billingCycle: data.billingCycle || "",
            paymentMethod: data.paymentMethod || "",
            licenseType: data.licenseType || "",
            brokerageFirmAffiliation: data.teammemberDetails.brokerageFirmAffiliation || "",
            managingBroker: data.teammemberDetails.managingBroker || "",
            teammemberID: data.teammemberDetails.teammemberID || "",
            licenseNumber: data.teammemberDetails.licenseNumber || "",
            licenseExpiryDate: data.teammemberDetails.licenseExpiryDate || "",
            offices: data.offices || null,
            warehouses: data.warehouses || null,
          });
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching license data:", error);
      }
    };

    if (!profile) {
      fetchProfile();
    }
  }, [userId, profile, token]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: profile?.companyName || "",
      companyWebsite: profile?.companyWebsite || "",
      jobTitle: profile?.jobTitle || "",
      firstName: profile?.firstName || "",
      lastName: profile?.lastName || "",
      email: profile?.email || "",
      phone1: profile?.phone1 || "",
      phone2: profile?.phone2 || "",
      streetAddress1: profile?.addressLine1 || "",
      streetAddress2: profile?.addressLine1 || "",
      city: profile?.city || "",
      state: profile?.state || "",
      zipcode: profile?.zip || "",
      country: profile?.country || "",
      status: "Active",
      validityDuration: "",
      billingCycle: "",
      paymentMethod: "",
      licenseType: profile?.licenseType || "",
      brokerageFirmAffiliation: profile?.brokerageFirmAffiliation || "",
      managingBroker: profile?.managingBroker || "",
      teammemberID: profile?.teammemberID || "",
      licenseNumber: profile?.licenseNumber || "",
      licenseExpiryDate: profile?.licenseExpiryDate || "",
      photo: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      phone1: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      phone2: Yup.string().matches(
        /^\d{10}$/,
        "Phone number must be exactly 10 digits"
      ),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      companyName: Yup.string()
        .min(2, "Company Name must be at least 2 characters long")
        .max(50, "Company Name cannot be longer than 50 characters"),
      streetAddress1: Yup.string()
        .min(2, "Street Name must be at least 2 characters long")
        .max(50, "Street Name cannot be longer than 50 characters")
        .required("Street Name is required"),
      city: Yup.string()
        .min(2, "City must be at least 2 characters long")
        .max(50, "City cannot be longer than 50 characters"),
      state: Yup.string()
        .min(2, "State must be at least 2 characters long")
        .max(50, "State cannot be longer than 50 characters"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // For debugging
      setFormSubmitting(true);
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${userId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              userType: "teammember",
              firstName: values.firstName,
              lastName: values.lastName,
              phone1: values.phone1,
              phone2: values.phone2,
              jobTitle: values.jobTitle,
              firmDetails: {
                companyName: values.companyName,
                companyWebsite: values.companyWebsite,
                address: {
                  addressLine1: values.streetAddress1,
                  addressLine2: values.streetAddress2,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  zip: values.zipcode,
                  phone: values.phone1,
                },
              },
              licenseType: "AGENT",
              teammemberDetails: {
                  brokerageFirmAffiliation: values.brokerageFirmAffiliation,
                  managingBroker: values.managingBroker,
                  teammemberID: values.teammemberID,
                  licenseNumber: values.licenseNumber,
                  licenseExpiryDate: values.licenseExpiryDate,
              }
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Profile updated successfully!");
        console.log("Form submission successful:", result);
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update Profile.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const addressComponents = place.address_components;

      if (addressComponents) {
        const getComponent = (type: string) => {
          const component = addressComponents.find((c) =>
            c.types.includes(type)
          );
          return component ? component.long_name : "";
        };

        const streetNumber = getComponent("street_number");
        const route = getComponent("route");
        const city =
          getComponent("locality") ||
          getComponent("administrative_area_level_2");
        const state = getComponent("administrative_area_level_1");
        const postalCode = getComponent("postal_code");

        const street = `${streetNumber} ${route}`.trim();

        // Update form values using Formik's setFieldValue
        formik.setFieldValue("streetAddress1", street);
        formik.setFieldValue("city", city);
        formik.setFieldValue("state", state);
        formik.setFieldValue("zipcode", postalCode);
      }
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="dashboard">
      <ToastContainer />
      <h2>Profile </h2>
      <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Job Title/Role{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="jobTitle"
                            name="jobTitle"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                            required
                          >
                            <option value="">- Select -</option>
                            <option value="Agent">Agent</option>
                            <option value="Manager">Manager</option>
                            <option value="Admin">Admin</option>
                            <option value="VP">VP</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Legal">Legal</option>
                            <option value="Custom">Custom</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select role
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone1}
                            required
                          />
                          {formik.touched.phone1 && formik.errors.phone1 ? (
                            <div className="text-danger">
                              {formik.errors.phone1}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Photo/Avatar Upload{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="photo"
                            name="photo"
                            onChange={(event) => {
                              formik.setFieldValue("photo", null); // Handle file selection
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.photo && formik.errors.photo ? (
                            <div className="text-danger">
                              {formik.errors.photo}
                            </div>
                          ) : null}
                        </div>

                       {/* Assign Warehouses */}
                       
                        {/* Permissions/Access Level */}
                     

                       
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  );
};

export default TeammemberProfile;
