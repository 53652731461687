import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select, { MultiValue } from "react-select";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface Plan {
  id: string;
  planName: string;
  description: string;
}

interface PlanGroup {
    id: string;
    groupName: string;
    groupPlans: Plan[];
  }

type SelectOption = {
  value: string;
  label: string;
};
const SuperAdminEditPlanGroup = () => {
  const token = localStorage.getItem('sessionToken');
  const { planGroupId } = useParams<{ planGroupId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [planGroup, setPlanGroup] = useState<PlanGroup | null>(null);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlans, setSelectedPlans] = useState<SelectOption[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchPlans = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/plan-profiles",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };
    fetchPlans();
  }, []);
 
  useEffect(() => {
    if (planGroup && plans.length > 0) {
      const selected = planGroup.groupPlans.map((plan) => {
        const pln = plans.find((p) => p.id === plan.id);
        return pln ? { value: pln.id, label: pln.planName } : null;
      }).filter((option): option is SelectOption => option !== null); // Type predicate
      console.log('selected:',selected);
      setSelectedPlans(selected);
    }
  }, [planGroup, plans]);

  const formik = useFormik({
    initialValues: {
        groupName: planGroupId,
        groupPlans: plans,
    },
    validationSchema: Yup.object({
        groupName: Yup.string()
        .min(2, "Group name must be at least 2 characters long")
        .max(50, "Group name cannot be longer than 50 characters")
        .required("Group name is required"),
        groupPlans: Yup.array()
        // .of(Yup.object())
        .min(1, 'At least one plan must be selected')
        .required('Group plan is required'),
    }),
    onSubmit: async (values) => {
      console.log('Added Plan ids:',values.groupPlans);
      const addedPlanIds = new Array();
      for(const item of values.groupPlans)
      {
        addedPlanIds.push(item);
      }
      
      console.log('addedPlanIds:',addedPlanIds);
      
      const addedPlans = new Array();
      for (const pid of addedPlanIds) {
        console.log(pid); 
        const addedPlan = plans.find((plan) => plan.id === pid);
        addedPlans.push(addedPlan);
        console.log('assignedOffices:', addedPlans);
      }  

      try {
        setLoading(true);
        const response = await fetch(`https://dev.reelty.app/api/v1/plan-groups/${planGroupId}`, {
          method: "PUT", // Use PUT or PATCH for updates
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: planGroupId,
            name: values.groupName,
            planProfile: addedPlans,
          }),
        });

        // console.log(response.body);

        if (!response.ok) {
          throw new Error("Failed to submit form");
        }

        const data = await response.json();
        console.log("Form successfully submitted:", data);
        navigate('/superadmin/profiles', { state: { successMessage: "Plan group updated successfully!" } });
        setLoading(false);
      } catch (error) {
        console.error("Error submitting form:", error);
        setError("Failed to submit the form");
        setLoading(false);
      }
    },
  });

  // Fetch the plan data based on planGroupId
  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`https://dev.reelty.app/api/v1/plan-groups/${planGroupId}`, {
          method: "GET", // Use GET for fetching
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch plan data");
        }

        const data = await response.json();

        // Update formik values with the fetched data
        formik.setValues({
            groupName: data.name || "",
            groupPlans: data.planProfile || "",
        });
        setPlanGroup({
            id: data.id,
            groupName: data.name,
            groupPlans: data.planProfile
        })

        setLoading(false);
      } catch (error) {
        console.error("Error fetching plan group:", error);
        setError("Failed to load the plan group");
        setLoading(false);
      }
    };

    if (planGroupId) {
      fetchPlanData();
    }
  }, [planGroupId]);

const plansOptions =
    plans?.map((plan) => ({
        value: plan.id,
        label: plan.planName,
})) || [];    

// Handle Assign Plans Change
const handlePlansChange = (options: MultiValue<SelectOption>) => {
  setSelectedPlans(options as SelectOption[]);
  const selectedValues = options.map((option) => option.value);
  formik.setFieldValue("groupPlans", selectedValues);
  console.log(`Plan selected:`, selectedValues);
};

  return (
    <div className="roles">
      <div className="card">
        <div className="card-body">
          <h2>Edit Plan Group</h2>

          {loading && <p>Loading...</p>}
          {error && <p className="text-danger">{error}</p>}

          {!loading && !error && (
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          Group Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="groupName"
                          name="groupName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.groupName}
                        />
                        {formik.touched.groupName && formik.errors.groupName ? (
                          <div className="text-danger">
                            {formik.errors.groupName}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="plan-select">Plan</label>

                          <Select
                            id="groupPlans"
                            name="groupPlans"
                            options={plansOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedPlans}
                            onBlur={formik.handleBlur}
                            onChange={handlePlansChange}
                          />

                          {formik.touched.groupPlans &&
                          formik.errors.groupPlans ? (
                            <div className="text-danger">
                              {/* {formik.errors.groupPlans} */}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminEditPlanGroup;
