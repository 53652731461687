// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register{

}
.register h3{
    font-weight: bold;
    text-align: center !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/registrations/SignageRegistration.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,iBAAiB;IACjB,6BAA6B;AACjC","sourcesContent":[".register{\n\n}\n.register h3{\n    font-weight: bold;\n    text-align: center !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
