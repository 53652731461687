import { useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface License {
    id: string;    
    firstName: string;
    lastName: string;    
    statuss: string;
    licenseType: string;
    offices: string[] | null;
}

  type Office = {
    id: number;
    officeName: string;
    officeType: string;  
    officeAddress: OfficeAddress;
  };

  type OfficeAddress = {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };

const TeammemberOffices = () => {
  const [offices, setOffices] = useState<Office[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const [license, setLicense] = useState<License | null>(null);

  useEffect(() => {
    const fetchLicense = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch license data");
        }

        const data = await response.json();
        console.log("Fetched License Data:", data);

        if (data) {
          const fetchedLicense: License = {
            id: data.id || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            statuss: data.status || "Active",
            licenseType: data.licenseType || "", 
            offices: data.offices || null,            
          };

          setLicense(fetchedLicense); 
          setOffices(data.offices);             
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching license data:", error);
        setError("Failed to fetch license data.");
      } finally {
        setLoading(false);
      }
    };

    if (!license) {
      fetchLicense();
    }
  }, [userId, license, token]);

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Offices </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Office Name</th>
                          <th>Office Type</th>
                          <th>City</th>
                          <th>State</th>
                        </tr>
                      </thead>
                      <tbody>
                        {offices != null &&
                          offices.map((office, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>
                              <td>                                
                                <Link
                                  to={`/teammember/office/${office.id}`}
                                  className="text-primary waves-effect waves-light me-2"
                                >
                                  {office.officeName}
                                </Link>
                                </td>
                              <td>{office.officeType}</td>
                              <td>{office.officeAddress.city}</td>
                              <td>{office.officeAddress.state}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeammemberOffices;
