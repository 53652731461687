// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-lg{
    color: #ffffff;
}
.logo-lg h2{
    color: #ffffff !important;
    padding: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/custom.min.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,aAAa;AACjB","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
