import React, { useState, useRef, useEffect } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

type Warehouse = {
  id: string;
  label: string;
  addressLine1: string;
  // addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  status: string;
  created_at: string;
};

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

const SignageEditWarehouse = () => {
  const { warehouseId } = useParams<{ warehouseId: string }>();
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    const fetchWarehouse = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/warehouses/${warehouseId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch warehouse data");
        }
        const data = await response.json();
        setWarehouse({
          id: data.id || "",
          label: data.label || "",
          addressLine1: data.address.addressLine1 || "",
          city: data.address.city || "",
          state: data.address.state || "",
          zip: data.address.zip || "",
          country: data.address.country || "",
          status: (data.status)?"Active":"Inactive",
          created_at: data.created_at || "",
        });
      } catch (error) {
        setError("Error fetching warehouse data. Please try again later.");
        console.error("Error fetching warehouse data:", error);
      }
    };

    if (warehouseId) {
      fetchWarehouse();
    }
  }, [warehouseId, token]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      warehouseName: warehouse?.label || '',      
      streetAddress1: warehouse?.addressLine1 || '',
      // streetAddress2: warehouse?.address.addressLine2 || '',
      city: warehouse?.city || '',
      state: warehouse?.state || '',      
      zipcode: warehouse?.zip || '',
      // country: warehouse?.address.country || '',
      assignedTeammember: '',
      statuss: warehouse?.status || '',
    },
    validationSchema: Yup.object({
      warehouseName: Yup.string()
        .min(2, 'Warehouse name must be at least 2 characters long')
        .max(50, 'Warehouse name cannot be longer than 50 characters')
        .required('Warehouse Name is required'),
      streetAddress1: Yup.string()
        .min(2, 'Street address must be at least 8 characters long')
        .max(50, 'Street address must be max 16 characters long') 
        .required('Street address is required'),
      city: Yup.string()
        .min(2, 'City must be at least 2 characters long')
        .max(50, 'City cannot be longer than 50 characters')
        .required('City is required'),
      state: Yup.string()
        .min(2, 'State must be at least 2 characters long')
        .max(50, 'State cannot be longer than 50 characters')
        .required('State is required'),     
      zipcode: Yup.string()
        .required('Zip code is required'),   
      country: Yup.string()
        .min(2, 'Country must be at least 2 characters long')
        .max(50, 'Country cannot be longer than 50 characters'),   
      // assignedTeammember: Yup.string().required('Please select a Team Member'),
      statuss: Yup.string().required('Please select status'),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      const warehouseGroup = (values.statuss=='Active')?true:false;

      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/warehouses/${warehouseId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              label: values.warehouseName,
              address: {
                  addressLine1: values.streetAddress1,
                  city: values.city,
                  state: values.state,
                  zip: values.zipcode              
              },
              status: warehouseGroup
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("Warehouse updated successfully!");
        navigate("/signage/warehouses", {
          state: { successMessage: "Warehouse updated successfully!" },
        });
      } catch (err) {
        toast.error("An error occurred while updating the warehouse.");
        console.error(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setFormSubmitting(false);
      }
    },
  });  

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const addressComponents = place.address_components;

      if (addressComponents) {
        const getComponent = (type: string) => {
          const component = addressComponents.find(c => c.types.includes(type));
          return component ? component.long_name : '';
        };

        const street = `${getComponent('street_number')} ${getComponent('route')}`.trim();
        formik.setFieldValue('streetAddress1', street);
        formik.setFieldValue('city', getComponent('locality') || getComponent('administrative_area_level_2'));
        formik.setFieldValue('state', getComponent('administrative_area_level_1'));
        formik.setFieldValue('zipcode', getComponent('postal_code'));
        formik.setFieldValue('country', getComponent('country'));
      }
    }
  };

  if (error) return <div>{error}</div>;
  if (!isLoaded || !warehouse) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Edit Warehouse </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">                    
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Warehouse Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="warehouseName"
                          name="warehouseName"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.warehouseName}                          
                        />
                        {formik.touched.warehouseName && formik.errors.warehouseName ? (
                          <div className="text-danger">{formik.errors.warehouseName}</div>
                        ) : null}
                      </div>
                      
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Street <span className="text-danger">*</span></label>
                        <Autocomplete
                          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                          onPlaceChanged={handlePlaceChanged}
                        >
                          <input
                            type="text"
                            className="form-control"
                            id="streetAddress1"
                            name="streetAddress1"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.streetAddress1}                            
                          />
                        </Autocomplete>
                        {formik.touched.streetAddress1 && formik.errors.streetAddress1 ? (
                          <div className="text-danger">{formik.errors.streetAddress1}</div>
                        ) : null}
                      </div>
                      {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Street Address 2{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress2"
                              name="streetAddress2"
                              placeholder="Enter Address line 2"
                            />
                            <div className="invalid-feedback">
                              Please enter Address line 2
                            </div>
                          </div> */}
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              City <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}                              
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <div className="text-danger">{formik.errors.city}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              State <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}                              
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <div className="text-danger">{formik.errors.state}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Zip code <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipcode"
                              name="zipcode"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.zipcode}                              
                            />
                            {formik.touched.zipcode && formik.errors.zipcode ? (
                              <div className="text-danger">{formik.errors.zipcode}</div>
                            ) : null}
                          </div>                         
                          
                          {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Country 
                            </label>                            
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              name="country"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.country}                              
                            />                           
                            {formik.touched.country && formik.errors.country ? (
                              <div className="text-danger">{formik.errors.country}</div>
                            ) : null}
                          </div> */}
                          {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Assign Team Member{" "}  <span className="text-danger">*</span>
                            </label>
                            <select className="form-control" id="assignedTeammember" name="assignedTeammember">
                              <option value="">- Select -</option>
                              <option value="Team Member 1">Team Member 1</option>
                              <option value="Team Member 2">Team Member 2</option>
                              <option value="Team Member 3">Team Member 3</option>
                            </select>
                            <div className="invalid-feedback">
                              Please select Team Member
                            </div>
                          </div> */}
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Status{" "} <span className="text-danger">*</span>
                            </label>
                            <select className="form-control" id="statuss" name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}>
                              <option value="">- Select -</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                            {formik.touched.statuss && formik.errors.statuss ? (
                              <div className="text-danger">{formik.errors.statuss}</div>
                            ) : null}
                          </div> 
                    </div>
                    <button className="btn btn-success w-100" type="submit">Submit</button>
                  </form>
                </div>
              </div>
            </div>                
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignageEditWarehouse;
