import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; 
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./RealtorViewGroup.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Agent = {
  id: string;
  name: string;
  email: string;
  phone: string;
  city: string;
};

type Group = {
  id: number;
  name: string;  
  status: string;
  agents: Agent[];
};

const RealtorViewGroup = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const token = localStorage.getItem("sessionToken");
  const [group, setGroup] = useState<Group | null>(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState<string>("");

  const location = useLocation();
  useEffect(() => {
    if (location.state?.successMessage) {
      toast.success(location.state.successMessage);
    }
  }, [location.state]);
  
  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/groups/${groupId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setGroup({
          id: data.id,
          name: data.name,
          status: data.status,
          agents: data.agents,
        });
      } catch (error) {
        console.error("Error fetching group data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (groupId) {
      fetchGroupData();
    }
  }, [groupId, token]);

  const handleDeleteAgent = async (groupId: number, agentId: string) => {
    setLoading(true);
    try {
      await fetch(`https://dev.reelty.app/api/v1/groups/${groupId}/remove-agent/${agentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (group) {
        setGroup({
          ...group,
          agents: group.agents.filter((agent) => agent.id !== agentId),
        });
      }
      toast.success("Agent deleted successfully.");
    } catch (err) {
      toast.error("Failed to delete agent.");
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading Group Data...</div>;
  if (!group) return <div>Group data not found.</div>;

  return (
    <div className="teammember">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>{group.name} ({group.agents.length} agents)</h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th colSpan={4}>Group Information </th>
                      </tr>
                      <tr>
                        <th>Group Name: </th>
                        <td>{group.name}</td>
                        <th>Status: </th>
                        <td>{group.status === "active" ? "Active" : "Inactive"}</td>
                      </tr>  
                      <tr>
                        <th>Agents</th>
                        <td colSpan={3}>
                          {group.agents.map((agent) => (
                            <div key={agent.id} className="d-flex justify-content-between mb-3 border-bottom">
                              <p>{agent.name} ({agent.email})</p>
                              <button
                                onClick={() =>
                                  window.confirm("Are you sure you want to delete this agent?") &&
                                  handleDeleteAgent(group.id, agent.id)
                                }
                                className="btn btn-danger waves-effect waves-light"
                              >
                                <i className="ri-delete-bin-fill align-bottom me-2"></i>
                              </button>
                            </div>
                          ))}
                        </td>
                      </tr>                    
                    </tbody>
                  </table>  
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorViewGroup;
