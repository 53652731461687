import { useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type TempObject = {
  agent_id: string;
  products: AgentProduct[];
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  product_id: string;
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  linked_mode: string;
  linked_installer: Installer;
  notes: string;
  image: Image | null;
  status: string;
};

interface Installer {
  id: string;    
  name: string;
}

type AgentProduct = {
  id: string;
  agent_id: string;
  products: Product;
};

type Image = {
  url: string;
};

interface Installer {
  id: string;    
  firstName: string;
  lastName: string;    
  statuss: string;
  licenseType: string;
  firmDetails: FirmDetails;
}

interface FirmDetails {
  companyID: string;    
  companyName: string;
}

const AgentInventory = () => {
  const [tempObject, setTempObject] = useState<TempObject | null>(null);
  const [agentproducts, setAgentProducts] = useState<AgentProduct[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [installers, setInstallers] = useState<Installer[]>([]);
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/agent/${userId}/inventory/products`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        // console.log("Fetched data:", data);
        setTempObject(data);
        // Ensure the response contains the expected structure
        if (data && Array.isArray(data.products)) {
          setAgentProducts(data.products);
        } else {
          console.error("Unexpected API response structure:", data);
          setAgentProducts([]);
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token, userId]);

  useEffect(() => {
    if (agentproducts.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agentproducts]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/users?userType=signage_firm",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("Failed to fetch installers data");
        }
  
        const data = await response.json();
        console.log("Fetched installers Data:", data);
  
        if (Array.isArray(data)) {
          setInstallers(data);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching installers data:", error);
        setError("Failed to fetch installers data.");
      } finally {
        setLoading(false);
      }
    };
  
    // Only fetch if the installers array is empty
    if (!installers) {
      fetchInstallers();
    }
  }, [token]); // Remove installers from dependencies
  
  

  const handleDelete = async (productId: string) => {
    setDeleting(productId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/agent/${userId}/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the tax group.");
      }

      toast.success("Product deleted successfully!");
      // Update the state by removing the deleted license
      setProducts((prev) =>
        prev.filter((product) => product.product_id !== productId)
      );
      setDeleting(null); // Clear the deleting state
    } catch (err) {
      toast.error("Failed to delete the tax group.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Inventory </h2>
          <div className="text-end">
            <a href="/agent/addProduct" className="btn btn-primary float-right">
              Add Inventory Item
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          {/* <th>
                            <input type="checkbox" />
                          </th> */}
                          <th>S.No.</th>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Category</th>
                          {/* <th>Qty</th> */}
                          <th>Status</th>
                          <th>Installer Status</th>
                          <th>Condition</th>
                          <th>Dimensions</th>
                          <th>Weight</th>
                          <th>Linked Installer</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                {agentproducts.map((agentproduct, index) => (
                  <tr key={agentproduct.products.product_id}>
                    {/* <td><input type="checkbox" /></td> */}
                    <td>{index + 1}</td>
                    <td>
                      {agentproduct.products.image?.url ? (
                        <img
                          src={`https://dev.reelty.app/${agentproduct.products.image.url}`}
                          alt="Product"
                          height={100}
                        />
                      ) : (
                        "No Image"
                      )}
                    </td>
                    <td>{agentproduct.products.name}</td>
                    <td>{agentproduct.products.category}</td>
                    <td>{agentproduct.products.status}</td>
                    <td>{agentproduct.products.linked_mode}</td>
                    <td>{agentproduct.products.item_condition}</td>
                    <td>
                      {agentproduct.products.package_dimensions?.length} x{" "}
                      {agentproduct.products.package_dimensions?.height} x{" "}
                      {agentproduct.products.package_dimensions?.width}
                    </td>
                    <td>{agentproduct.products.package_dimensions?.weight}</td>
                    <td>{agentproduct.products.linked_installer?.name || "None"}</td>
                    <td>
                      <Link
                        to={`/agent/editProduct/${agentproduct.products.product_id}`}
                        className="btn btn-warning me-2"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() =>
                          window.confirm("Delete this product?") &&
                          handleDelete(agentproduct.products.product_id)
                        }
                        className="btn btn-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentInventory;
