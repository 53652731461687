import React, { useState } from 'react';

interface TabsProps {
  children: React.ReactNode[];
}

const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <ul className="nav nav-pills nav-success mb-3" role="tablist">
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
            Plan Profiles
          </button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
            Permissions
          </button>
        </li>
        <li className="nav-item">
          <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>
            Plan Profile Groups
          </button>
        </li>
      </ul>
      <div className="tab-content">
        {children[activeTab]}
      </div>
    </>
  );
};

export default Tabs;
