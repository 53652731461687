import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";

type FileWithPreview = {
  file: File;
  preview: string;
};


const TeammemberAddProduct = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    const mappedFiles = acceptedFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setFiles((prevFiles) => [...prevFiles, ...mappedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    // accept: "image/*",
    onDrop,
    multiple: true,
  });

  useEffect(() => {
    return () => {
      files.forEach(({ preview }) => URL.revokeObjectURL(preview));
    };
  }, [files]);

  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  // const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      description: "",
      quantity: "",
      condition: "",
      length: "",
      height: "",
      width: "",
      weight: "",
      images: "",
      statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      const groupStatus = values.statuss == "Active" ? true : false;

      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/tax-groups",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              status: groupStatus,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("GeoLocation added successfully!");
        // console.log("Form submission successful:", result);
        navigate("/signage/taxGroups", {
          state: { successMessage: "Tax group added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add GeoLocation.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Add Inventory Item </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-1 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Category
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category}
                              />
                              {formik.touched.category &&
                              formik.errors.category ? (
                                <div className="text-danger">
                                  {formik.errors.category}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description/Notes{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Quantity <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="quantity"
                                name="quantity"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.quantity}
                              />
                              {formik.touched.quantity &&
                              formik.errors.quantity ? (
                                <div className="text-danger">
                                  {formik.errors.quantity}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-4 mb-3">
                              <label className="form-label">Condition</label>
                              <select
                                className="form-control"
                                id="condition"
                                name="condition"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.condition}
                              >
                                <option value="new">New</option>
                                <option value="used">Used</option>
                                <option value="damaged">Damaged</option>
                              </select>
                              {formik.touched.condition &&
                              formik.errors.condition ? (
                                <div className="text-danger">
                                  {formik.errors.condition}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="text-danger">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Dimensions/Measurements (Inches)
                              </label>
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="length"
                                    name="length"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.length}
                                    placeholder="Length"
                                  />
                                  {formik.touched.length &&
                                  formik.errors.length ? (
                                    <div className="text-danger">
                                      {formik.errors.length}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.height}
                                    placeholder="Height"
                                  />
                                  {formik.touched.height &&
                                  formik.errors.height ? (
                                    <div className="text-danger">
                                      {formik.errors.height}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="width"
                                    name="width"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.width}
                                    placeholder="Width"
                                  />
                                  {formik.touched.width &&
                                  formik.errors.width ? (
                                    <div className="text-danger">
                                      {formik.errors.width}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Weight(in gms)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="weight"
                                name="weight"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weight}
                              />
                              {formik.touched.weight && formik.errors.weight ? (
                                <div className="text-danger">
                                  {formik.errors.weight}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="form-label">
                                Installer <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Notes for the Installer{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              ></textarea>
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            
                          </div>
                        </div>
                        <div className="col-md-4">
                        <label className="form-label">Product Images</label>
                          {/* Dropzone */}
                          <div
                            {...getRootProps()}
                            style={{
                              border: "2px dashed #ccc",
                              padding: "100px 20px",
                              borderRadius: "5px",
                              textAlign: "center",
                            }}
                          >
                            <input {...getInputProps()} />
                            <p>
                              Drag & drop your images here,
                              <br />or click to select files
                            </p>
                          </div>

                          {/* Preview Images */}
                          <div
                            style={{
                              display: "flex",
                              marginTop: "10px",
                              gap: "10px",
                            }}
                          >
                            {files.map(({ preview, file }, index) => (
                              <div key={index} style={{ position: "relative" }}>
                                <img
                                  src={preview}
                                  alt={file.name}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                                <p style={{ textAlign: "center" }}>
                                  {file.name}
                                </p>
                              </div>
                            ))}
                          </div>
                          {/* <div className="col-md-12 mb-3">
                            <label className="form-label">
                              Images <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="images"
                              name="images"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.images}
                            />
                            {formik.touched.images && formik.errors.images ? (
                              <div className="text-danger">
                                {formik.errors.images}
                              </div>
                            ) : null}
                          </div> */}
                        </div>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Save Inventory Item
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeammemberAddProduct;
