import React from 'react';
import { Outlet, Link } from 'react-router-dom';  // Import Outlet and Link from react-router-dom

import '../assets/css/bootstrap.min.css';
import '../assets/css/icons.min.css';
import '../assets/css/app.min.css';
import '../assets/css/custom.min.css';
import './SignageLayout.css';

import SignageHeader from '../components/SignageHeader';
import SignageSidebar from '../components/SignageSidebar';
import DashboardFooter from '../components/DashboardFooter';

interface LayoutProps {
    children?: React.ReactNode;  // Make children optional as we are using Outlet
}

const SignageLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div id="layout-wrapper">
      {/* Header component */}
      <SignageHeader />
      
      {/* Sidebar component */}
      <SignageSidebar />

      {/* Main content area */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
           
            {/* Render children or Outlet (child routes) */}
            {children ? children : <Outlet />}  {/* Render children if passed, else Outlet */}
          </div>
        </div>

        {/* Footer component */}
        <DashboardFooter />
      </div>
    </div>
  );
};

export default SignageLayout;
