import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/icons.min.css';
import '../../assets/css/app.css';
import '../../assets/css/custom.min.css';

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

const AgentRegistration = ()=>{

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });

  const [address, setAddress] = useState('');
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      phone2: '',
      email: '',
      password: '',
      cpassword: '',
      company_name: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'First Name can only contain letters')
        .min(2, 'First Name must be at least 2 characters long')
        .max(50, 'First Name cannot be longer than 50 characters')
        .required('First Name is required'),
      lastname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Last Name can only contain letters')
        .min(2, 'Last Name must be at least 2 characters long')
        .max(50, 'Last Name cannot be longer than 50 characters')
        .required('Last Name is required'),
      phone: Yup.string()
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number is required'),
      phone2: Yup.string()
        .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
      email: Yup.string()        
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string()
        .min(8, 'Password must be at least 8 characters long')
        .max(16, 'Password must be max 16 characters long')
        .required('Password is required'),
      cpassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'Passwords must match')
        .required('Confirm Password is required'),
      company_name: Yup.string()
        .min(2, 'Company Name must be at least 2 characters long')
        .max(50, 'Company Name cannot be longer than 50 characters')
        .required('Company Name is required'),
      street: Yup.string()
        .min(2, 'Street Name must be at least 2 characters long')
        .max(50, 'Street Name cannot be longer than 50 characters')
        .required('Street Name is required'),
      city: Yup.string()
        .min(2, 'City must be at least 2 characters long')
        .max(50, 'City cannot be longer than 50 characters')
        .required('City is required'),
      state: Yup.string()
        .min(2, 'State must be at least 2 characters long')
        .max(50, 'State cannot be longer than 50 characters')
        .required('State is required'),
      zipcode: Yup.string()
        .required('Zipcode is required'),
    }),
    onSubmit: (values) => {
      console.log('Form values:', values);
    },
  });

  // const handlePlaceChanged = () => {
  //   if (autocompleteRef.current) {
  //     const place = autocompleteRef.current.getPlace();
  //     setAddress(place.formatted_address || '');
  //     formik.setFieldValue('street', place.formatted_address || '');
  //   }
  // };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
  
      const addressComponents = place.address_components;
  
      if (addressComponents) {
        // Helper function to get specific address component
        const getComponent = (type: string) => {
          const component = addressComponents.find(c => c.types.includes(type));
          return component ? component.long_name : '';
        };
  
        const streetNumber = getComponent('street_number');
        const route = getComponent('route');
        const city = getComponent('locality') || getComponent('administrative_area_level_2');
        const state = getComponent('administrative_area_level_1');
        const postalCode = getComponent('postal_code');
  
        const street = `${streetNumber} ${route}`.trim();
  
        // Update form values
        formik.setFieldValue('street', street);
        formik.setFieldValue('city', city);
        formik.setFieldValue('state', state);
        formik.setFieldValue('zipcode', postalCode);
      }
    }
  };
  

  if (!isLoaded) return <div>Loading...</div>;

    return(
        <div className="register">
            <div className="auth-page-wrapper pt-5">
        
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div className="bg-overlay"></div>

            <div className="shape">
                
            </div>
        </div>

        
        <div className="auth-page-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <Link to="/" className="d-inline-block auth-logo">
                                    <img src="assets/images/logo-light.png" alt="" height="20" />
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
               

                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-10">
                        <div className="card mt-4 card-bg-fill">

                            <div className="card-body p-4">
                                <div className="text-center mt-2">
                                    <h2 className="text-primary">Real Estate Agent Registration</h2>
                                    <p className="text-muted">User Details (Primary Contact)</p>
                                </div>
                                <div className="p-2 mt-4">
                                <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              name="firstname"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstname}
                              required
                            />
                            {formik.touched.firstname && formik.errors.firstname ? (
                              <div className="text-danger">{formik.errors.firstname}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              name="lastname"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastname}
                              required
                            />
                            {formik.touched.lastname && formik.errors.lastname ? (
                              <div className="text-danger">{formik.errors.lastname}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Phone <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              name="phone"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.phone}
                              required
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                              <div className="text-danger">{formik.errors.phone}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Secondary Phone Number{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone2"
                              name="phone2"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.phone2}
                            />
                            {formik.touched.phone2 && formik.errors.phone2 ? (
                              <div className="text-danger">{formik.errors.phone2}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Email <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              required
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="text-danger">{formik.errors.email}</div>
                            ) : null}
                          </div>

                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Password <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative auth-pass-inputgroup">
                            <input
                                type="password"
                                className="form-control pe-5 password-input"
                                id="password"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                aria-describedby="password"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                required
                              />
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                type="button"
                                id="password-addon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                              {formik.touched.password && formik.errors.password ? (
                              <div className="text-danger">{formik.errors.password}</div>
                            ) : null}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="position-relative auth-pass-inputgroup">
                            <input
                                type="password"
                                className="form-control pe-5 password-input"
                                id="cpassword"
                                name="cpassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cpassword}
                                aria-describedby="cpassword"
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                required
                              />
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                type="button"
                                id="password-addon"
                              >
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                              {formik.touched.cpassword && formik.errors.cpassword ? (
                              <div className="text-danger">{formik.errors.cpassword}</div>
                            ) : null}
                            </div>
                          </div>
                        </div>
                        <h3>Professional Details </h3>
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                            Brokerage Firm Affiliation{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder="Enter Brokerage Firm Affiliation"
                            />
                            <div className="invalid-feedback">
                              Please enter Brokerage Firm Affiliation
                            </div>
                          </div>
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Managing Broker{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="managingBroker"
                              placeholder="Enter Managing Broker"
                            />
                            <div className="invalid-feedback">
                              Please enter Managing Broker
                            </div>
                          </div>

                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Agent ID/Code{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder="Enter Agent ID/Code"
                            />
                            <div className="invalid-feedback">
                              Please enter Agent ID/Code
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            License Number{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="licenseNumber"
                              placeholder="Enter License Number"
                            />
                            <div className="invalid-feedback">
                              Please enter License Number
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            License Expiry Date{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="licenseExpiryDate"
                              placeholder="Enter License Expiry Date"
                            />
                            <div className="invalid-feedback">
                              Please enter License Expiry Date
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Street Name <span className="text-danger">*</span>
                            </label>
                            <Autocomplete
                              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                              onPlaceChanged={handlePlaceChanged}
                            >
                            <input
                              type="text"
                              className="form-control"
                              id="street"
                              name="street"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.street}
                              required
                            />
                            </Autocomplete>
                            {formik.touched.street && formik.errors.street ? (
                              <div className="text-danger">{formik.errors.street}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Address line 2{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              placeholder="Enter Address line 2"
                            />
                            <div className="invalid-feedback">
                              Please enter Address line 2
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              City 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <div className="text-danger">{formik.errors.city}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              State 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <div className="text-danger">{formik.errors.state}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Zip code 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipcode"
                              name="zipcode"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.zipcode}
                            />
                            {formik.touched.zipcode && formik.errors.zipcode ? (
                              <div className="text-danger">{formik.errors.zipcode}</div>
                            ) : null}
                          </div>
                        </div>

                        
                        <div className="mb-4">
                          <p className="mb-0 fs-12 fst-italic">
                            <input type="checkbox" /> Would you like to receive
                            updates and promotions?
                          </p>
                        </div>
                        <div className="mb-4">
                          <p className="mb-0 fs-12 text-muted fst-italic">
                            By registering you agree to the{" "}
                            <a
                              href="#"
                              className="text-primary text-decoration-underline fst-normal fw-medium"
                            >
                              Terms of Use
                            </a>
                          </p>
                        </div>                       

                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Sign Up
                          </button>
                        </div>

                        
                      </form>

                                </div>
                            </div>
                            
                        </div>
                        

                        <div className="mt-4 text-center">
                            <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link> </p>
                        </div>

                    </div>
                </div>
                
            </div>
            
        </div>       
        
        
    </div>
        </div>
    );
}

export default AgentRegistration;