import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const RealtorAddGroup = ()=>{
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      groupName: '',
      statuss: '',
    },
    validationSchema: Yup.object({
      groupName: Yup.string()
        .min(2, 'Group name must be at least 2 characters long')
        .max(50, 'Group name cannot be longer than 50 characters')
        .required('Group Name is required'),
      statuss: Yup.string()        
        .required('Status is required'), 
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/groups",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.groupName || "",
              status: true,
              agents: [],
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("Group added successfully!");
        console.log("Form submission successful:", result);
        navigate("/realtor/groups", {
          state: { successMessage: "Group added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Group.");
      } finally {
        setFormSubmitting(false);
      }
    },
  }); 

    return(
        <div className="teammember">
            <div className="card">
                <div className="card-body">
                    <h2>Add Group </h2>
                    
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">                    
                    <div className="p-2 mt-4">
                      <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Group Name <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="groupName"
                              name="groupName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.groupName}
                              required
                            />
                            {formik.touched.groupName && formik.errors.groupName ? (
                              <div className="text-danger">{formik.errors.groupName}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                          >
                            <option value="">- Select -</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select status
                          </div>
                        </div>
                        </div>                        
                        
                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>                        
                      </form>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
                </div>
                
            </div>
        </div>
    );
}

export default RealtorAddGroup;