import React, { useState, useRef } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

interface AgentDetails {
  brokerageFirmAffiliation: string;
  managingBroker: string;
  agentID: string;
  licenseNumber: string;
  licenseExpiryDate: string;
};

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Agent {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  email: string;
  phone1: string;
  phone2: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
  agentDetails: AgentDetails;
}

const RealtorAddAgent = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });

  const [address, setAddress] = useState("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone1: "",
      phone2: "",
      email: "",
      password: "", 
      brokerageFirmAffiliation:"",
      agentID:"",     
      licenseNumber: "",
      licenseExpiryDate: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipcode: "",
      statuss: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      phone1: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      phone2: Yup.string().matches(
        /^\d{10}$/,
        "Phone number must be exactly 10 digits"
      ),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(16, "Password must be max 16 characters long")
        .required("Password is required"),
      // company_name: Yup.string()
      //   .min(2, "Company Name must be at least 2 characters long")
      //   .max(50, "Company Name cannot be longer than 50 characters")
      //   .required("Company Name is required"),
      addressLine1: Yup.string()
        .min(2, "Street Name must be at least 2 characters long")
        .max(50, "Street Name cannot be longer than 50 characters")
        .required("Street Name is required"),
      city: Yup.string()
        .min(2, "City must be at least 2 characters long")
        .max(50, "City cannot be longer than 50 characters")
        .required("City is required"),
      state: Yup.string()
        .min(2, "State must be at least 2 characters long")
        .max(50, "State cannot be longer than 50 characters")
        .required("State is required"),
      zipcode: Yup.string().required("Zipcode is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/user/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              password: values.password,
              userType: "agent",
              firstName: values.firstName,
              lastName: values.lastName,
              phone1: values.phone1,
              phone2: values.phone2,
              licenseType: "AGENT",
              status: values.statuss,
              firmDetails: {
                companyID: "",
                companyName: "",
                companyWebsite: "",
                address: {
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    city: values.city,
                    state: values.state,
                    country: "US",
                    zip: values.zipcode,
                    cityCode: "",
                    stateCode: "",
                    countryCode: "",
                    latitude: "",
                    longitude: "",
                    phone: values.phone1,
                },
              },
              agentDetails: {
                  brokerageFirmAffiliation: values.phone1,
                  // managingBroker: values.managingBroker,
                  agentID: values.agentID,
                  licenseNumber: values.licenseNumber,
                  licenseExpiryDate: values.licenseExpiryDate,
              },
          }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Agent added successfully!");
        console.log("Form submission successful:", result);
        navigate("/realtor/agents", {
          state: { successMessage: "Agent added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Agent.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();

      const addressComponents = place.address_components;

      if (addressComponents) {
        // Helper function to get specific address component
        const getComponent = (type: string) => {
          const component = addressComponents.find((c) =>
            c.types.includes(type)
          );
          return component ? component.long_name : "";
        };

        const streetNumber = getComponent("street_number");
        const route = getComponent("route");
        const city =
          getComponent("locality") ||
          getComponent("administrative_area_level_2");
        const state = getComponent("administrative_area_level_1");
        const postalCode = getComponent("postal_code");

        const street = `${streetNumber} ${route}`.trim();

        // Update form values
        formik.setFieldValue("addressLine1", street);
        formik.setFieldValue("city", city);
        formik.setFieldValue("state", state);
        formik.setFieldValue("zipcode", postalCode);
      }
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Add Agent </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone1"
                            name="phone1"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone1}
                            required
                          />
                          {formik.touched.phone1 && formik.errors.phone1 ? (
                            <div className="text-danger">
                              {formik.errors.phone1}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Secondary Phone Number{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone2"
                            name="phone2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone2}
                          />
                          {formik.touched.phone2 && formik.errors.phone2 ? (
                            <div className="text-danger">
                              {formik.errors.phone2}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            required
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative auth-pass-inputgroup">
                            <input
                              type="password"
                              className="form-control pe-5 password-input"
                              id="password"
                              name="password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                              aria-describedby="password"
                              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              required
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div className="text-danger">
                                {formik.errors.password}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <h3>Professional Details </h3>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Brokerage Firm Affiliation{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="brokerageFirmAffiliation"
                            name="brokerageFirmAffiliation"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.brokerageFirmAffiliation}
                              aria-describedby="brokerageFirmAffiliation"
                            placeholder="Enter Brokerage Firm Affiliation"
                          />
                          <div className="invalid-feedback">
                            Please enter Brokerage Firm Affiliation
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">Agent ID/Code </label>
                          <input
                            type="text"
                            className="form-control"
                            id="agentID"
                            name="agentID"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.agentID}
                            aria-describedby="agentID"
                            placeholder="Enter Agent ID/Code"
                          />
                          <div className="invalid-feedback">
                            Please enter Agent ID/Code
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">License Number </label>
                          <input
                            type="text"
                            className="form-control"
                            id="licenseNumber"
                            name="licenseNumber"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.licenseNumber}
                            aria-describedby="licenseNumber"
                            placeholder="Enter license number"
                          />
                          {formik.touched.licenseNumber && formik.errors.licenseNumber ? (
                            <div className="text-danger">
                              {formik.errors.licenseNumber}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">License Expiry Date </label>
                          <input
                            type="date"
                            className="form-control"
                            id="licenseExpiryDate"
                            name="licenseExpiryDate"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.licenseExpiryDate}
                            aria-describedby="licenseExpiryDate"
                            placeholder="Enter license expiry date"
                          />
                          {formik.touched.licenseExpiryDate && formik.errors.licenseExpiryDate ? (
                            <div className="text-danger">
                              {formik.errors.licenseExpiryDate}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Street Name <span className="text-danger">*</span>
                          </label>
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (autocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="addressLine1"
                              name="addressLine1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.addressLine1}
                              required
                            />
                          </Autocomplete>
                          {formik.touched.addressLine1 && formik.errors.addressLine1 ? (
                            <div className="text-danger">
                              {formik.errors.addressLine1}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Address line 2 </label>
                            <input
                              type="text"
                              className="form-control"
                              id="addressLine2"
                              name="addressLine2"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.addressLine2}                              
                            />
                          {formik.touched.addressLine2 && formik.errors.addressLine2 ? (
                            <div className="text-danger">
                              {formik.errors.addressLine2}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">City</label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger">
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">State</label>
                          <input
                            type="text"
                            className="form-control"
                            id="state"
                            name="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className="text-danger">
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Zip code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="zipcode"
                            name="zipcode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zipcode}
                          />
                          {formik.touched.zipcode && formik.errors.zipcode ? (
                            <div className="text-danger">
                              {formik.errors.zipcode}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                          >
                            <option value="">- Select -</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          {formik.touched.statuss && formik.errors.statuss ? (
                            <div className="text-danger">
                              {formik.errors.statuss}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorAddAgent;
