import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

// Auth Pages
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import TestApi from "./pages/auth/TestApi";

// Registration Pages
import SignageRegistration from "./pages/registrations/SignageRegistration";
import RealtorRegistration from "./pages/registrations/RealtorRegistration";
import AgentRegistration from "./pages/registrations/AgentRegistration";
import InstallerRegistration from "./pages/registrations/InstallerRegistration";

// Signage Dashboard and Layout
import SignageDashboard from "./pages/signage/SignageDashboard";
import SignageLayout from "./layouts/SignageLayout";

// Realtor Layout and Pages
import RealtorLayout from "./layouts/RealtorLayout";
import RealtorDashboard from "./pages/realtor/RealtorDashboard";
import RealtorTeammembers from "./pages/realtor/RealtorTeammembers";
import RealtorAddTeammember from "./pages/realtor/RealtorAddTeammember";
import RealtorViewTeammember from "./pages/realtor/RealtorViewTeammember";
import RealtorEditTeammember from "./pages/realtor/RealtorEditTeammember";
import RealtorOffices from "./pages/realtor/RealtorOffices";
import RealtorAddOffice from "./pages/realtor/RealtorAddOffice";
import RealtorViewOffice from "./pages/realtor/RealtorViewOffice";
import RealtorEditOffice from "./pages/realtor/RealtorEditOffice";
import RealtorAgents from "./pages/realtor/RealtorAgents";
import RealtorAddAgent from "./pages/realtor/RealtorAddAgent";
import RealtorViewAgent from "./pages/realtor/RealtorViewAgent";
import RealtorEditAgent from "./pages/realtor/RealtorEditAgent";
import RealtorSuppliers from "./pages/realtor/RealtorSuppliers";

// Super Admin Layout and Pages
import SuperAdminLayout from "./layouts/SuperAdminLayout";
import SuperAdminDashboard from "./pages/superadmin/SuperAdminDashboard";
import SuperAdminProfiles from "./pages/superadmin/SuperAdminProfiles";
import SuperAdminAddProfile from "./pages/superadmin/SuperAdminAddProfile";
import SuperAdminLicenseRegistrations from "./pages/superadmin/SuperAdminLicenseRegistrations";
import SuperAdminAddLicense from "./pages/superadmin/SuperAdminAddLicense";
import SignageWarehouses from "./pages/signage/SignageWarehouses";
import SignageAddWarehouse from "./pages/signage/SignageAddWarehouse";
import SignageEditWarehouse from "./pages/signage/SignageEditWarehouse";
import SignageViewWarehouse from "./pages/signage/SignageViewWarehouse";
import SuperAdminEditProfile from "./pages/superadmin/SuperAdminEditProfile";
import Error from "./pages/Error";
import SignageProfile from "./pages/signage/SignageProfile";
import RealtorProfile from "./pages/realtor/RealtorProfile";
import SuperAdminEditLicense from "./pages/superadmin/SuperAdminEditLicense";
import SuperAdminViewLicense from "./pages/superadmin/SuperAdminViewLicense";
import MasterAdminLayout from "layouts/MasterAdminLayout";
import MasterAdminDashboard from "pages/master/MasterAdminDashboard";
import MasterAdminModules from "pages/master/MasterAdminModules";
import MasterAdminAddModule from "pages/master/MasterAdminAddModule";
import AgentDashboard from "pages/agent/AgentDashboard";
import AgentProfile from "pages/agent/AgentProfile";
import AgentLayout from "layouts/AgentLayout";
import InstallerLayout from "layouts/InstallerLayout";
import InstallerDashboard from "pages/installer/InstallerDashboard";
import InstallerProfile from "pages/installer/InstallerProfile";
import RealtorSupplierPage from "pages/realtor/RealtorSupplierPage";
import SignageTeammembers from "pages/signage/SignageTeammembers";
import SignageAddTeammember from "pages/signage/SignageAddTeammember";
import SignageViewTeammember from "pages/signage/SignageViewTeammember";
import SignageEditTeammember from "pages/signage/SignageEditTeammember";
import RealtorGroups from "pages/realtor/RealtorGroups";
import RealtorAddGroup from "pages/realtor/RealtorAddGroup";
import RealtorViewGroup from "pages/realtor/RealtorViewGroup";
import RealtorEditGroup from "pages/realtor/RealtorEditGroup";
import MasterAdminEditModule from "pages/master/MasterAdminEditModule";
import SuperAdminAddPlanPermissions from "pages/superadmin/SuperAdminAddPlanPermissions";
import SuperAdminEditPlanPermissions from "pages/superadmin/SuperAdminEditPlanPermissions";
import SuperAdminAddPlanGroup from "pages/superadmin/SuperAdminAddPlanGroup";
import SuperAdminEditPlanGroup from "pages/superadmin/SuperAdminEditPlanGroup";
import RealtorAddGroupAgent from "pages/realtor/RealtorAddGroupAgent";

// Adding root rendering for React DOM
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<App />} errorElement={<Error />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signage/register" element={<SignageRegistration />} />
          <Route path="/realtor/register" element={<RealtorRegistration />} />
          <Route path="/installer/register" element={<InstallerRegistration />} />
          <Route path="/agent/register" element={<AgentRegistration />} />
          <Route path="/testapi" element={<TestApi />} />

          {/* Signage Routes with Layout */}
          <Route element={<SignageLayout />}>
            <Route path="/signage/dashboard" element={<SignageDashboard />} />
            <Route path="/signage/profile" element={<SignageProfile />} />
            <Route path="/signage/teammembers" element={<SignageTeammembers />} />
            <Route path="/signage/add-teammember" element={<SignageAddTeammember />} />
            <Route path="/signage/view-teammember/:teammemberId" element={<SignageViewTeammember />} />
            <Route path="/signage/edit-teammember/:teammemberId" element={<SignageEditTeammember />} />
            <Route path="/signage/warehouses" element={<SignageWarehouses />} />
            <Route path="/signage/add-warehouse" element={<SignageAddWarehouse />} />
            <Route path="/signage/edit-warehouse/:warehouseId" element={<SignageEditWarehouse />} />
            <Route path="/signage/view-warehouse/:warehouseId" element={<SignageViewWarehouse />} />
          </Route>

          {/* Realtor Routes with Layout */}
          <Route element={<RealtorLayout />}>
            <Route path="/realtor/dashboard" element={<RealtorDashboard />} />
            <Route path="/realtor/profile" element={<RealtorProfile />} />
            <Route path="/realtor/teammembers" element={<RealtorTeammembers />} />
            <Route path="/realtor/add-teammember" element={<RealtorAddTeammember />} />
            <Route path="/realtor/view-teammember/:teammemberId" element={<RealtorViewTeammember />} />
            <Route path="/realtor/edit-teammember/:teammemberId" element={<RealtorEditTeammember />} />
            <Route path="/realtor/offices" element={<RealtorOffices />} />
            <Route path="/realtor/add-office" element={<RealtorAddOffice />} />
            <Route path="/realtor/view-office/:officeId" element={<RealtorViewOffice />} />
            <Route path="/realtor/edit-office/:officeId" element={<RealtorEditOffice />} />
            <Route path="/realtor/agents" element={<RealtorAgents />} />
            <Route path="/realtor/add-agent" element={<RealtorAddAgent />} />
            <Route path="/realtor/view-agent/:agentId" element={<RealtorViewAgent />} />
            <Route path="/realtor/edit-agent/:agentId" element={<RealtorEditAgent />} />
            <Route path="/realtor/suppliers" element={<RealtorSuppliers />} />
            <Route path="/realtor/supplier/:supplierId" element={<RealtorSupplierPage />} />
            <Route path="/realtor/groups" element={<RealtorGroups />} />
            <Route path="/realtor/add-group" element={<RealtorAddGroup />} />
            <Route path="/realtor/view-group/:groupId" element={<RealtorViewGroup />} />
            <Route path="/realtor/edit-group/:groupId" element={<RealtorEditGroup />} />
            <Route path="/realtor/addGroupAgent/:groupId" element={<RealtorAddGroupAgent />} />
          </Route>

          {/* Protected Super Admin Routes with Layout */}
          {/* <Route element={<PrivateRoute />}> */}
            <Route element={<SuperAdminLayout />}>
              <Route path="/superadmin/dashboard" element={<SuperAdminDashboard />} />
              <Route path="/superadmin/profiles" element={<SuperAdminProfiles />} />
              <Route path="/superadmin/add-profile" element={<SuperAdminAddProfile />} />
              <Route path="/superadmin/edit-profile/:planId" element={<SuperAdminEditProfile />} />
              <Route path="/superadmin/license-registrations" element={<SuperAdminLicenseRegistrations />} />
              <Route path="/superadmin/add-license" element={<SuperAdminAddLicense />} />
              <Route path="/superadmin/edit-license/:licenseId" element={<SuperAdminEditLicense />} />
              <Route path="/superadmin/view-license/:licenseId" element={<SuperAdminViewLicense />} />
              <Route path="/superadmin/addPlanPermissions" element={<SuperAdminAddPlanPermissions />} />
              <Route path="/superadmin/edit-plan-permissions/:planPermissionsId" element={<SuperAdminEditPlanPermissions />} />
              <Route path="/superadmin/addPlanGroup" element={<SuperAdminAddPlanGroup />} />
              <Route path="/superadmin/editPlanGroup/:planGroupId" element={<SuperAdminEditPlanGroup />} />
            </Route>
          {/* </Route> */}

          {/* Protected Master Admin Routes with Layout */}
          {/* <Route element={<PrivateRoute />}> */}
            <Route element={<MasterAdminLayout />}>
              <Route path="/master/dashboard" element={<MasterAdminDashboard />} />             
              <Route path="/master/modules" element={<MasterAdminModules />} />
              <Route path="/master/add-module" element={<MasterAdminAddModule />} />
              <Route path="/master/edit-module/:moduleId" element={<MasterAdminEditModule />} />
            </Route>
          {/* </Route> */}

          {/* Agent Routes with Layout */}
          <Route element={<AgentLayout />}>
            <Route path="/agent/dashboard" element={<AgentDashboard />} />
            <Route path="/agent/profile" element={<AgentProfile />} />
          </Route>

          {/* Installer Routes with Layout */}
          <Route element={<InstallerLayout />}>
            <Route path="/installer/dashboard" element={<InstallerDashboard />} />
            <Route path="/installer/profile" element={<InstallerProfile />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
