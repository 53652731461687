import { useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Office = {
  id: number;
  officeName: string;
  officeType: string;
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
};

type Dimensions = {
  length: string;
  height: string;
  width: string;
  weight: string;
};

type Product = {
  product_id: string;
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  installer: string;
  // notes: string;
  image: Image | null;
  status: string;
};

type Image = {
  url: string;
};

const TeammemberOfficeInventory = () => {
  const { officeId } = useParams<{ officeId: string }>();
  const [office, setOffice] = useState<Office>();
  const [products, setProducts] = useState<Product[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();

  // Fetch the office data based on officeId
  useEffect(() => {
    const fetchOfficeData = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/offices/${officeId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch office data: ${response.statusText}`
          );
        }

        const data = await response.json();
        setOffice({
          id: data.id,
          officeName: data.officeName,
          officeType: data.officeType,
          streetAddress1: data.officeAddress.addressLine1,
          streetAddress2: data.officeAddress.addressLine2,
          city: data.officeAddress.city,
          state: data.officeAddress.state,
          country: data.officeAddress.country,
          zipcode: data.officeAddress.zip,
          phone: data.officeAddress.phone,
        });
      } catch (error) {
        toast.error("Error fetching office data");
        console.error("Error fetching office data:", error);
        setError(error instanceof Error ? error.message : "Unknown error");
      }
    };

    if (officeId) {
      fetchOfficeData();
    }
  }, [officeId]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/office/${officeId}/inventory/products`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token]);

  useEffect(() => {
    if (products.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [products]);

  const handleDelete = async (productId: string) => {
    setDeleting(productId); // Set the license being deleted

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/office/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the tax group.");
      }

      toast.success("GeoLocation deleted successfully!");
      // Update the state by removing the deleted license
      setProducts((prev) =>
        prev.filter((product) => product.product_id !== productId)
      );
      setDeleting(null); // Clear the deleting state
    } catch (err) {
      toast.error("Failed to delete the tax group.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  return (
    <div className="teammember">
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h2>Office Details</h2>
              <table className="table">
                <tr>
                  <td><strong>Office Name:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.officeName || ""}</td>
                </tr>
                <tr>
                  <td><strong>Office Type:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.officeType || ""}</td>
                </tr>
                <tr>
                  <td><strong>Address:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.streetAddress1 || ""}
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.streetAddress2 || ""}</td>
                </tr>
                <tr>
                  <td><strong>City:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.city || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>State:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.state || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>Country:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.country || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>Zip code:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.zipcode || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>Phone:</strong>
                  <br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.phone || "N/A"}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card">
            <div className="card-body">
              <h2>Inventory </h2>
              <div className="text-end">
                <a
                  href="/teammember/addOfficeProduct"
                  className="btn btn-primary float-right"
                >
                  Add Office Inventory Item
                </a>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-12 col-lg-12 col-xl-12">
                  <div className="card mt-4 card-bg-fill">
                    <div className="card-body p-4">
                      <div className="table-container">
                        <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>S.No.</th>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Category</th>
                          <th>Qty</th>
                          <th>Status</th>
                          <th>Installer Status</th>
                          <th>Condition</th>
                          <th>Dimensions</th>
                          <th>Weight</th>
                          <th>Linked Installer</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products != null && products.
                          map((product, index) => (
                            <tr key={index}>
                              <td>
                                <input type="checkbox" />
                              </td>
                              <td>{index + 1}</td>
                              <td>
                                {product.image?.url ? (
                                  <img
                                    src={`https://dev.reelty.app/${product.image.url}`}
                                    height={100}
                                    alt="Product"
                                  />
                                ) : (
                                  <span>No image</span>
                                )}
                              </td>

                              <td>{product.name}</td>
                              <td>{product.category}</td>
                              <td>1</td>
                              <td>{product.status}</td>
                              <td>{product.status}</td>
                              <td>{product.item_condition}</td>
                              <td>
                                {product.package_dimensions.length} x{" "}
                                {product.package_dimensions.height} x{" "}
                                {product.package_dimensions.width}
                              </td>
                              <td>
                                {product.package_dimensions.weight}
                              </td>
                              <td></td>
                              <td>
                                <Link
                                  to={`/teammember/office/${office?.id}/editOfficeProduct/${product.product_id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this product?"
                                    ) &&
                                    handleDelete(product.product_id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeammemberOfficeInventory;
