import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; 
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./RealtorViewOffice.css";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

type Office = {
  id: number;
  officeName: string;
  officeType: string;  
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
};

const RealtorViewOffice = () => {
  const { officeId } = useParams<{ officeId: string }>(); // Get officeId from URL params
  const token = localStorage.getItem("sessionToken");
  const [office, setOffice] = useState<Office | null>(null); // To store the office data
  const [loading, setLoading] = useState(true);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);

  // Fetch the office data based on officeId
  useEffect(() => {
    const fetchOfficeData = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/offices/${officeId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();        
        setOffice({
          id: data.id,
          officeName: data.officeName,
          officeType: data.officeType,
          streetAddress1: data.officeAddress.addressLine1,
          streetAddress2: data.officeAddress.addressLine2,
          city: data.officeAddress.city,
          state: data.officeAddress.state,
          country: data.officeAddress.country,
          zipcode: data.officeAddress.zip,
          phone: data.officeAddress.phone,
        });
      } catch (error) {
        console.error("Error fetching office data:", error);
      }
    };

    if (officeId) {
      fetchOfficeData();
    }
  }, [officeId]);

  if (!office) return <div>Loading Office Data...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>View Office Details </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                <div className="table-container">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th colSpan={4}>Office Information </th>
                      </tr>
                      <tr>
                        <th>Office Name: </th>
                        <td>{office.officeName}</td>
                        <th>Office Type: </th>
                        <td>{office.officeType}</td>
                      </tr>
                      <tr>
                        <th>Address Line 1: </th>
                        <td>{office.streetAddress1}</td>
                        <th>Address Line 2: </th>
                        <td>{office.streetAddress2}</td>
                      </tr>
                      <tr>
                        <th>City: </th>
                        <td>{office.city}</td>
                        <th>State: </th>
                        <td>{office.state}</td>
                      </tr>
                      <tr>
                        <th>Country: </th>
                        <td>{office.country}</td>
                        <th>Zip code: </th>
                        <td>{office.zipcode}</td>
                      </tr>
                    </tbody>
                  </table>  
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorViewOffice;
