import React from 'react';
import logo from './logo.svg';
import './App.css';


function App() {
  return (
    <div className="App">      
      <header className="App-header">
        <h1>REALESY</h1>
      </header>
    </div>
  );
}

export default App;
