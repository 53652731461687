import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
// import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

const AgentCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null);
  const token = localStorage.getItem("sessionToken");
  const location = useLocation();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          "https://dev.reelty.app/api/v1/categories",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    // Initialize DataTable when categories change
    const table = $("#categoriesTable").DataTable({
      responsive: true,
      destroy: true, // Ensure DataTable re-initializes properly
      // columnDefs: [
      //   { orderable: false, targets: [0, 4] }, // Disable sorting for specific columns
      // ],
      lengthChange: false,
      ordering: false,
    });

    return () => {
      table.destroy(); // Clean up on component unmount
    };
  }, [categories]);

  const handleDelete = async (catId: string) => {
    setDeleting(catId);

    try {
      const response = await fetch(
        `https://dev.reelty.app/api/v1/categories/${catId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the category.");
      }

      toast.success("Category deleted successfully!");
      setCategories((prev) => prev.filter((category) => category.id !== catId));
      setDeleting(null);
    } catch (err) {
      toast.error("Failed to delete the category.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Categories</h2>
          <div className="text-end">
            <a
              href="/agent/addCategory"
              className="btn btn-primary float-right"
            >
              Add Category
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="categoriesTable"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categories.map((category, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                src={`https://dev.reelty.app/${category.image}`}
                                height={100}
                                alt="Category"
                              />
                            </td>
                            <td>{category.name}</td>
                            <td>
                              {category.status === "active"
                                ? "Active"
                                : "Inactive"}
                            </td>
                            <td>
                              <Link
                                to={`/agent/editCategory/${category.id}`}
                                className="btn btn-warning waves-effect waves-light me-2"
                              >
                                <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                              </Link>

                              <button
                                onClick={() =>
                                  window.confirm(
                                    "Are you sure you want to delete this category?"
                                  ) && handleDelete(category.id)
                                }
                                className="btn btn-danger waves-effect waves-light"
                              >
                                <i className="ri-delete-bin-fill align-bottom me-2"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentCategories;
