import React, { useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Teammember = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  assignedWarehouses: Warehouse[];
  permissions: string[];
  photo?: string;
  statuss: string;
  licenseType: string;
};

type Warehouse = {
  id: string;
  label: string;
};

type SelectOption = {
  value: string;
  label: string;
};

const SignageEditTeammember = () => {
  const { teammemberId } = useParams<{ teammemberId: string }>();
  const [teammember, setTeammember] = useState<Teammember | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState<SelectOption[]>([]);

  const permissionsOptions: SelectOption[] = [
    { value: "all", label: "All" },
    { value: "view", label: "View" },
    { value: "edit", label: "Edit" },
    { value: "delete", label: "Delete" },
  ];

  // Fetch the teammember data based on teammemberId
  useEffect(() => {
    const fetchTeammemberData = async () => {
      try {
        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${teammemberId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok && data) {
          setTeammember({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            jobTitle: data.jobTitle,
            phone: data.phone1,
            email: data.email,
            assignedWarehouses: data.warehouses || [],
            permissions: data.accessLevel || [],
            photo: data.photo || "",
            statuss: data.status || "Active",
            licenseType: data.licenseType || "",
          });
        } else {
          throw new Error("Incomplete data");
        }
      } catch (error) {
        setError("Error fetching teammember data");
        console.error(error);
      }
    };

    if (teammemberId) fetchTeammemberData();
  }, [teammemberId, token]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch("https://dev.reelty.app/api/v1/warehouses", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        if (response.ok) setWarehouses(data);
        else throw new Error("Failed to fetch warehouses");
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouses();
  }, [token]);

  const warehousesOptions = useMemo(
    () =>
      warehouses.map((warehouse) => ({
        value: warehouse.id,
        label: warehouse.label,
      })),
    [warehouses]
  );

  // Formik configuration
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: teammember?.firstName || "",
      lastName: teammember?.lastName || "",
      jobTitle: teammember?.jobTitle || "",
      email: teammember?.email || "",
      phone: teammember?.phone || "",
      photo: null as File | null,
      assignedWarehouses: teammember?.assignedWarehouses.map((w) => w.id) || [],
      permissions: teammember?.permissions || [],
      statuss: teammember?.statuss || "active",
      licenseType: teammember?.licenseType || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      jobTitle: Yup.string().required("Role is required"),
      phone: Yup.string().required("Phone number is required"),
      assignedWarehouses: Yup.array().of(Yup.string()).min(1, "At least one warehouse must be assigned"),
      permissions: Yup.array().of(Yup.string()).min(1, "At least one permission must be assigned"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      console.log('Assigned warehouse ids:',values.assignedWarehouses);
      const assignedWarehouseIds = new Array();
      for(const item of values.assignedWarehouses)
      {
        assignedWarehouseIds.push(item);
      }
     
      console.log('assignedWarehouseIds:',assignedWarehouseIds);
      // const assignedOfficeIds = values.assignedOffices as string[];
      const assignedWarehouses = new Array();
      for (const oid of assignedWarehouseIds) {
        console.log(oid); 
        const assignedWarehouse = warehouses.find((warehouse) => warehouse.id === oid);
        assignedWarehouses.push(assignedWarehouse);
        console.log('assignedWarehouses:', assignedWarehouses);
      }    
      // const assignedOffices = assignedOfficeIds.filter((office) => offices.includes(office.id));
      // console.log(assignedOffices);
      try {
        const body = {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle,
          phone1: values.phone,
          email: values.email,
          warehouses: assignedWarehouses,
          accessLevel: values.permissions,
          status: values.statuss,
          userType: "team_member",
          licenseType: "TEAM_MEMBER",
        };

        const response = await fetch(
          `https://dev.reelty.app/api/v1/users/${teammemberId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        toast.success("Teammember updated successfully!");
        navigate("/signage/teammembers", {
          state: { successMessage: "Teammember updated successfully!" },
        });
      } catch (err) {
        toast.error("An error occurred while updating the teammember.");
        console.error(err);
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  // Handle Assign Warehouses Change
  const handleWarehouseChange = (options: MultiValue<SelectOption>) => {
    setSelectedWarehouses(options as SelectOption[]);
    const selectedValues = options.map((option) => option.value);
    formik.setFieldValue("assignedWarehouses", selectedValues);
    console.log(`Assigned Warehouses selected:`, selectedValues);
  };

  // Handle Permissions Change
  const handlePermissionsChange = (options: MultiValue<SelectOption>) => {
    const selectedPermissions = options.map((option) => option.value);
    formik.setFieldValue("permissions", selectedPermissions);
    console.log(`Permissions selected:`, selectedPermissions);
  };

  // Handle Photo Upload
  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      formik.setFieldValue("photo", file);
      // Optionally, handle file preview or upload here
    }
  };

  // Debugging: Log fetched data
  useEffect(() => {
    console.log("Teammember Data:", teammember);
  }, [teammember]);

  useEffect(() => {
    console.log("Warehouses Data:", warehouses);
  }, [warehouses]);

  useEffect(() => {
    console.log("Selected Warehouses:", selectedWarehouses);
  }, [selectedWarehouses]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">Error: {error}</div>;
  }

  return (
    <div className="teammember">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Edit Team Member </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Job Title/Role{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="jobTitle"
                            name="jobTitle"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                            required
                          >
                            <option value="">- Select -</option>
                            <option value="Agent">Agent</option>
                            <option value="Manager">Manager</option>
                            <option value="Admin">Admin</option>
                            <option value="VP">VP</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Legal">Legal</option>
                            <option value="Custom">Custom</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select role
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Photo/Avatar Upload{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="photo"
                            name="photo"
                            onChange={(event) => {
                              formik.setFieldValue("photo", null); // Handle file selection
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.photo && formik.errors.photo ? (
                            <div className="text-danger">
                              {formik.errors.photo}
                            </div>
                          ) : null}
                        </div>

                       {/* Assign Warehouses */}
                       <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Assign Warehouses <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="assignedWarehouses"
                            name="assignedWarehouses"
                            options={warehousesOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedWarehouses}
                            onBlur={formik.handleBlur}
                            onChange={handleWarehouseChange}
                          />
                          {formik.touched.assignedWarehouses &&
                          formik.errors.assignedWarehouses ? (
                            <div className="text-danger">
                              {/* {formik.errors.assignedWarehouses} */}
                            </div>
                          ) : null}
                        </div>
                        {/* Permissions/Access Level */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Permissions/Access Level{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="permissions"
                            name="permissions"
                            options={permissionsOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={permissionsOptions.filter((option) =>
                              formik.values.permissions.includes(option.value)
                            )}
                            onChange={handlePermissionsChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.permissions &&
                          formik.errors.permissions ? (
                            <div className="text-danger">
                              {formik.errors.permissions}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            required
                          >
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                          {formik.touched.statuss &&
                          formik.errors.statuss ? (
                            <div className="text-danger">
                              {formik.errors.statuss}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageEditTeammember;
