import React from "react";
import { Link } from "react-router-dom";

interface Plan {
  id: string;
  planName: string;
  description: string;
}

interface PlanGroup {
  id: string;
  name: string;
  planProfile: Plan[];
}

interface ProfileGroupsProps {
  planGroups: PlanGroup[];
  loading: boolean;
  error: string;
  deletePlanGroup: (id: string) => void;
}

const ProfileGroups: React.FC<ProfileGroupsProps> = ({
  planGroups,
  loading,
  error,
  deletePlanGroup,
}) => {
  return (
    <div id="profileGroups">
      <div className="text-end">
        <Link
          to="/superadmin/addPlanGroup"
          className="btn btn-primary float-right"
        >
          Add Plan Group
        </Link>
      </div>
      <table className="table table-bordered dt-responsive nowrap table-striped align-middle">
        <thead>
          <tr>
            <th>Group ID</th>
            <th>Group Name</th>
            <th>Plan Profiles</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {planGroups &&
            planGroups.map((group, index) => (
              <tr key={index}>
                <td>1</td>
                <td>{group.name}</td>
                <td>
                  {group.planProfile &&
                    group.planProfile.map((plan, index) => (
                      <span>
                        {plan.planName}
                        {", "}
                      </span>
                    ))}
                </td>
                <td>
                  {/* <Link
                to="#"
                className="btn btn-secondary waves-effect waves-light"
              >
                <i className="ri-eye-fill align-bottom me-2"></i>
              </Link>{" "} */}
                  <Link
                    to={`/superadmin/editPlanGroup/${group.id}`}
                    className="btn btn-warning waves-effect waves-light"
                  >
                    <i className="ri-pencil-fill align-bottom me-2"></i>
                  </Link>{" "}
                  <button
                    onClick={() => deletePlanGroup(group.id)}
                    className="btn btn-danger waves-effect waves-light"
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2"></i>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProfileGroups;
