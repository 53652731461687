import React, { useState, useRef, useEffect } from "react";
import "./SuperAdminHeader.css";

const SuperAdminHeader = () => {
  const [isActive, setIsActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const divRef = useRef<HTMLDivElement | null>(null);

  const toogleMenuBtn = () => {
    setIsActive(!isActive);

    var windowSize = document.documentElement.clientWidth;
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;

    if (windowSize > 767) humberIcon.classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node)) {
      setIsActive(false); // Hide the div
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <header id="page-topbar">
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <a href="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src="/assets/images/logo-sm.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="/assets/images/logo-dark.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </a>

                <a href="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src="/assets/images/logo-sm.png" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="/assets/images/logo-light.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </a>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger material-shadow-none"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <form className="app-search d-none d-md-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    autoComplete="off"
                    id="search-options"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <span className="mdi mdi-magnify search-widget-icon"></span>
                  <span
                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                    id="search-close-options"
                  ></span>
                </div>
              </form>
            </div>

            <div className="d-flex align-items-center">
              <div className="dropdown d-md-none topbar-head-dropdown header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle"
                  id="page-header-search-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-search fs-22"></i>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="dropdown topbar-head-dropdown ms-1 header-item">
                <button
                  type="button"
                  className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle"
                  id="page-header-cart-dropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-shopping-bag fs-22"></i>
                  <span className="position-absolute topbar-badge cartitem-badge fs-10 translate-middle badge rounded-pill bg-info">
                    5
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
                  aria-labelledby="page-header-cart-dropdown"
                >
                  <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="m-0 fs-16 fw-semibold"> My Cart</h6>
                      </div>
                      <div className="col-auto">
                        <span className="badge bg-warning-subtle text-warning fs-13">
                          <span className="cartitem-badge">7</span>
                          items
                        </span>
                      </div>
                    </div>
                  </div>
                  <div data-simplebar style={{ maxHeight: "300px" }}>
                    <div className="p-2">
                      <div className="text-center empty-cart" id="empty-cart">
                        <div className="avatar-md mx-auto my-3">
                          <div className="avatar-title bg-info-subtle text-info fs-36 rounded-circle">
                            <i className="bx bx-cart"></i>
                          </div>
                        </div>
                        <h5 className="mb-3">Your Cart is Empty!</h5>
                        <a
                          href="apps-ecommerce-products.html"
                          className="btn btn-success w-md mb-3"
                        >
                          Shop Now
                        </a>
                      </div>
                      <div className="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                        <div className="d-flex align-items-center">
                          <img
                            src="/assets/images/products/img-1.png"
                            className="me-3 rounded-circle avatar-sm p-2 bg-light"
                            alt="user-pic"
                          />
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Branded T-Shirts
                              </a>
                            </h6>
                            <p className="mb-0 fs-12 text-muted">
                              Quantity: <span>10 x $32</span>
                            </p>
                          </div>
                          <div className="px-2">
                            <h5 className="m-0 fw-normal">
                              $<span className="cart-item-price">320</span>
                            </h5>
                          </div>
                          <div className="ps-2">
                            <button
                              type="button"
                              className="btn btn-icon btn-sm btn-ghost-secondary remove-item-btn"
                            >
                              <i className="ri-close-fill fs-16"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                        <div className="d-flex align-items-center">
                          <img
                            src="/assets/images/products/img-2.png"
                            className="me-3 rounded-circle avatar-sm p-2 bg-light"
                            alt="user-pic"
                          />
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Bentwood Chair
                              </a>
                            </h6>
                            <p className="mb-0 fs-12 text-muted">
                              Quantity: <span>5 x $18</span>
                            </p>
                          </div>
                          <div className="px-2">
                            <h5 className="m-0 fw-normal">
                              $<span className="cart-item-price">89</span>
                            </h5>
                          </div>
                          <div className="ps-2">
                            <button
                              type="button"
                              className="btn btn-icon btn-sm btn-ghost-secondary remove-item-btn"
                            >
                              <i className="ri-close-fill fs-16"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                        <div className="d-flex align-items-center">
                          <img
                            src="/assets/images/products/img-3.png"
                            className="me-3 rounded-circle avatar-sm p-2 bg-light"
                            alt="user-pic"
                          />
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Borosil Paper Cup
                              </a>
                            </h6>
                            <p className="mb-0 fs-12 text-muted">
                              Quantity: <span>3 x $250</span>
                            </p>
                          </div>
                          <div className="px-2">
                            <h5 className="m-0 fw-normal">
                              $<span className="cart-item-price">750</span>
                            </h5>
                          </div>
                          <div className="ps-2">
                            <button
                              type="button"
                              className="btn btn-icon btn-sm btn-ghost-secondary remove-item-btn"
                            >
                              <i className="ri-close-fill fs-16"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                        <div className="d-flex align-items-center">
                          <img
                            src="/assets/images/products/img-6.png"
                            className="me-3 rounded-circle avatar-sm p-2 bg-light"
                            alt="user-pic"
                          />
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Gray Styled T-Shirt
                              </a>
                            </h6>
                            <p className="mb-0 fs-12 text-muted">
                              Quantity: <span>1 x $1250</span>
                            </p>
                          </div>
                          <div className="px-2">
                            <h5 className="m-0 fw-normal">
                              $ <span className="cart-item-price">1250</span>
                            </h5>
                          </div>
                          <div className="ps-2">
                            <button
                              type="button"
                              className="btn btn-icon btn-sm btn-ghost-secondary remove-item-btn"
                            >
                              <i className="ri-close-fill fs-16"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                        <div className="d-flex align-items-center">
                          <img
                            src="/assets/images/products/img-5.png"
                            className="me-3 rounded-circle avatar-sm p-2 bg-light"
                            alt="user-pic"
                          />
                          <div className="flex-grow-1">
                            <h6 className="mt-0 mb-1 fs-14">
                              <a
                                href="apps-ecommerce-product-details.html"
                                className="text-reset"
                              >
                                Stillbird Helmet
                              </a>
                            </h6>
                            <p className="mb-0 fs-12 text-muted">
                              Quantity: <span>2 x $495</span>
                            </p>
                          </div>
                          <div className="px-2">
                            <h5 className="m-0 fw-normal">
                              $<span className="cart-item-price">990</span>
                            </h5>
                          </div>
                          <div className="ps-2">
                            <button
                              type="button"
                              className="btn btn-icon btn-sm btn-ghost-secondary remove-item-btn"
                            >
                              <i className="ri-close-fill fs-16"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border"
                    id="checkout-elem"
                  >
                    <div className="d-flex justify-content-between align-items-center pb-3">
                      <h5 className="m-0 text-muted">Total:</h5>
                      <div className="px-2">
                        <h5 className="m-0" id="cart-item-total">
                          $1258.58
                        </h5>
                      </div>
                    </div>

                    <a
                      href="apps-ecommerce-checkout.html"
                      className="btn btn-success text-center w-100"
                    >
                      Checkout
                    </a>
                  </div>
                </div>
              </div>

              {/* <div className="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                        <button type="button" className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                            <i className='bx bx-bell fs-22'></i>
                            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">0<span className="visually-hidden">unread messages</span></span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
    
                            <div className="dropdown-head bg-primary bg-pattern rounded-top">
                                <div className="p-3">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                        </div>
                                        <div className="col-auto dropdown-tabs">
                                            <span className="badge bg-light text-body fs-13"> 4 New</span>
                                        </div>
                                    </div>
                                </div>
    
                                <div className="px-2 pt-2">
                                    <ul className="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">
                                        <li className="nav-item waves-effect waves-light">
                                            <a className="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="true">
                                                All (4)
                                            </a>
                                        </li>
                                        <li className="nav-item waves-effect waves-light">
                                            <a className="nav-link" data-bs-toggle="tab" href="#messages-tab" role="tab" aria-selected="false">
                                                Messages
                                            </a>
                                        </li>
                                        <li className="nav-item waves-effect waves-light">
                                            <a className="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab" aria-selected="false">
                                                Alerts
                                            </a>
                                        </li>
                                    </ul>
                                </div>
    
                            </div>
    
                            
                        </div>
                    </div> */}

              <div
                className="dropdown topbar-head-dropdown ms-1 header-item"
                id="notificationDropdown"
              >
                <button
                  type="button"
                  className="btn btn-icon btn-topbar material-shadow-none btn-ghost-secondary rounded-circle"
                  id="page-header-notifications-dropdown"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="bx bx-bell fs-22"></i>
                  <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                    3<span className="visually-hidden">unread messages</span>
                  </span>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  aria-labelledby="page-header-notifications-dropdown"
                >
                  <div className="dropdown-head bg-primary bg-pattern rounded-top">
                    <div className="p-3">
                      <div className="row align-items-center">
                        <div className="col">
                          <h6 className="m-0 fs-16 fw-semibold text-white">
                            {" "}
                            Notifications{" "}
                          </h6>
                        </div>
                        <div className="col-auto dropdown-tabs">
                          <span className="badge bg-light text-body fs-13">
                            {" "}
                            4 New
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="px-2 pt-2">
                      <ul
                        className="nav nav-tabs dropdown-tabs nav-tabs-custom"
                        data-dropdown-tabs="true"
                        id="notificationItemsTab"
                        role="tablist"
                      >
                        <li className="nav-item waves-effect waves-light">
                          <a
                            className="nav-link active"
                            data-bs-toggle="tab"
                            href="#all-noti-tab"
                            role="tab"
                            aria-selected="true"
                          >
                            All (4)
                          </a>
                        </li>
                        <li className="nav-item waves-effect waves-light">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#messages-tab"
                            role="tab"
                            aria-selected="false"
                          >
                            Messages
                          </a>
                        </li>
                        <li className="nav-item waves-effect waves-light">
                          <a
                            className="nav-link"
                            data-bs-toggle="tab"
                            href="#alerts-tab"
                            role="tab"
                            aria-selected="false"
                          >
                            Alerts
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    className="tab-content position-relative"
                    id="notificationItemsTabContent"
                  >
                    <div
                      className="tab-pane fade show active py-2 ps-2"
                      id="all-noti-tab"
                      role="tabpanel"
                    >
                      <div
                        data-simplebar
                        style={{ maxHeight: "300px" }}
                        className="pe-2"
                      >
                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                          <div className="d-flex">
                            <div className="avatar-xs me-3 flex-shrink-0">
                              <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                <i className="bx bx-badge-check"></i>
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-2 lh-base">
                                  Your <b>Elite</b> author Graphic Optimization{" "}
                                  <span className="text-secondary">reward</span>{" "}
                                  is ready!
                                </h6>
                              </a>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> Just
                                  30 sec ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="all-notification-check01"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="all-notification-check01"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                          <div className="d-flex">
                            <img
                              src="/assets/images/users/avatar-2.jpg"
                              className="me-3 rounded-circle avatar-xs flex-shrink-0"
                              alt="user-pic"
                            />
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                  Angela Bernier
                                </h6>
                              </a>
                              <div className="fs-13 text-muted">
                                <p className="mb-1">
                                  Answered to your comment on the cash flow
                                  forecast's graph 🔔.
                                </p>
                              </div>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> 48
                                  min ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="all-notification-check02"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="all-notification-check02"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                          <div className="d-flex">
                            <div className="avatar-xs me-3 flex-shrink-0">
                              <span className="avatar-title bg-danger-subtle text-danger rounded-circle fs-16">
                                <i className="bx bx-message-square-dots"></i>
                              </span>
                            </div>
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-2 fs-13 lh-base">
                                  You have received{" "}
                                  <b className="text-success">20</b> new
                                  messages in the conversation
                                </h6>
                              </a>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> 2
                                  hrs ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="all-notification-check03"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="all-notification-check03"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-reset notification-item d-block dropdown-item position-relative">
                          <div className="d-flex">
                            <img
                              src="/assets/images/users/avatar-8.jpg"
                              className="me-3 rounded-circle avatar-xs flex-shrink-0"
                              alt="user-pic"
                            />
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                  Maureen Gibson
                                </h6>
                              </a>
                              <div className="fs-13 text-muted">
                                <p className="mb-1">
                                  We talked about a project on linkedin.
                                </p>
                              </div>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> 4
                                  hrs ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="all-notification-check04"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="all-notification-check04"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="my-3 text-center view-all">
                          <button
                            type="button"
                            className="btn btn-soft-success waves-effect waves-light"
                          >
                            View All Notifications{" "}
                            <i className="ri-arrow-right-line align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade py-2 ps-2"
                      id="messages-tab"
                      role="tabpanel"
                      aria-labelledby="messages-tab"
                    >
                      <div
                        data-simplebar
                        style={{ maxHeight: "300px" }}
                        className="pe-2"
                      >
                        <div className="text-reset notification-item d-block dropdown-item">
                          <div className="d-flex">
                            <img
                              src="/assets/images/users/avatar-3.jpg"
                              className="me-3 rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                  James Lemire
                                </h6>
                              </a>
                              <div className="fs-13 text-muted">
                                <p className="mb-1">
                                  We talked about a project on linkedin.
                                </p>
                              </div>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> 30
                                  min ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="messages-notification-check01"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="messages-notification-check01"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-reset notification-item d-block dropdown-item">
                          <div className="d-flex">
                            <img
                              src="/assets/images/users/avatar-2.jpg"
                              className="me-3 rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                  Angela Bernier
                                </h6>
                              </a>
                              <div className="fs-13 text-muted">
                                <p className="mb-1">
                                  Answered to your comment on the cash flow
                                  forecast's graph 🔔.
                                </p>
                              </div>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> 2
                                  hrs ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="messages-notification-check02"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="messages-notification-check02"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-reset notification-item d-block dropdown-item">
                          <div className="d-flex">
                            <img
                              src="/assets/images/users/avatar-6.jpg"
                              className="me-3 rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                  Kenneth Brown
                                </h6>
                              </a>
                              <div className="fs-13 text-muted">
                                <p className="mb-1">
                                  Mentionned you in his comment on 📃 invoice
                                  #12501.
                                </p>
                              </div>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> 10
                                  hrs ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="messages-notification-check03"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="messages-notification-check03"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-reset notification-item d-block dropdown-item">
                          <div className="d-flex">
                            <img
                              src="/assets/images/users/avatar-8.jpg"
                              className="me-3 rounded-circle avatar-xs"
                              alt="user-pic"
                            />
                            <div className="flex-grow-1">
                              <a href="#!" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                  Maureen Gibson
                                </h6>
                              </a>
                              <div className="fs-13 text-muted">
                                <p className="mb-1">
                                  We talked about a project on linkedin.
                                </p>
                              </div>
                              <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                <span>
                                  <i className="mdi mdi-clock-outline"></i> 3
                                  days ago
                                </span>
                              </p>
                            </div>
                            <div className="px-2 fs-15">
                              <div className="form-check notification-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="messages-notification-check04"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="messages-notification-check04"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="my-3 text-center view-all">
                          <button
                            type="button"
                            className="btn btn-soft-success waves-effect waves-light"
                          >
                            View All Messages{" "}
                            <i className="ri-arrow-right-line align-middle"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade p-4"
                      id="alerts-tab"
                      role="tabpanel"
                      aria-labelledby="alerts-tab"
                    ></div>

                    <div
                      className="notification-actions"
                      id="notification-actions"
                    >
                      <div className="d-flex text-muted justify-content-center">
                        Select{" "}
                        <div
                          id="select-content"
                          className="text-body fw-semibold px-1"
                        >
                          0
                        </div>{" "}
                        Result{" "}
                        <button
                          type="button"
                          className="btn btn-link link-danger p-0 ms-3"
                          data-bs-toggle="modal"
                          data-bs-target="#removeNotificationModal"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dropdown ms-sm-3 header-item topbar-user">
                <button
                  type="button"
                  className="btn material-shadow-none"
                  id="page-header-user-dropdown"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="d-flex align-items-center">
                    <img
                      className="rounded-circle header-profile-user"
                      src="/assets/images/users/avatar-1.jpg"
                      alt="Header Avatar"
                    />
                    <span className="text-start ms-xl-2">
                      <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        Super Admin{" "}
                      </span>
                    </span>
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-end">
                  <h6 className="dropdown-header">Welcome Anna!</h6>
                  <a className="dropdown-item" href="pages-profile.html">
                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Profile</span>
                  </a>
                  <a className="dropdown-item" href="apps-chat.html">
                    <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Messages</span>
                  </a>
                  <a className="dropdown-item" href="apps-tasks-kanban.html">
                    <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Taskboard</span>
                  </a>
                  <a className="dropdown-item" href="pages-faqs.html">
                    <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Help</span>
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="pages-profile.html">
                    <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">
                      Balance : <b>$5971.67</b>
                    </span>
                  </a>
                  <a
                    className="dropdown-item"
                    href="pages-profile-settings.html"
                  >
                    <span className="badge bg-success-subtle text-success mt-1 float-end">
                      New
                    </span>
                    <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Settings</span>
                  </a>
                  <a
                    className="dropdown-item"
                    href="auth-lockscreen-basic.html"
                  >
                    <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle">Lock screen</span>
                  </a>
                  <a className="dropdown-item" href="auth-logout-basic.html">
                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                    <span className="align-middle" data-key="t-logout">
                      Logout
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {isActive && (
        <div ref={divRef} className="active">
          <div
            className={
              isActive
                ? "app-menu navbar-menu vertical-sidebar-enable"
                : "app-menu navbar-menu"
            }
          >
            <div className="navbar-brand-box">
              <a href="index.html" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="/assets/images/logo-dark.png" alt="" height="17" />
                </span>
              </a>
              <a href="index.html" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src="assets/images/logo-light.png" alt="" height="17" />
                </span>
              </a>
              <button
                type="button"
                className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                id="vertical-hover"
              >
                <i className="ri-record-circle-line"></i>
              </button>
            </div>

            <div className="dropdown sidebar-user m-1 rounded">
              <button
                type="button"
                className="btn material-shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center gap-2">
                  <img
                    className="rounded header-profile-user"
                    src="/assets/images/users/avatar-1.jpg"
                    alt="Header Avatar"
                  />
                  <span className="text-start">
                    <span className="d-block fw-medium sidebar-user-name-text">
                      Anna Adame
                    </span>
                    <span className="d-block fs-14 sidebar-user-name-sub-text">
                      <i className="ri ri-circle-fill fs-10 text-success align-baseline"></i>{" "}
                      <span className="align-middle">Online</span>
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome Anna!</h6>
                <a className="dropdown-item" href="pages-profile.html">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Profile</span>
                </a>
                <a className="dropdown-item" href="apps-chat.html">
                  <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Messages</span>
                </a>
                <a className="dropdown-item" href="apps-tasks-kanban.html">
                  <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Taskboard</span>
                </a>
                <a className="dropdown-item" href="pages-faqs.html">
                  <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Help</span>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="pages-profile.html">
                  <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">
                    Balance : <b>$5971.67</b>
                  </span>
                </a>
                <a className="dropdown-item" href="pages-profile-settings.html">
                  <span className="badge bg-success-subtle text-success mt-1 float-end">
                    New
                  </span>
                  <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Settings</span>
                </a>
                <a className="dropdown-item" href="auth-lockscreen-basic.html">
                  <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Lock screen</span>
                </a>
                <a className="dropdown-item" href="auth-logout-basic.html">
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </a>
              </div>
            </div>
            <div id="scrollbar">
              <div className="container-fluid">
                <div id="two-column-menu"></div>
                <ul className="navbar-nav" id="navbar-nav">
                  <li className="nav-item">
                    <a
                      className="nav-link menu-link"
                      href="/superadmin/dashboard"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarDashboards"
                    >
                      <i className="ri-dashboard-2-line"></i>{" "}
                      <span data-key="t-dashboards">Dashboard </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link menu-link"
                      href="/superadmin/profiles"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarDashboards"
                    >
                      <i className="ri-dashboard-2-line"></i>{" "}
                      <span data-key="t-dashboards">Profiles </span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link menu-link"
                      href="/superadmin/license-registrations"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarDashboards"
                    >
                      <i className="ri-dashboard-2-line"></i>{" "}
                      <span data-key="t-dashboards">
                        License Registrations{" "}
                      </span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link menu-link"
                      href="/superadmin/logout"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarDashboards"
                    >
                      <i className="ri-dashboard-2-line"></i>{" "}
                      <span data-key="t-dashboards">Logout </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sidebar-background"></div>
          </div>
        </div>
      )}
      <div className="vertical-overlay"></div>
    </div>
  );
};

export default SuperAdminHeader;
